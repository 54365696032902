.weights{
    &-container{
        background-color: $TertiaryColor;

        &>.container{
            padding: 16px;
        }

        &__error{
            background-color: $errorBackground;

            .progress-bar-weights__label{
                color: $TertiaryTextColor;
            }

            .progress-bar-weights__bar{
                background: $errorProgressBar;
            }
        }

        & .progress-bar-container .bar .segments, .progress-bar-container .bar .progress-fill{
            height: 10px;
        }

        .col-12, .col-xl-5, .col-xl-7{
            padding: 0;
        }
    }

    &__text{
        font-family: $infoFont;
        font-size: 14px;
        line-height: 17.5px;
        font-weight: 300;
        color: #000000;
        margin: 0;

        &--bold{
            font-weight: 700;
        }
    }

    &__button{
        padding: 0;
        margin-top: 8px;
        font-family: $infoFont;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: $brandNewBlue;

        background: none;
        border: none;

        &:hover{
            color: #38A7C7;
        }
    }

    &__unit-counter{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1000px;
        border: 2px solid white;
        height: 57px;
        width: 57px;
        padding: 8px;
        margin-inline-start: 16px;
        margin-inline-end: 24px;

        @media screen and (max-width: 1200px) {
            margin-inline-start: 0;
        }

        & .weights__inner-circle{
            height: 45px;
            width: 45px;
            background: white;
            position: absolute;
            border-radius: 1000px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
        }

        & img, p{
            z-index: 20;
        }

        & img{
            min-width: 15px;
        }

        & p{
            margin-bottom: 0;
            flex: 1;
        }
    }

}

.progress-bar-weights{
    flex: 1;
    width: 100%;
    height: 10px;
    background: white;
    border-radius: 20px;
    position: relative;
    margin: 0 8px;

    & .middle{
        flex: 1;
        position: absolute;
        top: 18px;
        z-index: 10;
        transform: translateX(-50%);

        > .progress-bar-weights__label{
            font-size: 10px;
            line-height: 12px;
            font-weight: 400;
            text-align: center;

            > p{
                color: $secondNeutralText;
                white-space: nowrap;
            }

            &::after{
                content: '';
                position: absolute;
                top: -19px;
                left: 50%;
                transform: translateX(-50%);
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 11px solid #000000;
            }
        }
    }

    &__container{
        margin: 16px 0 28px 0;

        @media screen and (max-width: 1200px){
            margin: 28px 0 28px 0;
        }


    }

    &__current{
        position: absolute;
        bottom: 14px;
        font-family: $infoFont;
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
        color: black;
        white-space: nowrap;
        transform: translateX(-50%);
    }

    &__bar{
        position: absolute;
        display: flex;
        justify-content: end;
        min-width: 10px;
        height: 10px;
        background: $progressBar;
        border-radius: 20px;
        transition: width 0.5s ease-in-out;
    }

    &__ball{
        width: 6px;
        height: 6px;
        margin: 2px;
        background: white;
        border-radius: 20px;
        position: relative;
    }

    &__labels{
        display: flex;
        position: relative;
        margin-top: 8px;
    }

    &__label{
        color: $secondNeutralText;
        font-family: $infoFont;
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        text-align: center;

        > p{
            margin: 0;
            white-space: nowrap;
        }

        &-small{
            font-size: 10px;
            line-height: 10px;
            font-weight: 400;
        }
    }
}

@media screen and (max-width: $smMaxWidth){
    .progress-bar-weights__label{
        font-size: 12px;
        line-height: 14px;
    }

    .progress-bar-weights__current{
        font-size: 10px;
        line-height: 12px;
    }
}

@media screen and (max-width: $xsMaxWidth){
    .weights__unit-counter{
        width: 33px;
        height: 33px;
        border: 1px solid white;
        margin-inline-end: 8px;

        & .weights__inner-circle{
            width: 27px;
            height: 27px;
        }

        & .weights__text{
            font-size: 8px;
            line-height: 10px;
        }
    }
}
