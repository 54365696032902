.product-image-container {
    // background-color: $smoke;
    // background-image: url('../img/icons/no-image.svg');
    // background-repeat: no-repeat;
    // background-position: center;
    // border: 1px dashed $iron;
    height: 100%;
    position: relative;
    width: 100%;

    .product-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: calc(100% + 2px);
        // left: -1px;
        position: absolute;
        position: relative;
        // top: -1px;
        width: calc(100% + 2px);
        [dir='rtl'] & {
            left: 1px;
        }
        display: flex;
        align-items: flex-end;
        padding: 8px;
        gap: 2px;
        flex-wrap: wrap;
        justify-content: flex-start;

        .product-image-technology {
            display: flex;
            align-items: center;
            gap: 2px;
            justify-content: space-between;
            width: 100%;

            .technology-content {
                display: flex;
                align-items: center;
                gap: 2px;
                flex-wrap: wrap;
            }
        }

    }
    .other_mask {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border: 1px dashed $iron;
        bottom: 0;
        height: 30%;
        position: absolute;
        width: 30%;
    }
    .free-item {
        &:not([type='TABLA']) {
            background-color: white;
            background-size: contain;
        }
    }
}
