@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i|Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i');

@import 'fontawesome-all.min.css';

@font-face {
    font-family: 'questa_light';
    src: url('../fonts/questa_grande_light-webfont.eot');
    src: url('../fonts/questa_grande_light-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/questa_grande_light-webfont.woff') format('woff'),
        url('../fonts/questa_grande_light-webfont.ttf') format('truetype');
    font-weight: light;
    font-style: normal;
}

@font-face {
    font-family: 'questa_bold';
    src: url('../fonts/questa_bold-webfont.eot');
    src: url('../fonts/questa_bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/questa_bold-webfont.woff') format('woff'),
        url('../fonts/questa_bold-webfont.ttf') format('truetype'), url('../fonts/questa_bold-webfont.svg#Sri-TSCRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'questa_medium';
    src: url('../fonts/questa_medium-webfont.eot');
    src: url('../fonts/questa_medium-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/questa_medium-webfont.woff') format('woff'),
        url('../fonts/questa_medium-webfont.ttf') format('truetype'), url('../fonts/questa_medium-webfont.svg#Sri-TSCRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SilestoneWebSymbols';
    src: url('../fonts/SilestoneWebSymbols-Regular.eot');
    src: url('../fonts/SilestoneWebSymbols-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/SilestoneWebSymbols-Regular.woff2') format('woff2'),
        url('../fonts/SilestoneWebSymbols-Regular.woff') format('woff'), url('../fonts/SilestoneWebSymbols-Regular.ttf') format('truetype'),
        url('../fonts/SilestoneWebSymbols-Regular.svg#SilestoneWebSymbols-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CosentinoIcons';
    src: url('../fonts/cosentino-icons-webfont.eot');
    src: url('../fonts/cosentino-icons-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/cosentino-icons-webfont.woff2') format('woff2'),
        url('../fonts/cosentino-icons-webfont.woff') format('woff'), url('../fonts/cosentino-icons-webfont.ttf') format('truetype'),
        url('../fonts/cosentino-icons-webfont.svg#cosentino-icons-webfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'sensa-symbols';
    src: url('../fonts/sensa-symbols.eot');
    src: url('../fonts/sensa-symbols.eot?#iefix') format('embedded-opentype'), url('../fonts/sensa-symbols.woff') format('woff'),
        url('../fonts/sensa-symbols.ttf') format('truetype'), url('../fonts/sensa-symbols.svg#sensa-symbols') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'handbook_light';
    src: url('../fonts/pfhandbookpro-thin-webfont.eot');
    src: url('../fonts/pfhandbookpro-thin-webfont.svg');
    src: url('../fonts/pfhandbookpro-thin-webfont.ttf');
    src: url('../fonts/pfhandbookpro-thin-webfont.woff');
}

@font-face {
    font-family: 'handbook_regular';
    src: url('../fonts/pfhandbookpro-regular-webfont.eot');
    src: url('../fonts/pfhandbookpro-regular-webfont.svg');
    src: url('../fonts/pfhandbookpro-regular-webfont.ttf');
    src: url('../fonts/pfhandbookpro-regular-webfont.woff');
}

@font-face {
    font-family: 'handbook_medium';
    src: url('../fonts/pfhandbookpro-medium-webfont.eot');
    src: url('../fonts/pfhandbookpro-medium-webfont.svg');
    src: url('../fonts/pfhandbookpro-medium-webfont.ttf');
    src: url('../fonts/pfhandbookpro-medium-webfont.woff');
}

@font-face {
    font-family: 'dekton_symbol';
    src: url('../fonts/dektonsymbol-regular_v2-webfont.eot');
    src: url('../fonts/dektonsymbol-regular_v2-webfont.svg');
    src: url('../fonts/dektonsymbol-regular_v2-webfont.ttf');
    src: url('../fonts/dektonsymbol-regular_v2-webfont.woff');
}

@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-thin.ttf');
    src: url('../fonts/diagramm-thin.otf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-thin-italic.ttf');
    src: url('../fonts/diagramm-thin-italic.otf');
    font-weight: 100;
    font-style: italic;
}

//extra-light 200
@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-extra-light.ttf');
    src: url('../fonts/diagramm-extra-light.otf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-extra-light-italic.ttf');
    src: url('../fonts/diagramm-extra-light-italic.otf');
    font-weight: 200;
    font-style: italic;
}

//light 300
@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-light.ttf');
    src: url('../fonts/diagramm-light.otf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-light-italic.ttf');
    src: url('../fonts/diagramm-light-italic.otf');
    font-weight: 300;
    font-style: italic;
}

//regular 400
@font-face {
    font-family: 'diagramm-regular';
    src: url('../fonts/diagramm-regular.ttf');
    src: url('../fonts/diagramm-regular.otf');
    src: url('../fonts/diagramm-regular.woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-regular-italic.ttf');
    src: url('../fonts/diagramm-regular-italic.otf');
    src: url('../fonts/diagramm-regular-italic.woff');
    font-weight: 400;
    font-style: italic;
}

//medium 500
@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-medium.ttf');
    src: url('../fonts/diagramm-medium.otf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-medium-italic.ttf');
    src: url('../fonts/diagramm-medium-italic.otf');
    font-weight: 500;
    font-style: italic;
}

//semi-bold 600
@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-medium.ttf');
    src: url('../fonts/diagramm-medium.otf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-medium-italic.ttf');
    src: url('../fonts/diagramm-medium-italic.otf');
    font-weight: 600;
    font-style: italic;
}

//bold 700
@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-bold.ttf');
    src: url('../fonts/diagramm-bold.otf');
    src: url('../fonts/diagramm-bold.woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-bold-italic.ttf');
    src: url('../fonts/diagramm-bold-italic.otf');
    src: url('../fonts/diagramm-bold-italic.woff');
    font-weight: 700;
    font-style: italic;
}

//ultra-bold 800
@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-ultra-bold.ttf');
    src: url('../fonts/diagramm-ultra-bold.otf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-ultra-bold-italic.ttf');
    src: url('../fonts/diagramm-ultra-bold-italic.otf');
    font-weight: 800;
    font-style: italic;
}

//black 900
@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-black.ttf');
    src: url('../fonts/diagramm-black.otf');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'diagramm';
    src: url('../fonts/diagramm-black-italic.ttf');
    src: url('../fonts/diagramm-black-italic.otf');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'diagramm-light';
    src: url('../fonts/diagramm-light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'diagramm-medium';
    src: url('../fonts/diagramm-medium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'diagramm-semibold';
    src: url('../fonts/diagramm-semibold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

[data-iconfont='dekton-symbols'] {
    font-family: 'dekton_symbol';
    line-height: 1.2;
}

[data-iconfont='sensa-symbols'] {
    font-family: 'sensa-symbols';
    line-height: 1.2;
}

[data-iconfont='silestone-symbols'] {
    font-family: 'SilestoneWebSymbols';
    line-height: 1.2;
}

[data-iconfont='cosentino-symbols'] {
    font-family: 'CosentinoIcons';
    line-height: 1.2;
}

[data-iconchar] {
    &:before {
        content: attr(data-iconchar);
    }
}
