#section-unassigned-orders {
    h2 {
        color: $onyx;
        font-family: $titleFont;
        font-size: 22px;
        padding: 20px 0;
        text-transform: capitalize;
    }
    .select-customer {
        .Select-placeholder {
            padding-left: 0px;
        }
        .Select-value {
            padding-left: 0px;
        }
    }
}
