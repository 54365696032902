.big__banner__silestone {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 16px 24px;
    color: $primaryColor;
    background-color: #fff9e4cb;
    filter: brightness(1.03);

    @media (min-width: 1400px) {
        min-height: 208px;
    }

    @media (max-width: 1399px) {
        min-height: 221px;
    }

    @media (max-width: 1199px) {
        min-height: 250px;
    }

    @media (max-width: $mdMaxWidth) {
        min-height: 280px;
    }

    @media (max-width: 767px) {
        min-height: 350px;
    }

    @media (max-width: 599px) {
        min-height: 422px;
    }

    &--header {
        display: flex;
        align-items: flex-start;
        gap: 32px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1100px;

        .left-side {
            h2 {
                font-family: $titleFontNormal;
                font-size: 25px;
                font-weight: 500;
                line-height: 30px;
                margin-bottom: 0;

                @media (max-width: $mdMaxWidth) {
                    font-size: 20px;
                }
            }

            h3 {
                font-family: $titleFontNormal;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 0;
                line-height: 17px;
            }

            &--footer {
                padding-top: 10px;
                line-height: 16px;
                span {
                    font-family: $infoFont;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                }
            }

            @media (max-width: $smMaxWidth) {
                flex-direction: column;
                align-items: baseline;
                gap: 6px;
            }
        }

        .right-side {
            line-height: 26px;
            &--content {
                display: flex;
                align-items: center;
                gap: 4px;
                font-family: $titleFont;

                .number-percent {
                    span {
                        font-size: 38px;
                        font-weight: 700;
                        line-height: 45.5px;

                        @media (max-width: $smMaxWidth) {
                            font-size: 34px; 
                        }
                    }
                }

                .text-percent {
                    line-height: 20px;
                    span {
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 20px;

                        @media (max-width: $smMaxWidth) {
                            font-size: 16px;
                        }
                    }
                }
            }

            &--footer {
                line-height: 13px;
                padding-top: 6px;

                span {
                    font-size: 14px;
                    font-weight: 300;
                    font-family: $titleFontNormal;
                    line-height: 16px;
                }
            }
        }

        @media (max-width: $smMaxWidth) {
            width: 100%;
            gap: 13px;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    @media (max-width: $mdMaxWidth) {
        padding: 16px;
    }
}

.small__banner__silestone {
    border: 1px solid #A9AEBC;
    color: $primaryColor;
    background-color: #fff9e4cb;
    padding: 16px;
    text-align: left;
    filter: brightness(1.04);

    display: flex;
    align-items: baseline;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 1400px) {
        min-height: 318px;
    }

    @media (max-width: 1399px) {
        min-height: 415px;
    }

    @media (max-width: 1023px) {
        min-height: 295px;
    }

    @media (max-width: 767px) {
        min-height: 318px;
    }

    &--title {
        padding-bottom: 16px;
        font-family: $titleFontNormal;
        font-weight: 500;

        h2 {
            font-size: 18px;
            line-height: 19px;
            margin-bottom: 6px;
        }

        h3 {
            margin: 0;
            font-size: 13px;
            line-height: 13px;
        }
    }

    &--content {
        display: flex;
        align-items: center;
        gap: 4px;
        padding-bottom: 10px;

        .number-percent {
            span {
                font-size: 30px;
                font-weight: 700;
                line-height: 33px;
            }
        }

        .text-percent {
            line-height: 16px;
            display: flex;
            flex-direction: column;
            align-items: baseline;

            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
            }
        }
    }

    &--footer {
        line-height: 16px;
        padding-bottom: 14px;
        span {
           font-size: 14px;
           font-weight: 400;
           line-height: 16px;
        }
    }

    &--discount {
        line-height: 16px;
        span {
            font-size: 14px;
            font-weight: 300;
            line-height: 16px;
        }
    }
}

// Banner Silestone + Dekton Black Week

.banner-silestone-dekton-blackweek {
    padding: 16px;
    border: 1px solid $primaryColor;

    .banner__container--column {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .banner__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        @media(max-width: $smMaxWidth) {
            flex-direction: column;
        }

        .banner {
            &__header {
                text-align: left;

                @media(max-width: $smMaxWidth) {
                    width: 100%;
                }
            }

            &__header.width-50 {
                width: 50%;

                @media (max-width: $smMaxWidth) {
                    width: 100%;
                }
            }

            &__title {
                h2 {
                    font-weight: 500;
                    font-family: $titleFontNormal;
                    margin: 0;

                    @media (max-width: $smMaxWidth) {
                        font-size: 18px !important;
                    }
                }

                h3 {
                    font-weight: 400;
                    font-family: $titleFontRegular;
                    margin: 0;

                    @media (max-width: $smMaxWidth) {
                        font-size: 18px !important;
                    }
                }
            }

            &__link {
                padding-top: 6px;
                display: block;
                
                @media (max-width: $smMaxWidth) {
                    display: none;
                }

                button {
                    padding: 0;
                    background-color: transparent;
                    text-decoration: underline;
                    color: $white;
                    font-size: 14px;
                    font-weight: 400;
                    border: 0;

                    &:hover {
                        font-weight: 600;
                    }
                }
            }

            &__content.width-50 {
                width: 50%;

                @media (max-width: $smMaxWidth) {
                    width: 100%;
                }
            }

            &__content {
                display: flex;
                align-items: baseline;
                flex-direction: column;
                gap: 10px;

                .banner__cards--column {
                    flex-direction: column;
                    width: 100%;
                }

                .banner__cards {
                    background-color: transparent;
                    display: flex;
                    gap: 10px;

                    .banner__card {
                        border-width: 1px;
                        border-style: solid;
                        border-radius: 10px;
                        padding: 8px 16px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 8px;
                        flex: 1;
                        text-align: left;

                        h5 {
                            margin: 0;
                            font-weight: 700;
                        }

                        h6 {
                            font-size: 16px;
                            margin: 0;

                            @media (max-width: $smMaxWidth) {
                                font-size: 12px;
                            }
                        }
                    }
                }

                .banner__details {
                    .banner__detail {
                        margin-bottom: 0;
                        font-weight: 300;
                        font-size: 12px;
                        text-align: left;
                    }
                }

                .banner__link-mobile {
                    display: none;
                    @media (max-width: $smMaxWidth) {
                        display: block;
                    }

                    button {
                        padding: 0;
                        background-color: transparent;
                        text-decoration: underline;
                        font-size: 14px;
                        font-weight: 400;
                        border: 0;
                    }
                }

                .banner__link--row {
                    button {
                        padding: 0;
                        background-color: transparent;
                        text-decoration: underline;
                        font-size: 14px;
                        font-weight: 400;
                        border: 0;
                    }
                }
            }
        }
    }
}

.silestone-dekton-blackweek-modal {
    font-size: 14px;
    color: $primaryColor;

    .modal-subtitle {
        font-weight: 600;
        font-size: 16px;
        font-family: $titleFontNormal;
    }
}
// Banner Silestone XM - Health & Safety

.banner-silestone-xm-health-safety {
    margin: 24px 0;
    padding: 24px 32px;

    @media (max-width: $smMaxWidth) {
        padding: 0;
    }

    .banner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 48px;

        @media (max-width: $smMaxWidth) {
            flex-direction: column;
            gap: 0;
        }

        .banner__header {

            @media (max-width: $smMaxWidth) {
                padding: 24px;
            }

            .banner__logo {
                padding-bottom: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .silestone-xm-logo {
                    img {
                        width: 100%;
                        height: 100%;
                        max-width: 156px;
                    }
                }

                .hybriq-mineral-logo {
                    display: none;

                    @media (max-width: $smMaxWidth) {
                        display: block;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        max-width: 55px;
                    }
                }

            }

            .banner__content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 24px;

                .banner__text {
                    h4 {
                        color: $white;
                        font-family: $titleFontNormal;
                        margin-bottom: 0;
                        font-weight: 300;
                        line-height: 26.4px;
                        font-size: 22px;
                        text-transform: uppercase;
                        text-align: left;

                        @media (max-width: $smMaxWidth) {
                            font-size: 16px;
                            line-height: 19px;
                        }
                    }
                }

                .banner__image {

                    display: block;
                    
                    @media (max-width: $smMaxWidth) {
                        display: none;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        max-width: 71px;
                        min-width: 71px;
                    }
                }
            }

            .banner__button {
                padding-top: 4px;

                @media (max-width: $smMaxWidth) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 16px;
                }

                .cta-silestone-xm {
                    border-color: $white;
                    color: $white;
                    background-color: transparent;
                    font-weight: 500;
                    font-size: 14px !important;
                    padding: 12px 24px;

                }
            }
        }

        .banner__footer {
            padding: 24px;
            display: flex;
            align-items: baseline;
            flex-direction: column;
            gap: 23px;
            background-color: #EBEBEB;

            @media (max-width: $smMaxWidth) {
                padding: 16px 24px;
                gap: 16px;
            }

            &-text {
                p {
                    margin: 0;
                    font-size: 19px;
                    font-weight: 400;
                    color: $primaryColor;
                    line-height: 25px;
                    text-align: left;

                    @media (max-width: $smMaxWidth) {
                        font-size: 16px;
                        line-height: 18.75px;
                    }
                }
            }

            &-button {
                @media (max-width: $smMaxWidth) {
                    margin: auto;
                    width: 100%;
                }

                .cta-health-safety-info {
                    display: inline-block;
                    font-weight: 500;
                    font-size: 14px !important;
                    padding: 12px 24px;

                    @media (max-width: $smMaxWidth) {
                        display: block;
                    }
                }
            }
        }
    }
}