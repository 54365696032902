.rectangle-result-v2 {
    background: $white;
    display: flex;
    flex-direction: column;
    vertical-align: top;

    &:not([type='TABLA']) {
        .wrapper-product-image
        .product-image-container {
            .product-image {
                background-size: contain;
            }
        }
    }

    /* .outlet-indicator {
        background: #d66f00;
        border-radius: 2px;
        box-shadow: rgba($onyx, 0.3) 1px 1px 3px;
        color: $white;
        font-size: 11px;
        font-weight: bold;
        left: -16px;
        padding: 3px 5px 0;
        position: absolute;
        text-transform: uppercase;
        top: -2px;
        z-index: 1;
    } */

    /* .sales-indicator {
        background: #ffd972;
        border-radius: 4px;
        box-shadow: none;
        color: #232323;
        font-size: 14px;
        font-weight: bold;
        left: -20px;
        padding: 4px 12px 2px;
        position: absolute;
        text-transform: uppercase;
        top: -10px;
        z-index: 1;
    } */

    /* .discontinued-indicator {
        background: $discontinuedIndicatorBg;
        border-radius: 2px;
        box-shadow: rgba($onyx, 0.3) 1px 1px 3px;
        color: $white;
        font-size: 11px;
        font-weight: bold;
        left: -16px;
        padding: 3px 5px 0;
        position: absolute;
        text-transform: uppercase;
        z-index: 1;
    } */

    /* .technology-indicator {
        background: whitesmoke;
        border-radius: 2px;
        box-shadow: rgba($onyx, 0.3) 1px 1px 3px 1px;
        color: $white;
        display: flex;
        font-size: 11px;
        font-weight: bold;
        height: 25px;
        justify-content: center;
        left: 0;
        bottom: 0;
        top: auto;
        padding: 2px;
        position: absolute;
        text-transform: uppercase;
        width: 50px;
        z-index: 1;
        &.plus {
            &:after {
                color: black;
                content: '+';
                font-size: 14px;
                font-weight: 200;
                line-height: 14px;
                margin-left: 2px;
            }
        }
        > img {
            width: 80%;
        }
        &.hybriq {
            background-color: white;
            width: 80px;
            align-items: center;
        }
    } */

    /* .promo-indicator {
        background: $promoIndicatorBg;
        border-radius: 2px;
        box-shadow: rgba($onyx, 0.3) 1px 1px 3px;
        color: $white;
        font-size: 11px;
        font-weight: bold;
        padding: 3px 5px 0;
        position: absolute;
        right: -20px;
        text-transform: uppercase;
        top: -10px;
        z-index: 1;
    } */

    //Image section
    .wrapper-product-image {
        position: relative;

        .product-image-container {
            height: 160px;
            width: 100%;

            .product-image {
                width: 100%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 160px;
                position: relative;
                &:not([type='TABLA']) {
                    background-size: contain;
                }
            }
        }
    }

    //Middle section
    .product-info {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: baseline;
        text-align: center;
        padding: 16px;

        .product-brand {
            color: $secondNeutralText;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-transform: capitalize;
            text-align: left;
        }

        .product-color-name {
            word-break: break-all;
            word-break: break-word;
            text-align: left;
            padding-bottom: 4px;
            padding: 4px 0;

            .product-title {
                color: $primaryColor;
                font-family: $titleFontRegular;
                font-size: 20px;
                line-height: 24px;
                font-weight: 600;
                margin: 0;
            }

        }

        .product-info-details_productid {
            //flex-grow: 1;

            .product_detail {

                .text {
                    color: $secondNeutralText;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    text-transform: capitalize;
                }
            }
        }

        .product-info-details__content {
            flex-grow: 1;
            //padding-top: 16px;
            padding: 16px 0 ;
            width: 100%;
            border-bottom: 1px solid #DDE4F6;

            .product-info-details {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                line-height: 18px;

                .text {
                    color: $secondNeutralText;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    text-transform: capitalize;

                    .bold-text {
                        font-weight: 500;
                        color: $primaryGray;
                        font-size: 12px;
                        line-height: 18px;
                    }
                }

                .format {
                    .text {
                        text-transform: none;
                    }
                }

                .thickness-measure {
                    color: $secondNeutralText;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    margin-left: 3px;
                }
            }
        }

        .product_detail.stock {
            text-align: left;
            padding-top: 16px;
            //flex-grow: 1;

            .text {
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
            }

            .length {
                font-size: 12px;
                color: $secondNeutralText;
                font-weight: 400;
            }
        }

        .product-info-details {
            .product_detail {
                .icon {
                    img {
                        height: 15px;
                    }
                }
                .text {
                    word-break: break-word;
                    white-space: normal;
                    overflow-wrap: break-word;
                }
            }

            .finish.price {
                padding-top: 16px;
                text-align: left;
                font-weight: 500;
                font-size: 20px;

                .price-info-display-price {
                    color: $primaryColor;
                    font-weight: 400;
                    font-size: 0.9rem;
                }
                .text.price {
                    color: $primaryColor;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                }

                del {
                    color: $secondNeutralText;
                    font-weight: 400;
                    font-size: 16px;
                }
            }

            .finish {
                .text {
                    text-transform: initial !important;
                }
            }
        }
    }

    //Product actions
    .product-actions {
        display: flex;
        align-items: center;
        padding: 0 16px 16px 16px;
        align-items: center;
        justify-content: space-between;
        gap: 6px;

        .input-row {
            display: flex;
            align-items: flex-start;

            .input-number {
                max-width: 100%;
                color: $primaryColor;
                display: flex;
                justify-content: center;
                align-items: center;
                grid-gap: 2px;
                gap: 2px;

                .input-container {
                    height: 30px;
                    width: 34px;

                    .inner-input-number {
                        border: 1px solid $primaryColor;
                        background-color: $white;
                    }
                }
            }
        }

        .bt-row {
            width: 100%;
            padding-top: 0;
            
            .bt {
                width: 100%;
            }
        }
    }    
}