.bt.noselect.bt-inverted.bt-size-small.bt-add-favourite {
    background: $white;
    color: #81889C;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    padding: 8px;
    position: absolute;
    right: 8px;
    top: 8px;

    &:hover {
        background-color: transparent;
    }

    &:not(.bt-disabled):hover {
        color: #81889C;
    }

    &.isFavourite {
        color: #81889C;
    }
    
    i {
        font-size: 16px;
        height: auto;
        margin: 0;
    }
}
