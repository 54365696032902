.alert__success,
.alert__failure,
.alert__warning {
    max-width: 400px;
    width: 100%;
    border-radius: var(--border-radius);
    box-shadow: 0.0625rem 0.0625rem 0.0925rem #2d794b;
    padding: 20px;
    margin: 10px auto;
    position: fixed;
    z-index: 1200;
    bottom: 30px;
    left: 30px;
}

.alert__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
    border-radius: 50px;
    width: 18px;
    height: 18px;
    line-height: 14px;
    text-align: center;
}

.alert__title {
    color: #fff;
}

.alert__message {
    font-weight: 400;
    font-size: 14px;
}
.alert__failure {
    background: #db7474;
    box-shadow: 0.0625rem 0.0625rem 0.0925rem #883d3d;
}
.alert__failure .alert__close {
    border: 1px solid white;
    color: white;
}
.alert__success {
    background: #5ed490;
}
.alert__warning {
    background: #fff3cd;
    box-shadow: 0.0625rem 0.0625rem 0.0925rem #ffeeba;

    .alert__title {
        color: #856404;
    }
}
.alert__success .alert__close {
    border: 1px solid #2d794b;
    color: #2d794b;
}

.fadeinup {
    animation-name: fadeInUp;
    animation-duration: 0.5s;
    opacity: 0;
    animation-fill-mode: forwards;
    text-decoration: none;
}
.catalog-position {
    font-size: 12px;
    right: 30px;
    left: unset;
    z-index: $layer10;
}
.extra-discount {
    border-radius: 4px;
    background: #aed8d3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    img {
        width: 120px;
        margin-right: 20px;
    }
    .alert__title {
        font-size: 20px;
        font-weight: 500;
        color: #232323;
    }
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 50px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 10px, 0);
    }
}
@media (max-width: $smMaxWidth) {
    .alert__success,
    .alert__failure,
    .alert__warning {
        max-width: 100vw;
        left: 0px;
        width: 100%;
        position: fixed;
        z-index: $layer60;
        bottom: 0px;
    }
    .alert__close {
        width: 32px;
        height: 32px;
        line-height: 26px;
    }
    .catalog-position {
        z-index: $layer10;
    }
}
.v2{
    .catalog-position {
        background: #DBE4E9;
        color: black !important;
        .alert__title{
            color:black !important
        }
    }
}