/*
 * Specific styles of signin component
 */

/*
 * General styles
 */
.mkt_products_admin {
    .toolbar {
        margin-top: 25px;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        &__search {
            flex-grow: 1;
            margin-right: 25px;
        }
        &__filters {
            display: flex;
            justify-content: flex-end;
            & > * {
                margin-left: 30px;
            }
        }
    }
    input {
        max-width: 100%;
    }
    .group_buttons_row_table {
        display: flex;
        justify-content: space-evenly;
    }
    .wrapper_image {
        img {
            max-width: 50px;
            max-height: 50px;
        }
    }
}
