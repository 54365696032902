.bg-gray-lighter {
    background: $smoke;
}

.bg-gray-light {
    background: $iron;
}

.selected-language {
    display: flex;
    // float: right;
    font-size: 14px;
    padding: 3px 0;
    font-weight: 200;
    // text-align: right;
    justify-content: flex-end;
    cursor: pointer;
    width: fit-content;
}

.market-selector {
    font-family: 'Source Sans Pro';
    overflow-y: hidden;
    color: $thunder;
    font-size: 14px;
    transition: 0.5s $ease;
    position: relative;
    visibility: hidden;
    position: fixed;
    z-index: 302;
    left: 0;
    top: -100%;
    right: 0;
    height: 100%;
    background-color: $white;
    padding-bottom: 50px;

    @media (max-width: $smMaxWidth) {
        .flag-icon {
            display: none;
        }
    }

    @media (max-width: $mdMinWidth) {
        overflow-y: auto;
    }

    &.open {
        visibility: visible;
        top: 0;
    }

    .market-selector-info {
        font-size: 18px;
    }

    ul,
    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .logo-cosentino {
        display: block;
        width: 100%;
        font-size: 24px;
        font-weight: 200;
        padding: 10% 0px;

        &:before {
            display: block;
            max-width: 275px;
            margin: 20px auto;
        }

        img {
            height: 70px;
            margin-bottom: 30px;
        }
    }

    .continentes {
        position: relative;
        display: table;
        table-layout: fixed;
        width: 100%;

        .market-selector__continente {
            font-size: 18px;
            padding: 10px;
            font-weight: 500;
            cursor: pointer;
            padding: 15px 10px;

            &:hover {
                .market-selector__continente {
                    border-bottom: 2px solid $gray;
                }
            }
        }

        .market-selector__pais {
            font-size: 16px;
            cursor: pointer;
            padding: 10px;
            white-space: nowrap;
            border: 1px solid transparent;
            border-bottom: none;
        }

        .market-link__pais {
            font-size: 16px;
            cursor: pointer;
            padding: 10px;
            white-space: nowrap;
            border: 1px solid transparent;
            border-bottom: none;
        }

        li > a,
        li > span {
            display: block;
            padding: 10px;
        }

        //Continente
        & > li {
            display: table-cell;
            vertical-align: top;

            @media (max-width: $smMaxWidth) {
                display: block;
            }

            ul {
                display: none;
                position: absolute;
                text-align: left;

                @media (max-width: $smMaxWidth) {
                    position: relative;
                    text-align: center;
                }
            }

            //Paises
            &.selected {
                .market-selector__continente {
                    border-bottom: 2px solid $gray;
                }

                & > ul {
                    padding-top: 10px;
                    display: flex;
                    z-index: 1;
                }
            }

            & > ul {
                border: none;

                @media (max-width: $smMaxWidth) {
                    border-bottom: 2px solid $gray;
                }

                left: 0;
                right: 0;
                width: 100%;

                & > li {
                    margin-bottom: 5px;

                    //Idiomas
                    &.selected {
                        .market-selector__pais {
                            border-color: $gray;

                            &:after {
                                transform: scaleY(-1);
                            }
                        }

                        & > div > ul {
                            display: block;
                            z-index: 1;
                        }
                    }

                    .market-selector__pais:not([href]) {
                        &:after {
                            margin-left: 1px;
                            transition: 0.2s;
                            @extend .char-angle-down;
                        }
                    }

                    & > div > ul {
                        width: 100%;
                        background-color: $white;
                        border: 1px solid $gray;
                        border-top: none;

                        & > li {
                            //font-size: fz(14);
                        }
                    }
                }
            }
        }
    }

    .btn-cerrar {
        position: fixed;

        top: auto;
        right: auto;
        bottom: 4rem;
        left: 50%;
        transform: translateX(-50%);

        cursor: pointer;

        @media (max-width: $smMaxWidth) {
            top: 4px;
            bottom: 0;
            left: auto;
            right: 10px;
            transform: translateX(0%);
        }
    }
}
[dir='rtl'] .market-selector {
    .continentes {
        & > li {
            ul {
                text-align: right;
            }
        }
    }
}
