$svg-char-angle-down: '<svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z"/></svg>';

.logo-maintenance_wrapper {
    padding: 50px 15px;
}

.error-actions {
    margin-bottom: 25px;
}

.order_header {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    div.group_date_picker {
        display: flex;

        & > div {
            margin: 0 15px;
        }
    }

    & > div {
        margin-bottom: 10px;
        margin-right: 15px;

        &:last-child {
            flex-grow: 1;
            margin-right: 0;
        }
    }

    .react-datepicker-popper {
        z-index: 11;
    }
}

button.react-datepicker__close-icon::after {
    background-color: $iron;
}

.identification_flag {
    align-items: center;
    background-color: rgba($iron, 0.5);
    cursor: pointer;
    display: flex;
    font-weight: 400;
    height: 150px;
    height: 75px;
    justify-content: center;
    left: -75px;
    position: absolute;
    text-align: center;
    text-transform: capitalize;
    top: 50px;
    transform: rotate(-45deg);
    width: 325px;
    z-index: 9999;
    transition: 0.1s;

    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;

    &--local {
        background-color: rgba($lightGreen, 0.7);
    }

    &--pre{
        display: none;
    }

    &--pre-1,
    &--pre-2,
    &--pre-3,
    &--pre-4 {
        background-color: rgba($greenOk, 0.7);
    }

    &--qas,
    &--dev{
        background-color: rgba(200, 0, 0, 0.7);
        color: white;
        text-shadow: 0px 0px 2px black;
    }

    &:hover {
        transform: rotate(-45deg) scale(1.04);
        box-shadow: 2px 2px 2px lightgray;
    }

    &:active {
        transform: rotate(-45deg) scale(1.02);
        box-shadow: 1px 1px 1px lightgray;
    }
}

#wrapper-full {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 96px);

    #wrapper-contain {
        min-height: calc(100vh - 300px);
        flex-grow: 1;
    }
}

#wrapper-full {
    #wrapper-header {
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 200;
    }
}

#wrapper-full.v2 {
    #wrapper-contain {
        background-color: $backgroundV2;
        flex-grow: 1;
        .search-box {
            border: 1px solid #B7B7B7;
            
            &:focus-within {
                border-color: $primaryColor;
            }
        }
    }

    @media (max-width: 500px) {
        .toggle-mobile-filters.d-block.d-lg-none {
            width: 100%!important;
            bottom: 0 !important;
            left: 0 !important;
            border-radius: 0;
            margin: 0;
            img {
                position: initial;
            }
        }
    }
    .bt:not(.bt-disabled):hover {
        background-color: #DBE4E9 !important;
        color: $primaryColor !important;

        /* .btn-add-to-cart-text {
            color: $primaryColor;
        } */
    }
    #cart-doll > *{
        display: inline-block;
    }

}

.char-angle-down:after {
    display: inline-block;
    height: 15px;
    margin-left: 0.5em;
    vertical-align: sub;
    width: 16px;
    @include content-svg($svg-char-angle-down);
}

.img-line-product {
    text-align: center;

    img.img-fluid {
        max-height: 100px;
    }
}

.modal-recurring-orders {
    max-width: 635px;

    .recurring-orders-details-scroll {
        font-size: 13px;
        max-height: 60vh;
        overflow-y: auto;
        padding: 0 15px;

        div {
            text-transform: capitalize;
        }

        .product-serie {
            font-weight: 500;
        }
    }
}

.col-centered {
    float: none;
    margin: 0 auto;
}

.col-hidden {
    display: none;
}

.txt-centered {
    text-align: center;
}

.no-padding {
    padding: 0;
}

.flag-icon {
    background-size: cover;
    border: 1px solid $gray;
    border-radius: 50%;
    display: inline-block;
    height: 30px;
    overflow: hidden;
    vertical-align: middle;
    width: 30px;

    &.small {
        height: 18px;
        width: 18px;
    }
}

.btn-cerrar {
    background-color: $white;
    border: 1px solid $iron;

    border-radius: 50%;
    color: $gray;
    font-size: 13px;
    height: 2.8em; //40px;
    line-height: 2.7;
    overflow: hidden;
    padding: 0;
    text-align: center;
    text-indent: -9999px;
    width: 2.8em; //40px;

    &:hover {
        background-color: $onyx;
        color: $white;
    }

    &:before {
        content: '\2715';
        display: block;
        text-indent: 0;
    }
}

.loading-bar {
    background: $thunder;
    height: 3px;
    z-index: $layer60;
}

.full-overlay {
    background: rgba($thunder, 0.65);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $layer40;
    cursor: auto;
}

.modal-overlay {
    background: rgba($thunder, 0.65);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: $layer10;

    @media (max-width: $mdMaxWidth) {
        visibility: hidden;
    }
}

.form-group.employee-id {
    text-align: center;
}

.employee-msg {
    text-align: center;
    margin: 10px 0;
}

.modal {
    .mall-discount {
        .modal-header,
        .modal-footer {
            border: none;
        }
    }

    .modal-dialog {
        .modal-content {
            max-height: calc(100vh - 64px);

            .modal-header {
                .close {
                    cursor: pointer;
                }
            }

            .modal-body {
                overflow-y: auto;

                section {
                    height: initial;
                    min-height: initial;
                }
            }
        }

        &.showOverflow {
            .modal-content {
                .modal-body {
                    overflow: visible;
                }
            }
        }
    }
}

.info-modal {
    position: fixed;
    top: 20%;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;
    max-width: 950px;
    background: white;
    z-index: $layer50;

    @media (max-width: $mdMaxWidth) {
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .check-icon {
        position: absolute;
        margin-top: 20px;
        left: -30px;
        color: limegreen;
        @media (max-width: $mdMaxWidth) {
            margin-top: -30px;
            left: 10px;
        }
    }

    header {
        padding: 20px 25px;

        .bt-close {
            display: inline-block;
            cursor: pointer;

            &:hover {
                color: $onyx;
            }

            i {
                font-size: 40px;
            }
        }
    }

    h2 {
        padding-bottom: 40px;
    }

    footer {
        padding: 20px;
    }
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.blur-overflow {
    background-size: cover;
    overflow: hidden;
}

.fit-image {
    margin: 0 auto;
    overflow: hidden;
    width: fit-content;
}

.progress-bar-container {
    padding: 5px 25px;

    &.minimal {
        .bar {
            height: 2px;
        }
    }

    &.tiny {
        .bar {
            height: 8px;
        }
    }

    &.small {
        .bar {
            height: 15px;
        }
    }

    &.big {
        .bar {
            height: 40px;
        }
    }

    .bar {
        height: 25px;
        position: relative;
        overflow-x: clip;

        .segments {
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;

            .segment {
                bottom: 0;
                position: absolute;
                top: 0;
            }
        }

        .progress-fill {
            background: #fff;
            bottom: 0;
            opacity: 0.9;
            position: absolute;
            right: 0;
            top: 0;

            transition: left 0.5s;
        }

        .dividers {
            bottom: 0;
            left: 0;
            position: relative;
            right: 0;
            top: 0;

            .divider {
                background: #000;
                bottom: 0;
                opacity: 0.15;
                position: absolute;
                top: 0;
                width: 1px;
            }
        }
    }

    .labels {
        position: relative;
        text-align: left;

        .label {
            display: inline-block;

            &:not(:first-child) {
                position: absolute;
            }

            &:not(:first-child):not(:last-child) {
                transform: translateX(-50%);
            }

            &:last-child {
                right: 0;
            }
        }
    }
}

.ReactModal__Overlay {
    z-index: $layer30 !important;
}

#confirm-container {
    background: white;

    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
    left: 0;
    margin: auto;
    max-width: 650px;
    position: fixed;
    right: 0;
    top: 20%;
    width: 100%;
    z-index: 20000;

    @media (max-width: $mdMaxWidth) {
        bottom: 30%;
        left: 0;
        -webkit-overflow-scrolling: touch;
        overflow-x: none;
        overflow-y: scroll;
        top: unset;
        width: 100%;
    }
}

.confirm {

    .container-fluid {
        position: relative;
    }

    header {
        padding: 20px 25px;

        &:hover {
            color: $onyx;
        }

        i {
            font-size: 40px;
        }
    }

    h2 {
        font-size: 20px;
        color: $primaryColor;
        font-family: $titleFontRegular;
        font-weight: 600;
        padding-bottom: 16px;
        margin: 0;
    }

    .confirm-advise {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        align-items: center;
        font-size: 16px;
        text-align: center;
        color: #646464;
        i {
            font-size: inherit;
        }
    }

    .confirm-btn-group {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;

        @media (max-width: $smMaxWidth) {
            flex-direction: column-reverse;
            gap: 16px;

            div {
                width: 100%;
            }
        }
    }

    .confirm-dialog-close {
        position: absolute;
        right: 0;
        top: 0;
        background-color: transparent;
        border: 0;
        outline: 0;
        padding: 10px 15px;
        font-size: 16px;
        color: #646464;

        &:hover {
            background-color: $primaryColorHover;
        }

        i {
            font-size: 16px;
        }
    }
}

.bundle-content {
    color: deepskyblue;
    cursor: pointer;
}

.alert {
    font-size: 14px;
    padding: 8px 16px;
}

#username {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 30ch;
}

.tooltip {
    display: inline-block;
    opacity: 1;
    position: relative;
    z-index: auto;
    line-height: 17px;
}

/* Tooltip text */
.tooltip .tooltiptext {
    background-color: #555;
    border-radius: 6px;
    bottom: 125%;
    color: #fff;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    padding: 5px 0;

    /* Position the tooltip text */
    position: absolute;
    text-align: center;
    transition: opacity 0.3s;
    visibility: hidden;
    width: 120px;
    z-index: 100;
}

.tooltip-discontinued {
    width: 240px !important;
    left: 0 !important;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
    border-color: #555 transparent transparent transparent;
    border-style: solid;
    border-width: 5px;
    content: '';
    left: 50%;
    margin-left: -5px;
    position: absolute;
    top: 100%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    opacity: 1;
    visibility: visible;
    z-index: $layer60;
}

ul.menu-orders {
    list-style: none;
    padding: 0;

    > li > a,
    > li > span {
        display: block;
        padding-left: 20px;
        text-align: left;
    }
}

.forms-search-dsa {
    .form-inline {
        padding: 0px 25px 25px 25px;

        input {
            margin: 5px auto;
            @media (min-width: $mdMaxWidth) {
                margin-right: 5px;
            }
        }
    }

    div:nth-child(1) {
        .form-inline {
            @media (min-width: $smMaxWidth) {
                float: left;
            }
        }
    }

    div:nth-child(2) {
        .form-inline {
            @media (min-width: $smMaxWidth) {
                float: right;
            }
        }
    }
}

.text-more-size {
    font-size: 1.2em;
}

.wrapper__tracking-status-bar {

    &.preview {
        .tracking-status-bar {
            display: flex;
            align-items: center;
            margin: 6em 2em 0em 2em;
            font-size: 1rem;
            @media screen and (max-width: $mdMaxWidth) {
                flex-direction: column;
                align-items: center;
                height: 20em;
                width: 0;
                margin: 2em auto;
                transform: translate(-3em);
            }

            &__item {
                display: flex;
                flex-direction: column;
                position: relative;
                @media screen and (max-width: $mdMaxWidth) {
                    flex-direction: row;
                }

                &__sphere {
                    color: #888;
                    background: $iron;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1em;
                    line-height: 2em;
                    height: 1em;

                    &::before {
                        position: absolute;
                        content: '';
                        width: 0.2em;
                        height: 0.4em;
                        background-color: $aluminum;
                    }

                    &__condensed {
                        border-radius: 50%;
                        color: $iron;
                        width: 1em;
                        height: 1em;

                        &::before {
                            content: none;
                        }
                    }

                    @media screen and (max-width: $mdMaxWidth) {
                        width: 0.2em;
                        height: 0;
                        &::before {
                            content: '';
                            width: 0.4em;
                            height: 0.2em;
                            background-color: $aluminum;
                        }
                    }
                }

                &__date {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0);
                    white-space: nowrap;
                    bottom: 2em;
                    text-align: center;
                    margin-bottom: 1rem;
                    font-size: 11px;

                    &__highlighted {
                        span {
                            color: $normalblue !important;
                            font-weight: 500 !important;
                            font-size: 16px;
                        }
                    }

                    @media screen and (max-width: $mdMaxWidth) {
                        bottom: 0em;
                        left: 1.2em;
                        transform: unset;
                    }
                    @media screen and (max-width: $xsMaxWidth) {
                        display: block;
                    }
                }

                &__text {
                    font-size: 0.8em;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0);
                    top: 2em;
                    text-align: center;
                    @media screen and (max-width: $mdMaxWidth) {
                        white-space: nowrap;
                        top: 0em;
                        left: 1.2em;
                        transform: unset;
                    }
                    @media screen and (max-width: $xsMaxWidth) {
                        display: block;
                    }
                }

                &__date,
                &__date__warning {
                    div {
                        span {
                            color: #7D7D7D;
                            font-weight: 400;
                            font-size: 12px;
                        }
                    }
                }

                &--active {
                    .tracking-status-bar__item {
                        &__sphere {
                            border-radius: 50%;
                            background: rgb(155, 194, 199);
                            color: #fff;
                            width: 1em;
                            height: 1em;

                            &::before {
                                content: unset;
                            }
                        }

                        &__date,
                        &__text {
                            font-weight: 400;
                        }

                        &__date {
                            @media screen and (max-width: $mdMaxWidth) {
                                bottom: 0.5em;
                                left: 1.7em;
                                transform: unset;
                            }
                        }

                        &__text {
                            @media screen and (max-width: $mdMaxWidth) {
                                top: 0.5em;
                                left: 1.7em;
                                transform: unset;
                            }
                        }

                        &__date,
                        &__date__warning {
                            div {
                                span {
                                    color: $primaryColor;
                                }
                            }
                        }
                    }
                }
            }

            &__line {
                background: $iron;
                display: inline-block;
                height: 0.24em;
                flex-grow: 1;
                @media screen and (max-width: $mdMaxWidth) {
                    width: 0.24em;
                    height: unset;
                }

                &--active {
                    background: rgb(155, 194, 199);
                }

                &__condensed {
                    height: 0.12em;
                }
            }
            &__vertical {
                flex-direction: column;
                align-items: center;
                height: 104px;
                width: unset;
                margin: 1em auto 1.5em auto;
                transform: none;

                @media (max-width: $smMaxWidth) {
                    margin: 1em auto 0 auto;
                }

                .tracking-status-bar__item__date,
                .tracking-status-bar__item__date__warning {
                    div {
                        span {
                            white-space: unset !important;
                            font-size: 16px !important;
                        }

                    }
                }

                &.tracking-status-bar{
                    .tracking-status-bar{
                        &__item {
                            flex-direction: row;

                            div:nth-child(2){
                                display: flex;
                                flex-direction: column ;

                                i{
                                    position:absolute;
                                    margin: 0!important;
                                    padding: 0!important;
                                    left: 55px;
                                    top: 30px;

                                }
                                div{
                                    font-size: 14px;
                                    margin: 0!important;
                                    padding: 0!important;
                                    display: flex;
                                    flex-direction: column-reverse;
                                    position: relative;
                                    align-items: center;
                                    left: 0px;
                                    top: 25px;
                                    div{
                                        display: unset;
                                        position: unset;
                                    }
                                }

                            }

                            &__sphere {                    
                                width: 0.2em;
                                height: 0;
                                &::before {
                                    content: '';
                                    width: 0.4em;
                                    height: 0.2em;
                                    background-color: $aluminum;
                                }
                            }

                            &__date{
                                bottom: 0em;
                                left: 24px;
                                transform: unset;

                                &> div{
                                    align-items: flex-start !important;
                                }

                                div{
                                    span{
                                        white-space: unset;
                                    }
                                }
                            }
                            &__text {
                                white-space: nowrap;
                                top: 0em;
                                left: 1.2em;
                                transform: unset;
                                color: $tertiaryText !important;
                                font-weight: 400 !important;
                            }

                            &--active {
                                .tracking-status-bar__item {

                                    &__sphere {
                                        border-radius: 50%;
                                        background: rgb(155, 194, 199);
                                        color: #fff;
                                        width: 1em;
                                        height: 1em;

                                        &::before {
                                            content: unset;
                                        }
                                    }

                                    &__date {
                                        bottom: 0.5em;
                                        left: 30px;
                                        transform: unset;
                                    }

                                    &__text {
                                        top: 0.5em;
                                        left: 1.7em;
                                        transform: unset;
                                    }
                                }
                            }
                        }
                        &__line {
                            width: 0.24em;
                            height: unset;
                        }
                    }

                }
            }

            &__horizontal {
                margin: 24px 120px 48px 120px;
                flex-direction: unset;
                //align-items: unset;
                height: unset;
                width: unset;
                transform: unset;

                @media screen and (max-width: $lgMaxWidth) {
                    margin: 24px 120px 56px 120px;
                }

                @media screen and (max-width: $mdMaxWidth) {
                    margin: 24px 80px 56px 80px;
                }

                @media screen and (max-width: $smMaxWidth) {
                    margin: 24px 88px 56px 88px;
                }

                .tracking-status-bar__item__date,
                .tracking-status-bar__item__date__warning {
                    div {
                        span {
                            white-space: unset !important;
                            max-width: unset;
                            font-size: 16px !important;

                            @media screen and (max-width: $smMaxWidth) {
                                display: none !important;
                            }

                            &.tracking-status-bar__item__date__status{
                                color: $normalblue;
                                font-weight: 500;
                                display: flex !important;
                            }
                        }

                    }
                }

                &.tracking-status-bar {
                    .tracking-status-bar {
                        &__item {
                            flex-direction: column;

                            &__date{
                                bottom: unset;
                                top: 24px;
                                left: 50%;
                                transform: translate(-50%, 0);

                                @media screen and (max-width: $mdMaxWidth) {
                                    top: 16px;
                                }

                                &> div{
                                    top: unset;
                                    left: unset;
                                    font-size: 16px;
                                }
                            }

                            &--active {
                                .tracking-status-bar__item__date {
                                    top: 24px;
                                }

                                .tracking-status-bar__item__sphere {
                                    &::before {
                                        content: unset;
                                    }
                                }
                            }

                            &__sphere{
                                &::before{
                                    //position: absolute;
                                    content: '';
                                    width: 0.2em;
                                    height: 0.4em;
                                    background-color: #999999;
                                }
                            }

                            &__text{
                                color: $tertiaryText !important;
                                font-weight: 400 !important;
                                font-size: 14px !important;

                                @media screen and (max-width: $smMaxWidth) {
                                    display: none !important;
                                }
                            }
                        }

                        &__line{
                            display: inline-block;
                            height: 0.24em;
                            flex-grow: 1;
                        }
                    }
                }
            }
        }
    }

    /* Versión sin preview..*/
    .tracking-status-bar {
        display: flex;
        align-items: center;
        margin: 4em 2em;
        font-size: 1rem;
        @media screen and (max-width: $mdMaxWidth) and (min-width: $xsMaxWidth) {
            flex-direction: column;
            align-items: center;
            height: 20em;
            width: 0;
            margin: 2em auto;
            transform: translate(-3em);
        }

        &__item {
            display: flex;
            flex-direction: column;
            position: relative;
            @media screen and (max-width: $mdMaxWidth) and (min-width: $xsMaxWidth) {
                flex-direction: row;
            }

            &--action {
                cursor: pointer;
            }

            &--disabled {
                cursor: not-allowed;
            }

            &__sphere {
                color: #888;
                background: $iron;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1em;
                line-height: 2em;
                height: 1em;

                &::before {
                    position: absolute;
                    content: '';
                    width: 0.2em;
                    height: 0.4em;
                    background-color: $aluminum;
                }

                @media screen and (max-width: $mdMaxWidth) and (min-width: $xsMaxWidth) {
                    width: 0.2em;
                    height: 0;
                    &::before {
                        content: '';
                        width: 0.4em;
                        height: 0.2em;
                        background-color: $aluminum;
                    }
                }
            }

            &__date {
                font-size: 0.8em;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                white-space: nowrap;
                bottom: 2em;
                text-align: center;
                @media screen and (max-width: $mdMaxWidth) and (min-width: $xsMaxWidth) {
                    bottom: 0em;
                    left: 1.2em;
                    transform: unset;
                }
                @media screen and (max-width: $xsMaxWidth) {
                    display: none;
                    &__warning {
                        top: 0em;
                        left: -5.8em;
                        transform: unset;
                    }
                }
            }

            &__text {
                font-size: 0.8em;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                top: 2em;
                text-align: center;
                min-width: 120px;
                // margin: 1px 45px; disabled, cause wrecks the steps component
                @media screen and (max-width: $mdMaxWidth) and (min-width: $xsMaxWidth) {
                    white-space: nowrap;
                    top: 0em;
                    left: 1.2em;
                    transform: unset;
                }
                @media screen and (max-width: $xsMaxWidth) {
                    display: none;
                }
            }

            &--active {
                .tracking-status-bar__item {
                    &__sphere {
                        border-radius: 50%;
                        background: rgb(155, 194, 199);
                        color: #fff;
                        width: 1em;
                        height: 1em;

                        &::before {
                            content: unset;
                        }
                    }

                    &__date,
                    &__text {
                        font-weight: bold;
                    }

                    &__date {
                        @media screen and (max-width: $mdMaxWidth) and (min-width: $xsMaxWidth) {
                            bottom: 0.5em;
                            left: 1.7em;
                            transform: unset;
                        }
                    }

                    &__text {
                        @media screen and (max-width: $mdMaxWidth) and (min-width: $xsMaxWidth) {
                            top: 0.5em;
                            left: 1.7em;
                            transform: unset;
                        }
                    }
                }
            }
        }

        &__line {
            background: $iron;
            display: inline-block;
            height: 0.24em;
            flex-grow: 1;
            @media screen and (max-width: $mdMaxWidth) and (min-width: $xsMaxWidth) {
                width: 0.24em;
                height: unset;
            }

            &--active {
                background: rgb(155, 194, 199);
            }
        }
    }

    .timeline-advert {
        display: none;
    }

    &.detail-view {
        .tracking-status-bar__item {
            &.focus {
                @media screen and (max-width: $xsMaxWidth) {
                    .tracking-status-bar__item {
                        &__sphere {
                            width: 1.25rem;
                            height: 1.25rem;
                        }

                        &__date {
                            display: block;
                            top: 2em;
                        }

                        &__text {
                            display: block;
                            top: 3.5em;
                            white-space: nowrap;
                            font-weight: normal;
                        }
                    }
                }
            }
        }

        .timeline-advert.show {
            @media screen and (max-width: $xsMaxWidth) {
                display: flex;
                justify-content: center;
            }
        }
    }
}

.timeline-text-div__vertical{
    visibility: hidden;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    margin-inline-start: 14px;
}

.vertical-timeline-container{
    @media screen and (max-width: $smMaxWidth) {
        margin-inline-start: 16px;
        width: auto;
    }
}
.tracking-progress-bar {
    margin: 0;
}

.tracking-progress-bar__item {
    background: $iron;
    border-radius: 50%;
    color: #888;
    display: inline-block;
    font-family: sans-serif;
    font-size: 17px;
    height: 30px;
    line-height: 30px;
    position: relative;
    text-align: center;
    width: 31px;
}

.tracking-progress-bar__item__bar {
    background: $iron;
    display: inline-block;
    height: 4px;
    margin: 3px -5px;
    width: calc(50% - 93px);
}

.error .tracking-progress-bar__item__bar {
    width: calc(100% - 93px);
}

.tracking-progress-bar__item__bar--active {
    background: rgb(155, 194, 199);
}

.tracking-progress-bar__item--first {
    margin-left: 0;
}

.tracking-progress-bar__item--active {
    background: rgb(155, 194, 199);
    color: #fff;
}

.pp-align-center {
    padding-right: 2px;
}

.td-align-center {
    padding-left: 2px;
}

.icon-align-vertical {
    padding-top: 6px;
}

#back-to-old {
    background: $lightGreen;
    font-size: 13px;
    padding: 5px;
    text-align: center;

    a {
        text-decoration: underline !important;
    }

    .bt-close {
        color: $onyx;
        cursor: pointer;
        opacity: 0.7;

        &:hover {
            opacity: 1;
        }
    }
}

.error-template {
    text-align: center;
}

@media (min-width: 768px) {
    .error-template {
        align-items: center;
        display: flex;
        height: calc(100vh - 346px);
        justify-content: center;

        div.bt {
            margin-top: 5px;
        }
    }
    .fill-height-template {
        align-items: center;
        display: flex;
        height: calc(100vh - 346px);
        justify-content: center;

        div.bt {
            margin-top: 5px;
        }
    }
}

i.only-icon {
    margin-left: -6px;
}

.badge-emails {
    margin: 2px 4px;
}

.product-detail .product-image .fit-image.add-favourite,
.add-favourite {
    height: 100px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;
}

section {
    // min-height: calc(100vh - 300px); //MOVED TO wrapper-contain to avoid carrousels white spaces
    padding-bottom: 40px;
}

.heigth35 {
    height: 35px;
}

.Select.form-control {
    padding: 0 0.75rem;

    .Select-control {
        position: unset;
    }
}

section.suggestions {
    background: $smoke;

    .container {
        padding: 20px 0;

        .suggestions-form {
            background-color: white;
            padding: 20px 0;

            form {
                textarea {
                    height: 200px;
                    width: 100%;
                }

                .buttons {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                }

                input,
                textarea,
                .Select {
                    margin: 5px 0;
                }
            }

            .suggestion__label_count,
            .suggestion__the_count {
                margin-top: 15px;
            }

            .suggestion__the_count {
                text-align: right;

                .current {
                    &.validate {
                        color: green;
                    }

                    &.error {
                        color: red;
                    }
                }
            }
        }
    }
}

.list_requirements {
    height: 120px;
    opacity: 1;
    transition: 0.5s;

    &--hidden {
        height: 0px;
        margin: 0;
        opacity: 0;
        padding: 0;
    }

    .list_requirements__item-success {
        color: $greenOk;
        list-style: none;

        &:before {
            content: '✓';
            margin-left: -20px;
            position: absolute;
        }
    }

    .list_requirements__item-failure {
        color: red;
    }
}

.box {
    background: #ccc;
    border: 1px solid black;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    margin: 20px;
}

.step {
    background: #555;
    color: #fff;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    min-width: 100px;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 24px;
    margin-right: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.product-title {
    font-family: $titleFont;
    text-transform: capitalize;
}

.badge-primary {
    background: rgba(26, 26, 26, 0.85);
}

.tip {
    margin-left: 5px;
    font-size: 12px;
}

.red-tip {
    color: red;
}

.displayNone {
    display: none;
}

.ReactTable {
    .rt-tr {
        transition: 0.5s;

        &.edited {
            border: 1px solid lightgray;
        }

        &.reject {
            overflow: hidden;
            position: relative;

            &:after {
                background-color: rgba(150, 150, 150, 0.2);
                content: '';
                height: 100%;
                position: absolute;
                width: 100%;
            }
        }
    }

    .rt-noData {
        pointer-events: unset;
    }
}

/* .new-indicator {
    background: #86baae;
    border-radius: 2px;
    box-shadow: rgba($onyx, 0.3) 1px 1px 3px;
    color: $white;
    font-size: 11px;
    font-weight: bold;
    padding: 3px 5px 0;
    z-index: 1;
    width: fit-content;

    &--black {
        background-color: #4d4d4d;
    }
} */

.reject-indicator {
    background: #c5051c;
    border-radius: 2px;
    box-shadow: rgba($onyx, 0.3) 1px 1px 3px;
    color: $white;
    font-size: 11px;
    font-weight: bold;
    padding: 3px 5px 0;
    z-index: 1;
    width: fit-content;
}

.reject-col {
    display: flex;
    justify-content: center;
}

.center4000 {
    background-image: url('../img/logoikea.png') !important;
    background-position: center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}

.indicators {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    max-width: 85%;
    padding: 8px;
    gap: 2px;
}

.text_link {
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}

.a_link{
    color: mediumblue;
    text-decoration-color: mediumblue;
    text-decoration: underline;
    cursor: pointer;

    &:hover{
        color: blue;
        text-decoration-color: mediumblue;
    }
}

//video-react video
.video-react-big-play-button.video-react-big-play-button-left {
    top: calc(50% - 1.5em);
    left: calc(50% - 1.5em);
}

[dir='rtl'] {
    body {
        text-align: right;
    }
}

.changelog-items li {
    .changelog–items__title {
        cursor: pointer;
        font-weight: 400;
    }
}

.button_info {
    &.button_info--changelog {
        position: relative;
        display: inline;
    }
}

.border {
    border-color: #ddd !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-bottom: 0;
}

.CookieDeclaration {
    & > * {
        display: none !important;
    }

    & .CookieDeclarationType {
        display: block !important;

        & .CookieDeclarationTypeDescription {
            display: none !important;
        }
    }
}

.dekton-grip-modal {
    margin: 20vh auto;
    @media (max-width: $mdMaxWidth) {
        height: 100%;
        width: 100%;
        margin: 0;
        max-width: 100%;
        max-height: 100%;
        overflow-x: unset;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        border-radius: 0;
        &.modal-dialog .modal-content {
            height: 100%;
            max-height: 100%;
            border-radius: 0;
        }
    }

    a {
        font-weight: 400;

        &:hover {
            text-decoration: underline !important;
        }
    }

    .group_buttons {
        & > div {
            min-width: 150px;
        }
    }
}

$table-header: #07887d;
$table-header-border: #07695e;
$table-border: #d9d9d9;
$row-bg: #f4f2f1;

.table-container {
    display: block;

    .flex-table {
        display: flex;
        flex-flow: row wrap;
        border-left: solid 1px $table-border;
        transition: 0.5s;

        &:first-of-type {
            border-top: solid 1px $table-header-border;
            border-left: solid 1px $table-header-border;
        }

        &:first-of-type .flex-row {
            background: $table-header;
            color: white;
            border-color: $table-header-border;
        }

        &.row:nth-child(odd) .flex-row {
            background: $row-bg;
        }

        &:hover {
            background: whitesmoke;
            transition: 500ms;
        }
    }

    .flex-row {
        width: calc(100% / 5);
        text-align: center;
        padding: 0.5em 0.5em;
        border-right: solid 1px $table-border;
        border-bottom: solid 1px $table-border;
    }

    @media all and (max-width: 767px) {
        .flex-row {
            width: calc(100% / 4);

            &.first {
                width: 100%;
            }
        }
    }

    @media all and (max-width: 430px) {
        .flex-table {
            .flex-row {
                border-bottom: 0;
            }

            .flex-row:last-of-type {
                border-bottom: solid 1px $table-border;
            }
        }

        .header {
            .flex-row {
                border-bottom: solid 1px;
            }
        }

        .flex-row {
            width: 100%;

            &.first {
                width: 100%;
                border-bottom: solid 1px $table-border;
            }
        }
    }
}

.order_details {
    .group_buttons {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .progress {
        height: 0.5rem;
    }
}

.form-control:disabled,
.form-control[readonly] {
    background: $smoke;
    cursor: not-allowed;
}

.Select {
    background-color: white;
}

//react-toggle
.react-toggle.react-toggle-small {
    .react-toggle-track {
        width: 46px;
        height: 20px;
    }

    .react-toggle-thumb {
        width: 18px;
        height: 18px;
    }
}

//START fix kendo's dropdown
.k-animation-container {
    z-index: 10003 !important;
}

//END fix kendo's dropdown

.k-notification-group {
    padding: 1.5rem;
    z-index: 9999;
    max-height: 100%;
    max-width: 100%;

    &.x-center {
        left: 50%;
        transform: translateX(-50%);
    }

    &.y-center {
        top: 50%;
        transform: translateY(-50%);
    }

    &.x-center.y-center {
        transform: translate(-50%, -50%);
    }

    &.left {
        left: 0;
        align-items: flex-start !important;
    }

    &.right {
        right: 0;
        align-items: flex-end !important;
    }

    &.bottom {
        bottom: 0;
        flex-direction: column;
    }

    &.top {
        top: 0;
        flex-direction: column-reverse;
    }
}

.k-notification-container {
    .k-notification {
        padding: 0.5rem;
        font-size: 1rem;
        line-height: 1.2;
        border-radius: 4px;

        .k-icon {
            font-size: 1rem;
            line-height: 1.2;
            width: auto;
            height: auto;
            margin-right: 0.5rem;

            &.k-i-close {
                margin-left: 1.5rem;
                margin-right: 0;
            }
        }
    }
}

.v2 {
    .progress-bar-container {
        .bar {
        overflow-x: visible;
            .segments {
                height: 4px;
            }
            .progress-fill {
                height: 4px;
            }
        }

        .progress-fill-child {
            display: flex;

            .progress-fill-child-container {
                position: relative;
                max-width: 90%;
                width: 100%;

                @media(max-width: 768px) {
                    max-width: 85%;
                }

                .progress-fill-indicator {
                    position: absolute;
                    font-size: 14px;
                    font-family: $infoFont;
                    font-weight: 600;
                    color: $primaryColor;
                    white-space: nowrap;

                    span {
                        position: absolute;
                    }
                }
            }
        }
    }

    .slick-dots li.slick-active button:before {
        font-size: 13px;
    }
    .circle {
        border-radius: 50%;
        height:40px;
        text-align: center;
        width: 40px;
        border:1px solid black;
    }

    .initials {
        font-size: calc(40px / 2);
        line-height: 1;
        position: relative;
        top: calc(40px / 4);
    }

    #product-detail{
        .Select-control{
            border:1px solid $primaryColor;
        }
    }
    .Select_center{
        .Select-control{
            border:1px solid $primaryColor;
        }
    }
    @media (min-width: $xlgMaxWidth) {
        .container {
            max-width:1378px;
        }
    }
    #section-catalog{
        background: $backgroundV2;
    }
    /* .product-title {
        font-family: 'diagramm-medium';
        font-weight:500;
        text-transform: capitalize;
    } */
    .modal{
        .modal-content{
            //padding: 16px 24px;
            border-radius: 0;

            .modal-header {
                text-align: left;
                .modal-title {
                    font-family:'diagramm-medium';
                    font-size: 20px;
                    font-weight: 600;
                    color: $primaryColor;

                    @media (max-width: $smMaxWidth) {
                        font-size: 16px;
                    }
                }

                .close {
                    i::before {
                        color: $primaryColor;
                    }
                }
            }
        }
    }
}

#CybotCookiebotDialogHeader {
    display: none !important;
}

.horizontal-line {
    width: 100%;
    height: 1px;
    background-color: $iron;
}

.sky {
    background-color: $sky !important;
}
.lot-viewer-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: $mdMaxWidth) {
        display: block;
    }
}

.ril__toolbarItem {
    @media screen and (max-width: $mdMaxWidth) {
        line-height: 30px!important;
        white-space: normal!important;
    }
}

.ril__captionContent{
    width: 100%;
}

.ril__toolbar, .ril__toolbarSide {
    @media screen and (max-width: $mdMaxWidth) {
        height: fit-content!important;
    }
}

.ril__navButtonNext {
    right: 3vw !important;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMSAyMS4xMjUiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6IG5vbmU7CiAgICAgIH0KCiAgICAgIC5jbHMtMiB7CiAgICAgICAgZmlsbDogI2ZmZmZmZjsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPGcgaWQ9Ikdyb3VwXzExIiBkYXRhLW5hbWU9Ikdyb3VwIDExIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNzcgMzI3LjEyNSkgcm90YXRlKDE4MCkiPgogICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV8xIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAxIiBjbGFzcz0iY2xzLTEiIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzU2IDMwNikiLz4KICAgIDxwYXRoIGlkPSJhbmdsZS1jaGV2cm9uLWxlZnRfY29weSIgZGF0YS1uYW1lPSJhbmdsZS1jaGV2cm9uLWxlZnQgY29weSIgY2xhc3M9ImNscy0yIiBkPSJNNC42LDEwLjZsNy4zLTcuM2EyLDIsMCwwLDAsMC0yLjdBMiwyLDAsMCwwLDkuMi42TC42LDkuM2gwQTIsMiwwLDAsMCwuNiwxMmw4LjYsOC42YTEuOTM2LDEuOTM2LDAsMCwwLDEuMy42LDEuNjEyLDEuNjEyLDAsMCwwLDEuMy0uNiwyLDIsMCwwLDAsMC0yLjdaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNTkuOTI1IDMwNS45MjUpIi8+CiAgPC9nPgo8L3N2Zz4K) no-repeat center !important;
    @media screen and (max-width: $mdMaxWidth) {
        right: 1.5vw !important;
        background-size: 40px!important;
    }
}

.ril__navButtonPrev {
    left: 3vw !important;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMSAyMS4xMjUiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6IG5vbmU7CiAgICAgIH0KCiAgICAgIC5jbHMtMiB7CiAgICAgICAgZmlsbDogI2ZmZmZmZjsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPGcgaWQ9Ikdyb3VwXzEwIiBkYXRhLW5hbWU9Ikdyb3VwIDEwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzU2IC0zMDYpIj4KICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgMSIgY2xhc3M9ImNscy0xIiB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM1NiAzMDYpIi8+CiAgICA8cGF0aCBpZD0iYW5nbGUtY2hldnJvbi1sZWZ0X2NvcHkiIGRhdGEtbmFtZT0iYW5nbGUtY2hldnJvbi1sZWZ0IGNvcHkiIGNsYXNzPSJjbHMtMiIgZD0iTTQuNiwxMC42bDcuMy03LjNhMiwyLDAsMCwwLDAtMi43QTIsMiwwLDAsMCw5LjIuNkwuNiw5LjNoMEEyLDIsMCwwLDAsLjYsMTJsOC42LDguNmExLjkzNiwxLjkzNiwwLDAsMCwxLjMuNiwxLjYxMiwxLjYxMiwwLDAsMCwxLjMtLjYsMiwyLDAsMCwwLDAtMi43WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzU5LjkyNSAzMDUuOTI1KSIvPgogIDwvZz4KPC9zdmc+Cg==) no-repeat center !important;
    @media screen and (max-width: $mdMaxWidth) {
        left: 1.5vw !important;
        background-size: 40px!important;
    }
}

.advertisement {

    #modal-advertisement.modal-advertisement-container {

        @media (max-width: $mdMaxWidth) {
            left: 0;
            bottom: 0;
            width: 100%;
            max-width: 100%;
            overflow-x: none;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }

        .logo-container {
            height: calc(100vh - 300px);
            max-height: 300px;
            background-size: cover;
            background-position: center;
        }

        max-width: 568px;
        background: #d9dedf;

        .card-container {
            background: #d9dedf;
        }

        h3 {
            color: #333;
        }

        label {
            color: #333;
        }

        #profile-img {
            top: 0;
            width: 100%;
            margin: 0;
            padding: 0;
        }

        input[type='email'],
        input[type='password'],
        input[type='text'],
        button {
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border-radius: 20px;
        }

        input[type='email'],
        input[type='password'],
        input[type='text'] {
            margin-right: 8px;
        }
    }

    #modal-advertisement {
        position: fixed;
        top: 40vh!important;
        transform: translateY(-50%);
        right: 0;
        left: 0;
        margin: auto;
        width: 100%;
        max-width: 950px;

        z-index: $layer50;
        @media(min-width: $mdMaxWidth)and(max-width:$lgMaxWidth){
            top: 5%;
        }
        @media (max-width: $mdMaxWidth) {
            background: $lightWhite;
            left: 0;
            width: 100%;
            overflow-x: none;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }
    }

}


.cross-selling-modal {
    position:absolute;
    //right:0;
    inset-inline-end:0px;
    min-width: 350px;
    z-index: 99;
    //.tip-modal-arrow {
    //    inset-inline-end: 152px!important;
    //    @media (max-width: $mdMaxWidth) {
    //        inset-inline-end: 50px!important;
    //    }
    //}
    .line-item .img {
        width: 50%;
    }

    .rectangle-result {
        flex-direction: row!important;
        margin: 4px 0 2px;
    }

    .cross-selling-list-section {
        background: #e8edef;
        margin:4px -16px 0;
        padding:8px 16px 0px;
    }
    .cross-selling-list {
        min-height: 80px;
        max-height: calc(100vh - 400px);
        overflow-y: auto;
        overflow-x: clip;
    }
    .product-title {
        font-family: $infoFont;
        font-weight: 500;
        font-size: 14px;
        color: $primaryColor;
    }
    .product-brand  {
        font-size: 12px;
        font-weight: 400;
        text-transform: capitalize;
        color: $primaryColor;
        font-family: $infoFont;
        display: inline-block;
    }

    .product-actions {
        margin-top: 4px;
    }
    .spin.minus, .spin.plus {
        background: white!important;
        width: 2.5em!important;
        border: none!important;
    }
    .inner-input-number {
        width: 3em!important;
        background: white!important;
        font-size: 12px!important;
        font-weight: bold!important;
    }
    .bt-add-cart {
        background: none;
        color: black;
        font-weight: 500!important;
    }
    .finish.price {
        font-size: 0.9em;
    }
    .subtitle {
        display: flex;
    }
    .mini_cart_line_info__line {
        font-size: 0.9em;
        text-transform: capitalize;
        line-height: 1.4em;
    }

    .product-info-details .product_detail .text.price {
        font-size: 14px !important;
    }
}
.single-line-item {
    font-size: 12px;
    padding: 12px 0;
        // .product-image-container {
        //     border: none;
        // }
}
.promotion-home-banner {
    @media (orientation: landscape) {
        .promotion-home-banner-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
            background: none !important;
        }
        .promotion-home-banner-image {
            display: block;
        }
      }

      @media (orientation: portrait) {
        .promotion-home-banner-text {
            position: relative;
            padding: 5% 10%;
            background-position: center;
            background-size: cover;
        }
        .promotion-home-banner-image {
            display: none;
        }
      }
}

.box-input-number {
    display: flex;
    align-items: center;
    height: 3em;
}

.box-text-input-number {
    padding-left: 0.4em;
}

.box-btn-input-number {
    width: 4em;
    border: 1px solid #505050;
    border-radius: 5px;
    display: flex;
    position: relative;
    align-items: center;
    height: 100%;
}

.box-left.disabled {
    color: lightgrey!important;
}

.input-number-other.disabled {
    color: lightgrey!important;
}

.box-left {
    float: left;
    padding-left: 0.5em;
    color: #505050;
    position: absolute;
    height: 100%;
    top: 0;
}

.input-number-other {
    background: transparent;
    border: none;
    padding-right: 0.4em;
    color: #505050;
    -moz-appearance: textfield;
    position: absolute;
    width: 100%;
    text-align: end;
}

.input-number-other:focus {
    outline: none !important;
}

.input-number-other::-webkit-inner-spin-button,
.input-number-other::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-number-button.disabled {
    cursor: default;
}

.box-text-input-number {
    color: #505050;
    text-transform: lowercase;
}

.input-number-button {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    i {
        height: fit-content;
        position: absolute;
    }

    .up {
        top: 0.25em;
    }

    .down {
        bottom:0.25em;
    }
}

.carousel-custom {
    background-color: $primaryColor !important;
}

.carousel-custom div[class='ril-toolbar ril__toolbar']{
    background-color: $primaryColor;
}

.carousel-custom div[class='ril-caption ril__caption']{
    background-color: $primaryColor;
}

.select-wrapper-v2 {
    padding: 0;
    position: relative;
    user-select: none;
}
.select-v2 {
    position: relative;
    display: flex;
    flex-direction: column;
    border-width: 0 2px 0 2px;
    font-weight: normal;
    font-size: 12px;
}

.select__trigger-v2 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #3b3b3b;
    height: 37px;
    cursor: pointer;
    padding: 8px;
    border: 1px solid black;
    -webkit-appearance: none !important;
    background-color: transparent;
    white-space: nowrap;
    width: max-content;
    font-size: medium;
    span {
        margin-right: 0.5rem;
    }

    @media screen and (max-width: 1199px){
        span {
            margin-right: 0
        }
    }
}
.custom-options-v2 {
    flex-direction: column;
    position: absolute;
    display: flex;
    top: 100%;
    left: -1px;
    right: -1px;
    border: 1px solid black;
    background: #fff;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    cursor: pointer;
    a {
        padding-left: 10px;
    }
    a:hover {
        background: #DBE4E9;
    }
}
.select-v2.open-v2 .custom-options-v2 {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
}
.custom-option-v2 {
    position: relative;
    display: block;
    padding: 0 9px 0 9px;
    font-size: 11px;
    font-weight: 300;
    color: #3b3b3b;
    line-height: 30px;
    cursor: pointer;
    //transition: all 0.5s;
    margin: 4px;
}
.custom-option-v2:hover {
    cursor: pointer;
    background-color: #D7E3E7;
}
.custom-option-v2.selected {
    color: #ffffff;
    background-color: #305c91;
}

.box-input-number-v2 {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
}

.box-price-v2 {
    border: 1px solid #E3E3E3;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 1rem;
    span{
        font-size: 2rem;
        font-weight: bold;
    }
}

.box-btn-left-v2 {
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    padding: 0.9rem;
    cursor: pointer;
    background-color: #F6F6F6;
    justify-content: center;
}

.box-btn-input-number-v2 {
    border-radius: 0.3rem;
    width: 64px;
    height: 50px;
    position: relative;
    background-color: #F6F6F6;
    margin-left: 3px;
    margin-right: 3px;
    display: flex;
    justify-content: center;
}

.box-btn-right-v2 {
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    padding: 0.9rem;
    cursor: pointer;
    background-color: #F6F6F6;
    justify-content: center;

}

.input-number-product-v2 {
    background: transparent;
    border: none;
    padding: 7px;
    color: #505050;
    -moz-appearance: textfield;
    padding-top: 0px;
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 2rem;
}

.bt-add-to-cart-product-v2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    border: none !important;
    font-weight: bold !important;
    border-radius: 0 !important;
}

.bt-add-to-cart-product-v2:hover {
    background-color: $primaryColorHover !important;
    color: $primaryColor !important;
}

.buttons-lots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 8px 0;

    font-size: 14px;
    font-weight: 500;
    border: 1px solid $primaryColor;
    border-radius: 0;
    color: $primaryColor;
    padding: 8px 32px;
    line-height: 20px;
    background-color: transparent;

    img {
        width: 100%;
        height: 100%;
        max-width: 16px;
    }

    &:hover {
       background-color: $primaryColorHover;
       color: $primaryColor;
    }
}

.bt-add-cart-v2 {
    border: none !important;
    font-weight: bold !important;
    border-radius: 0 !important;
    height: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bt-add-cart-v2:hover {
    background-color: $primaryColorHover !important;
    color: $primaryColor;
}

.btn-add-to-cart-text {
    font-size: 12px;
}

.fit-image-v2 {
    border-start-start-radius: 6px;
    border-start-end-radius: 6px;
}

@media screen and (min-width:0px) and (max-width: 767px) {
    .banner-visitas-virtuales-hidden-v2 {
        display: none !important;
    }
}

.sk-select-v2{
    display: inline;
    font-size: 12px;
}

.sk-select-v2>select{
    padding: 0.2rem 2rem 0.2rem 1rem;
    border-color: #B7B7B7;
    -webkit-appearance: none !important;
    background-color: white;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
    background-position: 100%;
    background-repeat: no-repeat;
}

.sk-select-v2>select>option{
    font-size: 0.8rem;
}

.bt-reset-searchbox:before {
    top: 0px !important;
}

.add-favourite.v2{
    width: 100% !important;
}

.finished.v2 {
    font-weight: bold;
    .finished-title{
        margin-bottom: 0;
    }
}

.product-title.v2 {
    font-family: 'diagramm-medium' !important;
    margin-bottom: 0;
}

.datepicker {
    min-width: 7em;
    text-align: center;
}

.banner_hybriq {
    margin-bottom: 20px;
    margin-top: 20px;
}
.banner_pietrakode {
    margin-bottom: 20px;
}

.dekton-banner-desktop{
    @media (max-width: 777px) {
        display: none;
    }
}
.dekton-banner-squared{
    display: none;
    @media (max-width: 777px) {
        display: flex;
        justify-content: center;
    }
}
.banner_dekton {
    display: grid;
    grid-auto-columns: .7fr 1fr .9fr;
    grid-auto-rows: 235px;
    grid-auto-flow: column;
    grid-template-areas: "first second third";
    background-color: #EFF0EB;
    height: 235px;
    margin-bottom: 20px;
    margin-top: 20px;

    @media (max-width: 991px) {
        height: 140px;
        grid-auto-rows: 140px;
    }

    .logo-dekton {
        grid-area: first;
        border-right: solid 1px black;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            height: 45px;
            @media (max-width: 991px) {
                height: 2em;
            }
        }
   }
   .dekton-texts{
        grid-area: second;
        justify-self: center;
        text-align: center;
        padding: 1em;
        h1{
            font-family: 'Diagramm';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 36px;
            text-align: center;
            color: #000000;

            @media (max-width: 991px) {
                font-size: 15px;
                line-height: inherit;
            }
        }
        h2{
            font-family: 'Diagramm';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #000000;

            @media (max-width: 991px) {
                font-size: 9px;
                line-height: inherit;
            }
        }
        div{
            margin-top: 1.5em;
            color: #000000;
            display: flex;
            gap: 1em;
            justify-content: center;

            a:hover {
                text-decoration: underline !important;
            }

            a {
                i {
                    margin-left: .4em;
                }
            }

            @media (max-width: 991px) {
                margin-top: 0em;
                font-size: 10px;
            }
        }
    }

    .dekton-img {
        grid-area: third;
        background-position: initial;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: end;
        align-items: end;

        span {
            background-color: #FFFFFF;
            padding: 8px;
            margin: 1em;
            height: fit-content;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

        }

    }
}

.banner_dekton-responsive {
    display: grid;
    grid-auto-rows: 40px 260px;
    grid-auto-columns: minmax(200px, 300px);
    grid-auto-flow: row;
    grid-template-areas: "first" "second";
    background-color: #EFF0EB;
    max-width: 300px;
    margin-bottom: 20px;
    margin-top: 20px;

    .logo-dekton-responsive {
        grid-area: first;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        border-bottom: 1px solid black;

        img {
            height: 25px;
        }

        span {
            text-transform: uppercase;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            background-color: #FFFFFF;
            padding: 4px;

        }
    }
        .x { //Banner dekton, why the classname is a fucking X
            position: absolute;
            //top: 40px;
            padding-top: 4.5em;
            padding-left: 1em;
            padding-right: 1em;
            // height: -webkit-fill-available; //DISABLED BECAUSE IT BLOCKS FILTERS ON v1
            width: 300px;
            h1 {
                font-family: 'Diagramm';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                margin-top: 1em;
                color: #000000;
            }

            h2 {
                font-family: 'Diagramm';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
                text-align: center;

                color: #000000;
            }

            div {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #000;
                margin-top: 1.5em;
                display: flex;
                flex-direction: column;
                gap: 1em;
                justify-content: center;
                align-items: center;
                a:hover {
                    text-decoration: underline !important;
                }

                a {
                    i {
                        margin-left: .4em;
                    }
                }
            }
        }
    .container-dekton-responsive{
        grid-area: second;
        text-align: center;
        background-position: bottom !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        display: flex;
        flex-direction: column;
        gap: 1em;
        opacity: 0.3;

        img {
            position: absolute;
            opacity: 0.3;
            height: 260px;
            object-fit: cover;
            width: 300px;
        }


    }


}

.pointer {
    cursor: pointer;
}

.product-detail-card {
    &[data-type='PPCC'] {
        .fit-image {
            background-position-y: 10px !important;
        }
    }
}

.product-detail-card {
    &[data-type='FREGADERO'] {
        .fit-image {
            background-size: contain !important;
        }
    }
}

.v2 {
    div[data-cy='catalog__clear_all_filters'] {
        border-radius: 0;
    }

    .rectangle-result {
        border-radius: 0;
    }
}

.fit-image.v2 {
    width: 100% !important;
    .fit-image {
        width: 100% !important;
    }
}

.small {
    .bt-size-medium {
        padding: 4px 15px !important;
    }
}

.no-border {
    border: none !important;
}

.v2.bt {
    border-radius: 0 !important;
}

.v2.bt:hover {
    background-color: #DBE4E9 !important;
    color: $newOnyx !important;
}

.container.product-detail.product-detail-card {
    &[data-type='PPCC'] {
        .product-image {
            background-color: white;
        }
    }
    &[data-type='FREGADERO'] {
        .product-image {
            background-color: white;
        }
    }
}

.item-select:hover {
    background: #DBE4E9;
}

.select_filter_products {
    .Select-control {
        border-radius: 0;
        border-color: black !important;
    }
    .Select-menu-outer {
        div:hover {
            background: #DBE4E9;
        }
        border-color: black;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}

.message {
    color: #000;
    clear: both;
    line-height: 18px;
    font-size: 15px;
    padding: 16px 24px;
    position: relative;
    margin: 2px 10px 8px;
    max-width: 85%;
    word-wrap: break-word;
    z-index: 1;


}
.cosentino-message{
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    img{
    display:flex;
    flex-direction: row;

    border-radius: 100%;
    width: 36px;
    height: 36px;
    margin-right: 16px;
    }
}

/* .message:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
} */


.message--received {
    background: #F6F6F6;
    border-radius: 0px 5px 5px 5px;
    float: left;
}

.message--received .message__metadata {
    padding: 0 0 0 16px;
}

.message--received:after {
    border-width: 0px 10px 10px 0;
    border-color: transparent #F6F6F6 transparent transparent;
    top: 0;
    left: -10px;
}

.message--sent {
    background: #E9F0F3;
    color:#235F7D;
    border-radius: 5px 0px 5px 5px;
    float: right;
}

.message--sent:after {
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent #E9F0F3;
    top: 0;
    right: -10px;
}

@media (min-width: 320px) and (max-width: 425px) {
    .box-patent-pending{
        font-size: 9px;
    }
}

.new_access_recurring_order {
    background-color: #80BDD5;
    position: relative;
    border-radius: 1rem;
    padding: 5px 8px;
    font-size: 14px;
    color: white;
    width: 56px;
    left: 6px;
}

.menu-configuration {
    padding: 1rem;
    a {
        padding: 0.5rem 1rem;
        font-weight: 400;
    }
}

.v2 {
    .user-box {
        padding: 0 !important;
    }

    .move-btn{
        @media (max-width: 500px) {
            display: none;
        }
    }
    .recurring_profile {
        background-color: white;
        padding: 16px;
        margin-bottom: .5em;

        .input-number.noselect.small {

            display: flex;
            flex-direction: row;
            gap: .2em;
            background-color: white;
            .spin{
                position: inherit !important;
            }
            .spin.minus {
                height: 30px;
                width: 30px;
                padding: 7px 10px;
                border: 0.3px solid #939090;
                background-color: white;
                i {
                    position: relative;
                    top: -5px;
                }
            }
            .spin.plus {
                height: 30px;
                width: 30px;
                padding: 7px 10px;
                background-color: white;
                border: 0.3px solid #939090;
                i{
                    position: relative;
                    top: -5px;
                }
            }
            .input-container {
               width: 30px;
                height: 30px !important;
                background-color: white;

                input {
                    height: 30px !important;
                    background-color: white;

                    width: 30px !important;
                    border: 0.3px solid #939090;
                }
            }
        }

        .desktop-version {
            .line-item {
                .img {
                    min-width: 130px;
                }
            }
        }

    }
    .tracking-status-bar__item__sphere {
        background-color: #365BCA !important;
    }
    .tracking-status-bar__line.tracking-status-bar__line--active  {
        background-color: #365BCA !important;
    }
    .profile-form {
        .bt.noselect.bt-inline.bt-size-small.mr-2 {
            border: 0;
            background-color: #f6f6f6;
        }
        .bt.noselect.bt-inline.bt-size-small {
            border: 0;
            background-color: #f6f6f6;
            border-left: 1px solid #EBEBEB;
        }

        .go-back-profile {
            @media(min-width: $lgMinWidth) {
                display: none;
            }
            padding-bottom: 1.2rem;
        }
    }
    .filters-list-container {
        background: $white;
        padding: 1rem;
        margin-bottom: 1rem;

        .view-more-filters {
            cursor: pointer;

            .fas, .view-more-filters__x {
                padding-right: 0.5rem;
            }

            .view-more-filters__text {
                font-size: 14px;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    
    .box-filters-order {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 1rem;
        overflow-x: auto;

        .search-bar-orders{
            .search-bar--thin::before {
               right: 10px !important;
               top: 6px!important;
            }
            .search-box::placeholder{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 18px;
                position: relative;
            }
            .search-box{
                width: 250px;
            }
            @media (max-width: 1400px) {
                .search-box {
                    width: 100% !important;
                }

            }
            @media (max-width: 575px) {
                .search-box {
                    width: 90% !important;
                }
                .search-bar--thin::before {
                    right: 0px !important;
                }
            }
        }

        .filters-order {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .filters-list{
                overflow: auto;
                white-space: nowrap;
            }
            .input-search-order-desktop {
                align-self: flex-end;
            }
            @media (max-width: 770px) {
                flex-direction: column;
                .show-all-filters{
                   align-self: flex-start;
                   margin: 1em;
                }

            }
            .filter-order {
                padding: 13px 16px 13px 16px;
                display: inline-block;
                color: #9B9B9B;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
            }
            .filter-order-active {
                padding: 13px 16px 0 16px;
                display: inline-block;
                color: #232323;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                border-bottom: 5px solid #DBE4E9;
            }
            .show-all-filters {
                color: $primaryColor;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
            }
            input {
                border: 1px solid black !important;
                border-radius: 0 !important;
            }
        }
        .filter-order:hover {
            background-color: #DBE4E9;
            font-weight: 500;
            color: #232323;
        }
        .Select--border {
            border: 1px solid black !important;
            border-radius: 0 !important;
        }
        .form-control {
            border: 1px solid black !important;
            border-radius: 0 !important;
        }
    }
    }
    .more-filters {
        flex-direction: row;
        justify-content: space-between;

        .filter-by {
            width: 145px;
            z-index: 5;
        }

        .split {
            display: flex;
            flex-direction: row;
            gap: 1em;

            .in-split {
                width: 190px;

                .react-datepicker-wrapper {
                    .customDatePicker {
                        border: 1px solid $primaryColor !important;
                        border-radius: 0 !important;
                    }
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .more-filters {
            flex-direction: column !important;

            .filter-by {
                width: 100%;
            }

            .split {
                width: 100%;
                display: flex;
                flex-direction: row;
                gap: 1em;
                justify-content: space-between;

                .in-split {
                    width: 100%;
                }
            }
        }
    }
    .view-selector {
        .active {
            color: #232323;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            border-bottom: 5px solid #DBE4E9;
        }
        span {
            color: #9B9B9B;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
        }
    }
    .per-delivery-view {
        b {
            font-weight: 600;
        }
    }
    .order-details {
        .product-image {
            margin: 0 !important;
        }
        b {
            font-weight: 600;
        }
    }
}

.user-profile-modal-container-v2 {
    width: 270px !important;
    .nav-item {
        padding: 10px 0 10px 1rem !important;
    }
}

.v2 {
.form-control{
    border-radius: 0;
}
    .filter_date{
        display: flex;
        gap: 1em;
    }

    .title{
        justify-content: space-between;
    }

    @media (max-width: 391px) {
        .inputs{
            //flex-direction: column;
            align-items: start !important;
            button{
                width: 60px !important;
                padding: 0 !important;
                i{
                    top: 2px !important;
                }
            }
        }
    }

    .bottom-btns {
        padding-top: 16px;
        border-top: 1px solid #d9d9d9;

        @media (max-width: 420px) {
            div {
                flex-direction: column;
                a {
                    button{
                        width: 100%;
                    }

                }
                button {
                    height: 40px;
                }
            }
        }
    }
    @media (max-width: 326px) {
        .inputs {
            //flex-direction: column;
            align-items: start !important;

            button {
                width: 32px !important;
                padding: 0 !important;

                i {
                    top: 2px !important;
                }
            }
        }
    }
    @media (max-width: 768px) {

        .title{
            flex-direction: column;
        }
        .profile-form {

            .responsive {
                display: flex !important;

                .inputs{
                    margin-top: 1em;
                    .bt{
                        background-color: #000;
                        height: 30px;
                        width: 97px;
                        padding: 16px 8px;
                        i{
                            color: white;
                            top: -10px;
                        }
                    }
                }
                .recurring_orders_image.product-image-container {
                        .product-image {
                            margin-top: 0 !important;
                            width: 130px !important;
                            height: 120px !important;
                            background-size: cover;
                        }
                    }
                .img {
                    margin-right: 10px;
                    position: relative;
                }

            }
            .desktop{
                .desktop-version{
                    display: none;
                }
            }
        }

    }
    @media (max-width: 1199px) {
        .col-lg-3:has(.profile-menu) {
            display: none;
        }

        .profile-form {
            .row {
                div:first-child {
                    .filter_date{
                        display: flex;
                        flex-direction: row;
                        gap: .5em;

                        div{
                            flex: 1;
                        }
                    }
                }

                div:last-child{
                    //max-width: initial !important;
                    #filter_order{
                       i {
                            top: 20px;
                       }
                    }

                }
            }

            .profile-configuration-screen {
                .form.user-form {
                    @media(max-width: 992px) {
                        .order-notifications-box {
                            margin-top: 1.2rem;
                        }
                    }
                }
            }
        }
    }

    .profile-form {
         margin: 0px !important;

        @media (max-width: 995px) {
            #filter_center {
                width: 100%;
                .center-name-header{
                    img{
                        position: absolute;
                        right: 40px;
                    }
                }
            }

        }

        .profile-form{
            .center-name-header{

                span:first-child{
                        border: 1px solid black;
                        padding: 8px;
                        @media (max-width: 995px) {
                            #filter_center{
                                width: 100%;
                            }
                            display: block;
                        }
                    strong{
                        color: #495057 !important;
                    }
                }
            }
            #filter_order{
                position: relative;
                    top: 12px;
                    div {
                        i {
                            top: 18px !important;
                            right: 10px !important;
                        }
                    }
            }
            @media (max-width: 1399px) {
                .search {
                    width: 100%;
                }
                .header{
                    flex-direction: column-reverse;
                    align-items: flex-start !important;
                    gap: 1em;
                }
            }

            .header{
                @media (max-width: 995px) {
                    flex-direction: column-reverse;
                    .location{
                        div{
                            i {
                                top: 20px !important;
                            }
                        }

                    }
                    .date{
                        width: 100%;
                    }
                    div{
                        .filter_date {

                            justify-content: space-between;
                            div{
                                width: 100%;
                            }

                        }
                    }

                }

            }
        }

        .responsive{
            display: none;
        }

        margin-left: 8px;
        #filter_date_to, #filter_date_from{
            div{
                div{
                    div{
                        input{
                            border-radius: 0px;
                            border: 1px solid #232323;
                        }
                    }
                }
            }
        }
        #filter_order{

            div {
                input{
                    border-radius: 0px !important;
                    border: 1px solid #232323 !important;
                }
                i {
                    position: absolute;
                    top: 40px;
                    right: 30px;
                }
            }
        }

    }
    #section-profile .profile-menu {
        margin: 0;
    }
    .d-flex.flex-column.menu-configuration {
        a.active {
            font-weight: 500;
            border-left: 3px solid #DBE4E9 !important;
        }
    }
    .bt-not-style {
        border: none;
        font-weight: 500 !important;
    }
    .react-datepicker__input-container {
        width: 100%;
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    #section-profile .toolbar .container .row {
        border: none;
    }
    #segmentation-container {
        .Select-control {
            border: 1px solid rgb(236, 236, 236);
            border-radius: 0;
        }
    }
    .box-segmentation {
        border-right: 1px solid #ECECEC;
    }

    .btn-primary-m {
        width: 100%;
        border: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 32px;
        gap: 8px;
        height: 36px;
        white-space: nowrap;
        background: #232323;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #FFFFFF;
        &:disabled {
            background: #D8D8D8;
            color: #FFFFFF;
            border: none;
        }
        &:hover:not([disabled]) {
            background: #DBE4E9;
            color: #232323;
        }
    }

    .btn-secondary-m {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 32px;
        gap: 8px;
        height: 36px;
        white-space: nowrap;
        background: #FFFFFF;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #232323;
        border: 1px solid #232323;
        &:disabled {
            background: #D8D8D8;
            color: #D8D8D8;
            border: none;
        }
        &:hover:not([disabled]) {
            background: #DBE4E9;
            color: #232323;
            border: none;
        }
    }


    .box-favourite-add {
        border: 1px solid black;
        width: 280px;
        margin: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        text-align: center;
        .box-favourite-add-text {
            width: 176px;
            font-family: diagramm;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .box-favourite {
        display: flex;
        border: 1px solid black;
        .box-image-favourite {
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-right: 1px solid black;
            padding: 2rem;
        }
        .box-text-favourite {
            padding: 2rem;
            .title-favourite {
                font-family: diagramm;
                font-weight: 500;
                font-size: 32px;
            }
            .text-favourite {
                font-family: Roboto;
                font-weight: 400;
                font-size: 18px;
            }
            .text-2-favourite {
                font-family: Roboto;
                font-weight: 300;
                font-size: 18px;
            }
            .button-favourite {
                width: 50%;
            }
        }
    }

    .box-button-segmentation {
        display: flex;
        justify-content: end;
    }

    .box-btn-segmentation-clear {
        margin-right: 24px;
    }

    .h1-profile {
        font-family:diagramm;
        font-weight: 600;
        font-size: 20px;
        display: block;
    }

    .h2-profile {
        font-family: Roboto;
        font-weight: 500;
        font-size: 16px;
        display: block;
    }

    .h3-profile {
        font-family: Roboto;
        font-weight: 400;
        font-size: 16px;
        display: block;
    }

    .h4-profile {
        font-family: Roboto;
        font-weight: 300;
        font-size: 12px;
        display: block;
        font-style: italic;
    }

    .btn-link {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 12px;
        color: black;
        cursor: pointer;
    }

    .btn-link:hover {
        text-decoration: underline;
    }

    .txt-modal {
        font-family: Roboto;
        font-weight: 500;
        font-size: 12px;
    }

    .h1-modal {
        font-family: Diagramm;
        font-weight: 400;
        font-size: 16px;
        display: block;
    }

    .txt-profile {
        font-family: Roboto;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        display: block;
    }

    .txt-email-profile {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: block;
    }

    .badge {
        background-color: #DBE4E9;
        color: #232323;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1000px;
        width: fit-content;
        padding: 7px 9px 5px 8px;
    }

    .badge-emails {
        margin: 6px 4px;
        gap: 0.8rem;

        i {
            cursor: pointer;
        }
    }

    .list_requirements__item {
        font-family: Roboto;
        font-style: italic;
        font-weight: 300;
    }

    .list_requirements .list_requirements__item-success {
        font-family: Roboto;
        font-style: italic;
        font-weight: 300;
        color: #36A596;
    }

    .txt-username {
        display: block;
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 11px;
        float: left;
    }

    .box-details-order {
        margin-top: 16px;
        column-gap: 24px;
    }

    .promotions-preferences-form {
        label {
            margin-bottom: 0;
            span {
                font-weight: 500;
                font-size: 14px;
                color: $thunder;
                margin-left: 8px;
            }
        }

        .additional-info-msg {
            font-weight: 400;
            font-size: 14px;
            color: $thunder;
        }
    }

    @media (max-width: 991px) {
        .box-details-order {
            margin-top: auto;
            flex-direction: column;
        }
    }

    @media (max-width: 991px) {
        .tracking-status-bar__item{


            div:nth-child(2){
               display: flex;
               flex-direction: column ;

               i{
                    position:absolute;
                    margin: 0!important;
                    padding: 0!important;
                    left: 55px;
                    top: 30px;

               }
               div{
                    font-size: 12px;
                    margin: 0!important;
                    padding: 0!important;
                    display: flex;
                    flex-direction: column-reverse;
                    position: relative;
                    align-items: center;
                    left: 0px;
                    top: 25px;
                    div{
                        display: unset;
                        position: unset;
                    }
               }

            }
            .tracking-status-bar__item__date {
                /*.tracking-status-bar__item__text {
                        position: relative !important;
                        top: 5.5em;
                        left: 0em;
                    }
                    span{
                        position: relative;
                        top: 2em;
                    }
                    i{
                        position: relative;
                        top: 3em;
                    }*/
            }
        }

        // FIXME Not sure where else is used other than FiltersV2 in Catalog
        //div[data-qa='options'] {
        //    display: grid ;
        //    grid-template-columns: repeat(3, 1fr);
        //    place-items: center;
        //}
        .box-btn-segmentation-clear {
            margin-right: 0;
            margin-top: 24px;
        }
        .box-button-segmentation {
            display: flex;
            flex-direction: column-reverse;
        }
        .box-profile {
            place-content: center;
        }
        .box-profile-menu {
            display: none;
        }
        .box-favourite {
            flex-direction: column-reverse;
            .box-image-favourite {
                border-top: 1px solid black;
                border-right: none;
                align-items: center;
            }
            .btn-primary-m {
                width: 100%;
            }
        }
    }

    #lots-list {
        .Select-control {
            border-radius: 0 !important;
        }
        .Select.has-value.is-searchable.Select--single {
            border-radius: 0 !important;
        }
    }
    .box-btn-input-number {
        height: 80%;
    }

    .customDropdown {
        border-radius: 0;
        background-color: #FFFFFF;
        border: 1px solid #232323;
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        font-family: Roboto;
        .Select-arrow-zone{
            width: auto;
            .Select-arrow:before{
                content: url('../img/icons/chevron-down.svg');
            }
            .Select-arrow{
                line-height: 12px;
                display: table-cell;
                border-style: hidden;
            }
        }
        .Select-control {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: auto;
        }
    }

    .customDatePicker {
        height: 36px;
        .button.react-datepicker__close-icon::after {
            background-color: #232323 !important;
        }
    }
    .customDatePicker + button:after{
        background-color: #232323 !important;
    }

    .customDropdownTitle::before {
        content: attr(aria-label);
        padding-left: 10px;
        display: flex;
        align-items: center;
    }
    .border-right-none {
        border-right: none !important;
    }
    .font-bold {
        font-weight: 500;
    }

    @media screen and (max-width: 991px) and (min-width: 320px) {
        .profile-container-box {
            display: none;
        }
    }
    @media (max-width: 1400px) {
        .input-search-order-desktop {
            display: none;
        }
    }

    .input-search-order-phone {
        padding: 1rem 1rem 0.2rem 1rem;
        background: $white;

        input {
            border: 1px solid $primaryColor !important;
            border-radius: 0;
        }
    }

    @media screen and (min-width: 1399px) {
        .input-search-order-phone {
            display: none;
        }
    }

    .modalNoModification {
        .title {
            font-family: Roboto;
            font-weight: 400;
            font-size: 18px;
        }
        .subtitle {
            font-family: Roboto;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
        }
        .modal-header {
            border-bottom: none;
        }
        .modal-footer {
            border-top: none;
        }
    }

.linkLogout {
    font-weight: 400;
    cursor: pointer;
    border-top: 1px solid #D8D8D8 !important;
}

body{
    overflow-x: hidden;
}


    .hidden-column-v2 {
        display: none;
    }
}

.linkLogout {
    padding: 0.5rem 1rem;
}


#home .image-slide.banner-home .slide-bt .bt:hover, #product-detail .image-slide.banner-home .slide-bt .bt:hover {
    background-color: #cccccc;
}

.pointer {
    cursor: pointer;
}

.text_button {
    font-size: 14px;
    font-weight: 500;
    color: black;
    cursor: pointer;
    &:hover {
        font-weight: 900;
        color: $kunta;
    }
}

.linkMenu {
    text-align: left;
    height: 44px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    &:hover:not(:first-child) {
        cursor: pointer;
        background-color: #dbe4e9;
    }
}


.time-line {
    @media (max-width: 775px) {
        margin-right: -15px;
        margin-left: -15px;
    }
}

.impersonate-btn-v2{
    background: none;
    color: inherit;
    border: 1px solid black;
    padding: 6px 30px;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    min-width: 105px;
}
.impersonate-btn-table{
    display: flex;
    justify-content: center;
}

.countdown{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    border: 1px solid #DBE4E9;
    padding: 0 16px 4px 16px;

    .countdown-time {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0.5em;

        span {
            font-size: 28px;
            font-weight: 600;

            @media (max-width: 768px) {
                font-size: 28px;
            }
        }

        span:last-child {
            font-size: 14px;
        }
    }
}

.mall-preview-slider{
    .mall-promotion-container{
        display: flex;
        justify-content: center;
        margin-left: 1em;
        margin-right: 1em;
        margin-top: 2rem;
    }

    .mall-promo-info {
        .promotion-progress-bar {
            margin: 8px 0;
        }
        .product-info-details {
            padding: 8px 0;
        }

        /* div:first-child {
            width: 100%;
        } */
    }

    .slick-dots{
        bottom: 15px;
    }
}

.mall-promo.landing-mall {
    .product-image-container {
        height: 200px !important;
        .product-image {
            background-size: cover !important;
            height: 200px !important;
        }
    }
    .--placeholder {
        .product-image {
            background-size: contain !important;
        }
    }
}

.mall-promo-card {
    @media (max-width: 771px) {
        flex-direction: column;
        h2 {
            align-self: baseline;
            margin-left: 10px !important;
        }
    }
}
.close-config-modal {
    display: flex;
    align-items: center;
    justify-content: end;
    span {
        cursor: pointer;
        font-size: 1.4rem;
    }
}

.v2{
    @media (max-width: 767px){
        .results-pagination{
            .sk-toggle{
                .sk-toggle-option[data-key='previous'], .sk-toggle-option[data-key='next']{
                    .sk-toggle-option__text{
                        font-size: 0;

                        &:before{
                            font-size: 1rem;
                            height: 34px;
                        }
                    }
                }
            }
        }
    }


    /* .slider-cards{
        height: 530px;
    } */


    /* .products-similar-colors {
        .slider-cards {
            height: 590px;
        }
    } */
    .arrow-cross-left{
        left: -10px !important;
    }
    .arrow-cross-rigth{
        right: -10px !important;
    }
    .product-info-details{
        .product_detail {
            /* .text {
                word-break: break-word !important;
                white-space: normal !important;
                overflow-wrap: break-word !important;
            } */
            .text.price{
                font-family: 'Roboto';
                font-style: normal !important;
                font-weight: 500 !important;
                font-size: 16px;
                line-height: 19px !important;
                /* identical to box height */

                text-align: center !important;

                /* Brand/Primary */

                color: #232323 !important;
            }
        }
    }

}
.filter-mktool-v2{
    .filter--mktoolShippingCenter{
        display: block !important;
    }
}

.tracking-status-bar__item__date,
.tracking-status-bar__item__date__warning {
    div {
        display: flex !important;
        flex-direction: column-reverse !important;

        .tracking-status-bar__item__text {
            all: unset !important;
        }

        span {
            white-space: normal !important;
            overflow-wrap: break-word !important;
            max-width: 80px;
        }

    }
}

.select-shipping{
    .Select-value,
        .Select-menu-outer {

            * {
                text-transform: capitalize !important;
            }

        }
}

.new-mall{
    .rectangle-result-v2 {
        .product-info > div {
            width: 100%;
        }

        .product-info .product-info-details {
            text-align: left;
            justify-content: flex-start !important;

            .finish.price {
                text-align: left;
            }
        }
    }
}

.k-dropdown-wrap.k-state-focused .k-list-container .k-list .k-item {
    background-color: blue !important;
}
.options-mall-select{
    *
    {
        background-color: white !important;
        color: grey !important;
    }

}

.modal-v2{
    #confirm-container {
        border-radius: 0;

        &.confirm{
            header{
                padding: 40px 32px;

                @media (max-width: 768px) {
                    padding: 40px 16px;
                }

                h2{
                    padding-top: 0px;
                }

                .confirm-container-buttons {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;

                    @media (max-width: $smMaxWidth) {
                        flex-direction: column;

                        .text-cancel-btn, .text-ok-btn {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.whatsapp-optin{
    background:white;
}


.dont_show_more:hover{
    text-decoration: underline;
}

.alert-signal {
    color: $alertColor;
    font-size: 1rem;

}

a.brand-new-blue{
    color:$brandNewBlue;
}

.modal-promotion-container {
    position: fixed;
    top: 15%;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;
    max-width: 950px;
    z-index: $layer50;

    @media (max-width: $smMaxWidth) {
        top: 30%;
    }
}

.disclaimer-price-discount {
    width: 100%;
    line-height: 11px;
    text-align: left;

    span {
        color: #20367A;
        font-weight: 300 !important;
        font-size: 10px !important;
        font-family: $infoFont;
    }
}

.something__went__wrong {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;

    img {
        max-width: 80px;

        @media (max-width: $smMaxWidth) {
            max-width: 50px;
        }
    }

    h2 {
        color: $primaryColor;
        font-weight: 500;
        font-size: 32px;
        margin: 0;
        padding: 0;
        font-family: $titleFont;
        text-align: center;

        @media (max-width: $smMaxWidth) {
            font-size: 24px;
        }
    }

    &--text {
        p {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            margin: 0;
            padding: 0;
            color: $primaryColor;
        }

        span {
            text-decoration: underline;
            color: $normalblue;
            cursor: pointer;

            &:hover {
                color: $primaryColor;
            }
        }

        .btn-call-to-action {
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}