@import 'node_modules/react-select/scss/select';

$select-input-bg: $smoke;
$select-input-border-width: 0;
$select-option-focused-bg: $mercury;
$select-item-bg: $smoke;
$select-input-hover-box-shadow: none;

@import 'node_modules/react-select/scss/control';
@import 'node_modules/react-select/scss/menu';
@import 'node_modules/react-select/scss/mixins';
@import 'node_modules/react-select/scss/multi';
@import 'node_modules/react-select/scss/spinner';

.Select {
    cursor: pointer !important;

    &.is-focused {
        background: transparent !important;
    }

    .Select-placeholder {
        background: transparent !important;
        cursor: pointer !important;
        color: $thunder;
    }

    .Select-control {
        background: transparent !important;
    }
    // .Select-menu-outer {
    //     border-left: 1px solid $mercury !important;
    //     border-right: 1px solid $mercury !important;
    //     border-bottom: 1px solid $mercury !important;
    //     border-top: 1px solid $mercury !important;
    // }
    &_center {
        .Select-option {
            display: flex;
            align-items: center;
            span {
                flex-grow: 1;
            }
            i {
                max-width: 20px;
            }
        }
    }
    &.select__shipping_method {
        border: 1px solid $iron;
        .Select-input {
            &:focus {
                background-color: transparent;
            }
        }
    }
    &.select-mktools-attr {
        border: 1px dashed $mercury;
        .Select-value,
        .Select-menu-outer {
            text-transform: capitalize;
        }
    }
    &--sm {
        display: flex;
        align-items: center;
        height: calc(1.5em + 0.5rem + 2px);
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
    }
    &--border {
        border: 4px solid $iron;
        border-radius: 0.2rem;
    }
    &.Select--multi {
        height: auto;
    }
}
