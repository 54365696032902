#modal-segmentation-container {
    position: fixed;
    right: 0;
    left: 0;
    top: 5%;
    margin: auto;
    width: 800px;
    text-align: left;
    max-width: 800px;
    z-index: $layer50;
    max-height: 90vh;
    overflow-y: auto;
    padding: 32px;

    @media (max-width: $mdMaxWidth) {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        max-width: none;
        max-height: none;
        overflow-x: none;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        border-radius: 0;
        padding: 16px;
    }
}

.segmentation {
    background: $white;

    .segmentation-icon {
        background: black;
    }

    header {
        h4 {
            font-size: 22px;
            font-weight: 700;
            font-family: $titleFont;
            color: $primaryColor;
        }

        .bt-close {
            display: inline-block;
            cursor: pointer;

            &:hover {
                color: $onyx;
            }

            i {
                font-size: 24px;
            }
        }
    }

    .segment-favourites {
        padding-bottom: 15px;

        .favourite {
            border: 0;
            padding: 5px 10px;
            background: $iron;
            color: black;
            margin: 5px 0px 5px 7px;
        }

        .active {
            background: black;
            color: white;
            outline: none;
        }
    }

    footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        &>div {
            width: 100%;
        }
    }

    .segmentation__saved-segmentations-container {
        background-color: $backgroundColor;

        h6 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            font-family: $titleFontRegular;
            color: $primaryColor;
        }

        .saved-segmentations {
            .row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 8px 16px;
            }
    
            ul {
                margin-bottom: 0;
            }
    
            div {
                cursor: pointer;
    
            }
    
            span {
                &.active {
                    padding: 0px 5px;
                    border-radius: 5px;
                    background-color: lightblue;
                }
            }
    
            i {
                position: relative;
                right: 0;
                margin-right: 10px;
            }
        }

        .segmentation__saved_dropdown-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px;
            cursor: pointer;
        }
    }

    .sk-item-list {
        display: flex;
        justify-content: space-around;

        .sk-item-list__item {
            display: flex;
            flex-direction: column;
            // justify-content: center;
            align-items: center;
            vertical-align: top;
            cursor: pointer;
            width: 40px;
            height: 40px;
            padding: 2px;
            margin: 10px 7px 35px;

            border-radius: 50%;
            border: 1px solid $mercury;

            background-position: center 5px;
            background-size: 90% auto;
            background-repeat: no-repeat;

            font-family: SilestoneWebSymbols;

            &::before {
                color: $thunder;
                font-size: 65px;
            }

            &[data-key='A0'] {
                background: black url('../img/icons/A0.png');
                background-size: cover;
            }

            &[data-key='D0'] {
                background: black url('../img/icons/D0.png');
                background-size: cover;
            }

            &[data-key='E0'] {
                background: black url('../img/icons/E0.png');
                background-size: cover;
            }

            &[data-key='B0'] {
                background: black url('../img/icons/B0.png');
                background-size: cover;
            }

            &[data-key='C0'] {
                background: black url('../img/icons/C0.png');
                background-size: cover;
            }

            &[data-key='BB'] {
                background: black url('../img/icons/BB.png');
                background-size: cover;
            }

            &[data-key='BD'] {
                background: black url('../img/icons/BD.png');
                background-size: cover;
            }

            &.is-active {
                background-color: $mercury;
                border: 2px solid $thunder;

                .sk-item-list-option__text {
                    font-weight: 300;
                }
            }

            &:hover {
                background-color: $mercury;
            }

            .sk-item-list-option__checkbox {
                display: none;
            }

            .sk-item-list-option__text {
                margin-top: 0.05rem;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 40px;
                width: 100%;
                text-align: center;
                font-family: $infoFont;
                font-weight: 200;
                font-size: 12px;
                line-height: 15px;
            }

            .sk-item-list-option__count {
                display: none;
            }
        }
    }

    .scroll {
        max-height: 60vh;
        overflow-y: auto;
    }

    .segmentation-segment-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $primaryColor;
    }

    .Select-control {
        border: 1px solid $primaryColor;
        border-radius: 0;
    }
    
    //Dont delete this if don't know what you're doing
    #seg-content {
        flex-grow: 1; /* Allow content to grow as needed */
        overflow-y: auto; /* Enable scrolling if content overflows */
    }
}

.v2 {
    .segmentation {
        .segmentation-icon {
            background: #206793;
        }

        .sk-item-list {
            .sk-item-list__item {
                border: none;

                &[data-key='A0'] {
                    background: #e6e6e6 url('../img/icons/A0.png');
                    background-size: cover;
                }

                &[data-key='D0'] {
                    background: #e6e6e6 url('../img/icons/D0.png');
                    background-size: cover;
                }

                &[data-key='E0'] {
                    background: #e6e6e6 url('../img/icons/E0.png');
                    background-size: cover;
                }

                &[data-key='B0'] {
                    background: #e6e6e6 url('../img/icons/B0.png');
                    background-size: cover;
                }

                &[data-key='C0'] {
                    background: #e6e6e6 url('../img/icons/C0.png');
                    background-size: cover;
                }

                &[data-key='BB'] {
                    background: #e6e6e6 url('../img/icons/BB.png');
                    background-size: cover;
                }

                &[data-key='BD'] {
                    background: #e6e6e6 url('../img/icons/BD.png');
                    background-size: cover;
                }
            }

            .sk-item-list__item.is-active {
                background-color: black;
            }
        }
    }
}

.segmentation-v4-box {
    width: auto;
    padding: 8px 16px;
    border-radius: 8px;
    background: #f9f9f9;
    border: 1px solid #f9f9f9;
    color: #232323;
    margin-right: 24px;
    margin-bottom: 16px;
    text-transform: capitalize;
    cursor: pointer;
    font-weight: bold;

    &.is-active {
        border: 1px solid $normalblue;
        color: $normalblue;
        background: white;
    }

    &:hover {
        background: #dbe4e9;
        border: 1px solid #dbe4e9;
    }
}

.saved-segmentation-item {
    border-color: $backgroundColor;
    border-width: 2px;
    border-style: solid;
    padding: 16px;

    .save-segmentation-name {
        line-height: 18px;
        span {
            font-size: 14px;
            font-weight: 700;
            color: $primaryColor;
        }
    }

    .save-segmentation-option {
        line-height: 16px;
        span {
            font-weight: 300;
            font-size: 12px;
            color: $primaryColor;
        }
    }
}

.active-segmentation-v4 {
    background: #DBE4E9;
    border-color:$normalblue;
}

.segmentation-icon2{
    &::before{
        font-size: 20px;
    }
}

.segmentation__evidence {
    .segmentation__evidence__content {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        h5 {

            font-size: 16px;
            font-weight: 500;
            color: $primaryColor;
            margin: 0;
        }

        &--input {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 8px;

            .title {
                color: $primaryColor;
                font-size: 14px;
                font-weight: 400;
            }

            .input-content {
                .input-area {
                    display: flex;
                    gap: 8px;
                    align-items: center;

                    input {
                        max-width: 30%;
                    }

                    .add-another-purchase-btn {
                        color: $normalblue;
                        border: 0;
                    }
                }

                .purchase-orders-list {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-start;
                    column-gap: 10px;

                    .purchase-order-item {
                        margin-top: 16px;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        border-right: 1px solid #E2E4E9;
                        padding: 0 4px;
  
                        span {
                            color: $primaryColor;
                            font-size: 14px;
                            font-weight: 400;
                        }

                        .delete-purchase-btn {
                            border: 0;
                            color: $normalblue;
                            padding: 4px;
                            margin: 0;

                            i {
                                margin: 0;
                                font-size: 13px;
                            }

                            &:hover i {
                                color: rgb(226, 0, 0);
                            }
                        }
                    }
                }
            }
        }

        &--upload {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 8px;

            .title {
                color: $primaryColor;
                font-size: 14px;
                font-weight: 400;
            }

            .custom-upload-button {
                max-width: 18%;

                @media (max-width: $smMaxWidth) {
                    max-width: 30%;
                }
            }

            .uploaded-files-list {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                column-gap: 10px;

                .uploaded-file-item {
                    margin-top: 16px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    border-right: 1px solid #E2E4E9;
                    padding: 0 4px;
                    justify-content: space-between;

                    .file-icon {
                        color: #848484;
                        font-size: 12px;
                    }

                    .file-name {
                       color: $primaryColor;
                       font-size: 14px; 
                       font-weight: 400;
                    }

                    .file-size {
                        color: #5E6578;
                        font-weight: 400;
                        font-size: 13px;
                    }

                    .delete-file-btn {
                        border: 0;
                        color: $normalblue;
                        padding: 4px;
                        margin: 0;

                        i {
                            margin: 0;
                        }

                        &:hover i {
                            color: rgb(226, 0, 0);
                        }
                    }
                }
            }
        }
        
    }

    footer {
        .segmentation-footer-back {
            i {
                color: $primaryColor;
            }
        }
    }
}

