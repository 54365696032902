.catalog_lowes {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 32px;
    &__sidbar {
        padding: 10px;
        // background-color: $smoke;
        width: 100%;
        &.menu {
            display: flex;
            .menu__item {
                flex-grow: 1;
                margin: 10px 25px;
            }
        }
        .title_template {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            sup {
                cursor: pointer;
                i {
                    font-size: 0.75em;
                }
            }
            & > * {
                flex-grow: 1;
                margin: 0 10px;
            }
        }
    }
    &__content {
        margin: 0 auto;
        overflow-x: auto;
        position: relative;
        width: 90vw;
    }
    .Select {
        border: 1px solid $mercury;
    }
    .modal .modal-dialog .modal-content .modal-body {
        overflow-y: visible;
    }
}
.flip_container {
    &--hover:hover {
        .flip {
            transform: rotateY(180deg);
            z-index: 10;
        }
        .flip__back {
            visibility: visible;
        }
    }
    .flip {
        box-shadow: 0px 0px 0px black;
        height: 100%;
        transform-style: preserve-3d;
        transition: 0.8s;
        width: 100%;
    }
    .flip__front,
    .flip__back {
        backface-visibility: hidden;
        position: absolute;
    }
    .flip__back {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        text-align: center;
        transform: rotateY(180deg);
        visibility: hidden;
        width: 100%;
    }
    .flip__front {
        background-size: cover;
    }
    .badge {
        backface-visibility: hidden;
    }
    .react-resizable-handle {
        height: 32px;
        width: 32px;
        z-index: 10;
    }
}
.catalog_lowes__selection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .item {
        margin: 15px;
        width: 200px;
        &__screenshot {
            background-color: $mercury;
            height: 200px;
            position: relative;
            width: 200px;
            .available {
                align-items: center;
                color: $aluminum;
                display: flex;
                font-size: 22px;
                justify-content: center;
                position: absolute;
                right: -11px;
                top: -11px;
            }
            .screenshot {
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                height: 190px;
                margin: 5px;
                width: 190px;
            }
            .add_new {
                align-items: center;
                color: $aluminum;
                display: flex;
                font-size: 75px;
                height: 100%;
                justify-content: center;
            }
        }
    }
}
.catalog_lowes__content {
    h2 {
        text-align: center;
    }
    .grid_container {
        &--editing::before {
            background: url('../img/backgrounds/cuadricula-transp.png');
            background-position-x: 10px;
            background-position-y: 10px;
            background-size: 52.1px 50px;
            border: 1px solid black;
            bottom: 0;
            // background-color: $smoke;
            content: '';
            left: 0;
            opacity: 0.1;
            position: absolute;
            right: 0;
            top: 0;
        }
        .item,
        .react-grid-item {
            align-items: center;
            background-color: lightgrey;
            display: flex;
            height: 100%;
            justify-content: center;
            width: 100%;
            .item {
                &__img {
                    height: 100%;
                    width: 100%;
                }

                &__info {
                    padding: 5px 10px;
                    width: 100%;
                    &__title {
                        overflow: hidden;
                        text-align: center;
                        text-overflow: ellipsis;
                        span {
                            white-space: nowrap;
                        }
                    }
                }
            }
            .badge {
                position: absolute;
                right: -6px;
                top: -6px;
                z-index: 99;
            }
        }
    }
    .grid_container__item {
        &--brochure {
            .item {
                position: relative;
                &__img {
                    width: 100%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &__info {
                    position: absolute;
                    height: 100%;
                }
            }
        }
        &--title {
            .item {
                cursor: unset;
            }
        }
    }
}

.sumary_cart {
    &__lines {
        max-height: 60vh;
        overflow-y: auto;
    }
    &__footer {
        display: flex;
        padding: 5px 10px;
        & > * {
            flex-grow: 1;
            margin: 5px 10px;
        }
    }
}
.line {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    .fit-image {
        margin: 5px;
        img {
            max-height: 30px;
            max-width: 30px;
        }
    }
    .line__btn {
        &--close {
            cursor: pointer;
        }
    }
}
.lowes-container-left {
    display: flex;
    justify-content: left;
    margin: 20px;
    align-items: center;
}
.lowes-container {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    .some-parent-selector & {
        align-items: center;
        display: flex;
    }
    a {
        border-radius: 5px;
        padding: 0 10px;
        &.active {
            background-color: lightgray;
        }
    }
}
.ReactTable.scroll {
    .rt-tbody {
        max-height: 50vh;
        overflow-y: auto;
    }
}
.lowes-step {
    align-items: center;
    background: #555;
    border-radius: 100%;
    color: white;
    display: flex;
    font-size: 24px;
    height: 100px;
    justify-content: center;
    margin-right: 20px;
    min-width: 100px;
    width: 100px;
}
.lowes-button {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    i {
        border-radius: 5px;
    }
}
.lowes-col {
    align-items: center;
    background: transparent;
    border: 2px solid black;
    cursor: pointer;
    display: flex;
    height: 200px;
    justify-content: center;
    margin: 20px;
    width: 50%;
    &:hover {
        background: $mercury;
    }
    a {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
    }
}
.lowes-icon {
    padding: 20px;
}
.lowes-title {
    font-family: $titleFont;
    font-size: 24px;
    font-weight: 600;
}
.item {
    cursor: pointer;
    &--edit {
        cursor: move;
        & > .item__info > div {
            cursor: pointer;
            padding: 5px 20px;
        }
    }
}

.remove,
.edit {
    display: flex;
    height: 32px;
    padding: 5px;
    width: 32px;
}
.remove {
    justify-content: flex-end;
}
.edit {
    align-items: flex-end;
}
.edit_sample {
    &__type {
        display: flex;
        justify-content: space-evenly;
        margin: 10px 0;
        & > div {
            width: 20%;
        }
    }
}
.modal-footer {
    &__buttons {
        display: flex;
        justify-content: flex-end;
        & > * {
            flex-grow: 0.2;
        }
    }
}
.lowes_register {
    .container-login {
        align-items: center;
        display: flex;
        justify-content: center;
        min-height: calc(100vh - 299px);
        .card {
            .group {
                margin-top: 20px;
            }
        }
    }
}
.modal_new_template {
    padding: 10px;
    .group {
        margin: 10px;
        &__title {
            display: flex;
            .bt {
                margin-left: 25px;
            }
        }
        &__duplicate {
            display: flex;
            .Select {
                border-radius: 0.25rem;
                flex-grow: 1;
            }
        }
    }
}
.catalog_info {
    background-color: $smoke;
    margin-bottom: 10px;
    max-height: 1000px;
    overflow: hidden;
    padding: 10px;
    transition: 0.5s ease-in-out;
    &--hidden {
        max-height: 0px;
        padding: 0 10px;
    }
    li {
        font-size: 15px;
        margin-bottom: -2px;
    }
}
.button_info {
    cursor: pointer;
    font-weight: 400;
    color: $primaryColor;
    text-align: left;
    width: fit-content;
    z-index: 1;
    &--left {
        left: -1px;
        margin: 0;
        position: relative;
        right: unset;
        text-align: left;
    }

    @media (max-width: $smMaxWidth) {
        position: relative;
    }
}
.btn_close_lowes_need_help {
    float: right;
    border: none;
    margin-top: -10px;
    margin-right: -10px;
    background-color: unset;
    height: 20px;
    width: 20px;
    line-height: 1;
    cursor: pointer;
}
.group_button_select_all {
    display: flex;
    justify-content: space-evenly;
    div {
        margin-bottom: 15px;
    }
}
.group_buttons {
    display: flex;
    flex-wrap: wrap;
    .search-bar {
        flex-grow: 2;
        margin: 5px 0;
    }
    .group_button_select_all {
        flex-grow: 1;
        margin: 5px 0;
        & > div {
            height: 26px;
            padding-bottom: 0;
            padding-top: 0;
        }
    }
}
.ReactTable.react-table {
    &--lowes-orders {
        .rt-table {
            .rt-thead {
                .rt-tr {
                    .rt-th {
                        border-left: 1px solid rgba(0, 0, 0, 0.05);
                        border-left: 1px solid rgba(0, 0, 0, 0.05);
                    }
                }
            }
            .rt-tr {
                .rt-td:nth-child(2) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
        }
    }
    &--lowes-order-details {
        .rt-table {
            .rt-thead {
                .rt-tr {
                    .rt-th {
                        border-left: 1px solid rgba(0, 0, 0, 0.05);
                        border-left: 1px solid rgba(0, 0, 0, 0.05);
                    }
                }
            }
            .rt-tr {
                .rt-td:nth-child(2) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
        }
    }
}
.lowesStoreData {
    div.row:nth-child(even) {
        background-color: $smoke;
    }
    &-label {
        font-weight: 400;
    }
    &-value {
    }
}
.container {
    &--lowes {
        margin-top: 32px;
    }
}
