#section-products-slider {

    .general-results-list {
        h2 {
            text-align: left;
            margin: 0;
            padding: 1rem 0;
            font-size: 1.25rem;
            font-family: $titleFont;
            color: $primaryColor;

            @media (max-width: 768px) {
                font-size: 1.5rem;
            }
        }
        .slick-slider {

            .slick-dots {
                bottom: -30px;

                li {
                    @media (max-width: 576px) {
                        width: 16px;
                        height: 18px;
                        margin: 0 3px;
                    }
                }
            }

            .slick-track {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: stretch;
    
                .slick-slide {
                    height: auto !important;
                    padding: 0 8px;
                    box-sizing: border-box;
                    
                    @media (min-width: 768px) {
                        max-width: 330px;
                    }
                    
                    .slider-cards {
                        height: 100%;
    
                        .rectangle-result-v2 {
                            height: 100%;
                        }
                    }
                }
                .slick-slide > div {
                    height: 100% !important;
                }
            }
        }
    }

    .product-actions {
        flex-direction: column;
        .input-row {
            width: 100%;

            .input {
                width: 100%;

                .input-number {
                    .spin {
                        width: 100%;
                    }
    
                    .input-container {
                        width: 100%;
                    }
                }
            }
        }
    }
}
