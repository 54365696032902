/*
 * Specific styles of product-detail component
 */

.product-detail-card {
    position: relative;
    .btn-group {
        display: inline-block;
        a,
        span {
            button {
                cursor: pointer;
                background-color: $smoke;
                border: $smoke;
                color: $onyx;
                border-radius: 0;
                margin: 5px;
                &.btn-primary {
                    background-color: $onyx;
                    border: $onyx;
                    color: $smoke;
                }
            }
        }
    }

    .group_tag {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 2px;
        padding-bottom: 16px;

        &--left-side {
            display: flex;
            align-items: center;
            gap: 2px;
        }
    }

    /* .outlet-indicator {
        font-family: $infoFont;
        font-size: 0.75rem;
        font-weight: 500;
        text-transform: uppercase;
        padding: 2px 5px;
        border-radius: 3px;
        background: $outletIndicatorBg;
        color: $white;
        z-index: 1;
    } */

    /* .discontinued-indicator {
        font-family: $infoFont;
        font-size: 0.75rem;
        font-weight: 500;
        text-transform: uppercase;
        padding: 2px 5px;
        border-radius: 3px;
        background: $discontinuedIndicatorBg;
        color: $white;
        z-index: 1;
        margin-bottom: 2px;
    } */

    /* .promo-indicator {
        font-family: $infoFont;
        font-size: 0.75rem;
        font-weight: 500;
        text-transform: uppercase;
        padding: 2px 5px;
        border-radius: 3px;
        background: $promoIndicatorBg;
        color: $white;
        text-transform: uppercase;
        z-index: 1;
    } */

    .filters {
        margin-top: 1rem;
        .label {
            font-family: 'diagramm';
            font-weight: 600;
        }
    }
}

.product-detail {
    &[data-type='TABLA'] {
        .product-image {
            .fit-image {
                width: 500px;
                max-width: 100%;

                //img {
                //    width: -webkit-fill-available;
                //}
            }
        }
    }
    .outlet {
        margin-bottom: 15px;
        border-radius: $headingBorderRadius;
        width: fit-content;
    }

    .price {
        color: $primaryColor;
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        text-align: left;

        del {
            color: $secondNeutralText;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
        }
    }

    .product-description {
        background-color: $white;
        padding: 16px;

        .product-serie {
            font-weight: 500;
            color: $gray;
            font-family: $infoFont;
            text-transform: capitalize;
        }

        .product-description-p {
            font-weight: 200;
            color: $gray;
            font-family: $infoFont;
        }

        .product-brand {
            text-transform: capitalize;
        }
    }

    .row-others {
        padding-bottom: 16px;

        .lots {
            border: 1px solid black;
            padding: 10px 20px;
            text-align: center;
            border-radius: $headingBorderRadius;

            i {
                margin-right: 10px;
            }

            p {
                margin: 0;
                padding: 0;
            }
        }

        .finished-title {
            font-family: $infoFont;
            color: $secondNeutralText;
            font-weight: 500;
            line-height: 20px;
            font-size: 14px;
            padding-bottom: 8px;
        }

        .finished-subtitle {
            font-family: $infoFont;
            color: $secondNeutralText;
            font-weight: 400;
            line-height: 20px;
            font-size: 14px;
        }

        p {
            margin: 0;
            padding: 0;
        }

        .available-finished {
            color: $gray;
            font-family: $infoFont;
        }
    }

    .row-price {
        .stock {
            font-weight: 400;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 16px;
            .length {
                color: #a1a1a1;
                font-size: 10px;
            }
        }
        label.quantity {
            font-family: $titleFont;
        }

        .form-control:focus {
            border-color: rgb(60, 60, 60);
            outline: 0;
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(60, 60, 60);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(60, 60, 60);
        }
    }
    .product-image {
        margin-bottom: 24px;
        & > .fit-image {
            &--no-image {
                background-color: $smoke;
                background-image: url('../img/icons/no-image.svg');
                background-position: center;
                background-repeat: no-repeat;
                border: 1px dashed $iron;
            }
            min-width: 500px;
        }
        .fit-image {
            position: relative;
            min-width: 50px;
        }

        .wrapper_technology_indicator {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 16px;

            @media (max-width: $smMaxWidth) {
                padding: 8px;
            }
        }
    }
    
    @media (min-width: $smMaxWidth) {
        .product-image {
            .fit-image {
                img {
                    max-height: 550px;
                }

                &.loading {
                    height: 300px;

                    img {
                        height: 300px;
                    }
                }
            }
        }
    }

    .bt-download {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        cursor: pointer;
        padding-bottom: 16px;
        text-align: left;

        .icon {
            img {
                width: 100%;
                height: 100%;
                max-width: 28px;
            }
        }

        .label {
            color: $secondNeutralText;
            font-size: 12px;
            font-weight: 400;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    #section-catalog {
        text-align: center;

        h1 {
            text-align: left;
            margin: 0px 20px;
        }

        .results {
            width: 100%;
        }

        .results-list {
            position: relative;
            text-align: center !important;

            .arrows {
                position: absolute;
                top: 50px;
                font-weight: 100;

                &:first-child {
                    left: 20px;
                }

                &:not(:first-child) {
                    right: 20px;
                }
            }
        }
    }
    .suggests {
        text-align: center;

        .suggests-title {
            font-family: $titleFont;
        }

        button {
            border: 1px solid black;
            border-radius: 20px;
        }

        div {
            text-align: center;
        }

        div:nth-child(4) {
            margin-top: 20px;
        }

        .sale {
            border: 1px solid black;
            padding: 10px 20px;
            text-align: center;
            border-radius: $headingBorderRadius;
            width: 50%;
            margin: 0 auto;

            p {
                margin: 0;
                padding: 0;
            }
        }
    }

    .product-detail-stock {
        text-align: left;
        .product_detail.stock {
            .text {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
            }

            .length {
                color: $secondNeutralText;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    .product-detail-canbuy {
        padding-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        @media (max-width: $smMaxWidth) {
            flex-direction: column;
        }

        &--input {
            flex: 30%;

            @media (max-width: $smMaxWidth) {
                flex-direction: column;
                width: 100%;
                flex: unset;
            }
        }

        &--btn {
            flex: 70%;

            @media (max-width: $smMaxWidth) {
                flex-direction: column;
                width: 100%;
                flex: unset;
            }
        }
    }
}

.product-images {
    margin: 0px;
    text-align: center;

    .image {
        padding: 0;

        .img-background {
            height: 250px;
            margin: 0 1px 1px;
            overflow: hidden;
            div {
                height: 250px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }
        }
    }
}

.image-carousel {
    .carousel_wrapper {
        overflow: hidden;
        position: relative;
        max-width: 601px;
        .button {
            width: 35px;
            height: 100px;
            position: absolute;
            top: 0;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            &__back {
                height: 100px;
                background: white;
                background: linear-gradient(90deg, white, transparent);
            }
            &__next {
                right: 0px;
                background: white;
                background: linear-gradient(90deg, transparent, white);
            }
        }
        .carousel {
            width: 600px;
            margin: 0 auto;
            transition: 1s;
            &__item {
                display: inline-block;
                background-size: cover;
                background-position: center;
            }
        }
    }
}
#wrapper-full.v2 {
    #product-detail {
        background-color: $backgroundV2;
        padding-top: 2rem;
        @media (max-width: 991px) {
            padding-top: 1rem;
        }
        .results-list {
            display: flex;
            flex-wrap: wrap;
        }
    }
    .product-description-desktop {
        position: sticky;
        height: fit-content;
        top: 10.5rem;

        @media screen and (max-width: 1023px) {
            position: unset;
        }
    }
}

.product-description {
    .product-brand {
        text-transform: capitalize;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $secondNeutralText;
    }

    .product-name-content {
        padding: 4px 0;
    }

    .product-title {
        text-align: left;
        font-size: 28px;
        font-weight: 500;
        font-family: $titleFontNormal;
        line-height: 32px;
        text-transform: capitalize;
        color: $primaryColor;
        word-wrap: break-word;
        margin: 0;
    }

    .productid-info {
        color: $secondNeutralText;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
    }

    .stock-info-product {
        font-weight: 500;
        font-size: 0.875rem;
    }

    .stock-m2-info {
        font-size: 0.875rem;
        font-weight: 400;
        color: $secondNeutralText;
    }

    .Select {
        .Select-control {
            .Select-value-label {
                color: $primaryGray !important;
                font-size: 0.875rem;
                font-weight: 400;
            }

            .Select-arrow-zone {
                .Select-arrow {
                    border-color: $primaryColor transparent transparent;
                }
            }
        }
        .Select-menu-outer {
            font-size: 0.875rem !important;
            font-weight: 400 !important;
        }
    }

    .Select.is-open .Select-arrow,
    .Select .Select-arrow-zone:hover {
        border-top-color: $primaryColor;
    }

    .Select.is-open > .Select-control .Select-arrow {
        border-color: transparent transparent $primaryColor;
    }

    .form-group {
        .finish.price.v2 {
            font-weight: 600;
            font-size: 1.5rem;
            @media (max-width: 768px) {
                font-size: 1.3rem;
            }
        }
    }

    .price-info-display-price {
        font-size: 1rem;
        font-weight: 400;
    }

    .product-info-details {
        text-align: left;
        .product_detail.pn-origin {
            .text {
                color: $secondNeutralText;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                text-transform: capitalize;
            }
        }
    }

    .product-detail-filter-product {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        padding: 16px 0;


        @media (max-width: $smMaxWidth) {
            grid-template-columns: auto;            
        }
    }
}

.fit-image--no-image {
    height: 300px;
    border: 1px dashed rgb(204, 204, 204);
    margin-bottom: 24px;
    @media (max-width: 767px) {
        height: 200px;
    }
}

.select-heading-product {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $secondNeutralText;
    display: block;
    padding-bottom: 8px;
    text-align: left;
}
@media (max-width: 767px) {
    .product-detail {
        .product-image {
            img {
                max-height: 290px;
            }
        }
    }
}

.product-description {
    background-color: $white;
    padding: 1rem;

    .input-number.v2 {
        max-width: 100%;
        color: $primaryColor;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;

        @media (max-width: $mdMaxWidth) {
            justify-content: flex-start;
        }

        .spin {
            position: relative;
            width: 43.2px;
            height: 40.5px;
            font-size: 1.185rem;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: $smMaxWidth) {
                width: 100%;
            }
        }

        .input-container {
            width: 43.2px;
            height: 40.5px;
            
            @media (max-width: $smMaxWidth) {
                width: 100%;
            }

            input {
                height: 40.5px !important;
                font-size: 1.185rem;
                font-weight: 400;
                background-color: $white;
                border: 1px solid $primaryColor;

                &:hover {
                    background-color: #dbe4e9;
                }
            }
        }
    }
}

.product-image {
    & > .fit-image {
        background-color: $white;
    }
}

@media (min-width: $smMaxWidth) {
    .product-image {
        .fit-image {
            &.loading {
                height: auto;

                img {
                    height: auto;
                }
            }
        }
    }
}

.product-description-phone {
    display: none;
}

.product-description-desktop {
    display: block;
    margin-bottom: 3rem;
}

#box {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

@media (max-width: 1023px) {
    .product-description-phone {
        display: block;
    }

    .product-description-desktop {
        display: none;
    }

    #box {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.box-patent-pending {
    font-size: 12px;
    color: $secondNeutralText;
    font-weight: 400;
}