.search-bar {
    position: relative;
    padding-bottom: 20px;

    input[type=text] {
        background-color: $white;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 512 512'%3E%3Cpath fill='%23232323' d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 98% 50%;
    }

    .search-box {
        width: 100%;
        border: 1px solid $primaryColor;
        border-radius: 0;
        outline: none;
        &::placeholder {
            text-transform: capitalize;
            font-size: 14px;
        }
    }
    &--thin {
        padding: 0;
        &:before {
            font-size: 14px;
            top: 3px;
        }
    }
    .order_filters & {
        .search-box {
            border-color: $iron;
        }
        padding: 0;

        &:before {
            font-size: 14px;
            right: 12px;
            top: 5px;
        }
    }
}

//New versión. Component SearchInput.
.search__input__bar {
    border-radius: 0;
    font-size: 14px;
    border-color: $smoothGray;
    background-color: $white;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 512 512'%3E%3Cpath fill='%23232323' d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 97% 50%;

    @media (max-width: $mdMaxWidth) {
        background-position: 98% 50%;
    }
}
