html .alert-box{
  position: relative;
  border-radius: 5px;

  &.bg-danger{
    background-color: #FBDFDC !important;
  }

  & .alert-icon{
    position: absolute;
    display: flex;
    left: 8px;
    top: 8px;
  }

  & .alert-text{
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    padding: 8px 32px;
    color: #323232;
  }

  & .alert-close{
    position: absolute;
    display: flex;
    right: 10px;
    top: 6px;

    i{
      font-size: 20px;
      font-weight: 400;
    }
  }
}

html .v2 .alert-box{
  border-radius: 0;
}

html[dir=rtl] .alert-box{

  & .alert-icon{
    right: 8px;
    left: unset;
  }

  & .alert-close{
    left: 10px;
    right: unset;
  }
}
