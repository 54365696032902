.preorder__cts__filter {
    padding: 1rem;
    background-color: $white;

    .preorder__cts__filter--inputs {
        display: flex;
        align-items: center;
        padding-bottom: 1rem;
        gap: 0.7rem;

        .preorder__cts__filter--column1 {
            gap: 0.8rem;
            
            .preorder__cts--project {
                width: 100%;
            }
        
            .preorder__cts--datefrom {
                width: 100%;
        
                input {
                    border: 1px solid $primaryColor !important;
                    border-radius: 0!important;
                }
            }

            @media (min-width: 767px) {
                max-width: 49%;
            }

            @media (max-width: 767px) {
                flex-direction: row-reverse;
            }
        }

        .preorder__cts__filter--column2 {
            gap: 0.8rem;

            .preorder__cts--dateto {
                width: 100%;
        
                input {
                    border: 1px solid $primaryColor !important;
                    border-radius: 0!important;
                }
            }
        
            .preorder__cts--status {
                width: 100%;
            }

            @media (min-width: 767px) {
                max-width: 49%;
            }
        }

        @media (max-width: 991px) {
            padding-top: 1rem;
        }

        @media (max-width: 767px) {
            flex-direction: column;
        }

    }
    
    .preorder__cts--search {
        position: relative;
        max-width: 273px;

        input {
            border: 1px solid $primaryColor !important;
            border-radius: 0!important;

            &::placeholder {
                font-size: 14px;
            }
        }

        .fa-search {
            position: absolute;
            box-sizing:border-box;
            top: 30%;
            right: 1rem;
            color: $primaryColor;
        }

        @media (max-width: 991px) {
            width: 100%;
        }
    }
}

.preorder__history {
    background-color: $white;
    padding: 1rem;
    margin-top: 1rem;

    .preorder__history--header {
        padding: 0.6rem 0;
        margin-bottom: 4.2rem;
        border-bottom: 1px solid #EBEBEB;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .preorder__history--header--status {
            h4 {
                color: $primaryColor;
                font-size: 16px;
                font-weight: 600;
            }
        }

        .preorder__history--header-id {
            font-size: 12px;
            font-weight: 400;
            color: $primaryColor;
        }

        @media(max-width: 767px) {
            margin-bottom: 1rem;
            gap: 5px;
        }
    }

    .preorder__history--content {
        padding: 0 1rem;
        .preorder__history--content--summary {
            border-right: 1px solid #EBEBEB;

            h5 {
                font-size: 14px;
                font-weight: 600;
                color: $primaryColor;
                margin: 0;
            }

            .summary__project--title {
                font-weight: 300;
                font-size: 12px;
                color: $primaryColor;
            }
            .summary__project--name {
                font-weight: 500;
                font-size: 12px;
                color: $primaryColor;
            }

            @media(max-width: 767px) {
                border: 0;
                margin-bottom: 2.5rem;
            }
        }

        .preorder__history--content-timeline {
            
            .timeline--lastupdate {
                font-size: 14px;
                font-weight: 500;
                color: $primaryColor;
                text-align: left;
            }

            @media (min-width: 992px) {
                .wrapper__tracking-status-bar.preview .tracking-status-bar {
                    margin: 3em 1em;
                }
            }
            
            @media (max-width: 991px) {

                .tracking-status-bar__item__date {
                    margin-bottom: 0 !important;
                }
                
                .wrapper__tracking-status-bar.preview .tracking-status-bar {
                    font-size: 12px;
                }
            }

            @media (max-width:576px) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .preorder__history--content-description {
            border-left: 1px solid #EBEBEB;
            padding-right: 0;

            h5 {
                font-size: 14px;
                font-weight: 600;
                color: $primaryColor;
                margin: 0;
            }

            span {
                font-size: 12px;
                font-weight: 300;
                color: $primaryColor;
            }

            .preorder__history--vieworder {
                font-size: 12px;
                font-weight: 700;
                color: $normalblue;
                text-decoration: underline;
            }

            .preorder__history--btn-container {
                margin-top: 1rem;
                
                .preorder__history--viewdetails {
                    font-size: 12px !important;
                    font-weight: 500 !important;
                    color: $white !important;
                    font-family: $infoFont !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 14px;
                }
            }

            .preorder__history--cancel {
                font-size: 14px;
                font-weight: 500;
                color: $primaryColor;
                cursor: pointer;
                margin-top: 1rem;

                &:hover {
                    text-decoration: underline;
                }
            }

            @media(max-width:991px) {
                padding-top: 0.8rem;
                margin-top: 0.6rem;
                border-top: 1px solid #EBEBEB;
                border-left: 0;
                padding-right: 15px;

                .preorder-unread-msg-icon {
                    top: 6px !important;
                    right: 0 !important;
                }
            }

            @media (max-width: 767px) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

// Preorder Details

.preorder-details-view {

    .preoder-details-timeline {
        .wrapper__tracking-status-bar.preview .tracking-status-bar {
            margin-bottom: 2rem;
        }

        @media(max-width: 768px) {
            .tracking-status-bar__item div:nth-child(2) div {
                left: 10px !important;
            }
        }
    }

    .preorder-details-chat-panel {
        background-color: $white;
        padding: 2rem 1rem 1rem 1rem;

        .preorder-details-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #EBEBEB;
            padding-bottom: 0.4rem;

            .preorder-details-title {
                color: $primaryColor;

                h5 {
                    font-size: 22px;
                    font-weight: 600;
                }
            }
  
            @media (max-width: 768px) {
                flex-direction: column;
                align-items: baseline;
            }
        }

        .preorder-details-description {
            padding: 1rem 0 0.8rem 0;
            color: $primaryColor;
            border-bottom: 1px solid #EBEBEB;

            h3 {
                font-size: 16px;
                font-weight: 600;
            }

            span {
                font-size: 12px;
                font-weight: 300;
            }
        }

        .preoder-details-messages {
            padding: 1rem 0 0.8rem 0;
            display: flex;
            align-items: center;
            gap: 8px;
            h3 {
                font-size: 16px;
                font-weight: 600;
                color: $primaryColor;
                margin: 0;
            }

            span {
                height: 22px;
                width: 22px;
                font-size: 12px;
                font-weight: 400;
                background-color: $normalblue;
                color: $white;
                border-radius: 100%;
                text-align: center;
                line-height: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .preorder-communications-panel {
            max-height: 500px;
            overflow: auto;

            .same-date-container {
                margin-left: 0;
                margin-right: 0;

                .preorder-left-line {
                    flex-grow: 1;
                    height: 1px;
                    border-bottom: 1px solid #EBEBEB;
                }

                .preorder-current-date {
                    text-align: center;
                    color: #9B9B9B;
                    font-size: 14px;
                }

                .preorder-right-line {
                    flex-grow: 1;
                    height: 1px;
                    border-bottom: 1px solid #EBEBEB;
                }
            }

            .message--received {
                border-radius: 0;
            }

            .message--sent {
                border-radius: 0;
            }

            .message {
                color: $primaryColor;
                padding: 12px 16px;
                margin: 8px 10px 8px;
            }

            .preorder-communications-fromcosentino {

                .fromcosentino-header {
                    display: flex;
                    align-items: center;
                    gap: 0.8rem;

                    img {
                        margin: 0;
                        height: 32px;
                        width: 32px;
                    }
                }

                .cosentino-name {
                    font-size: 14px;
                    color: $primaryColor;
                    font-weight: 700;
                }

                .cosentino-message {
                    font-size: 14px;
                    font-weight: 300;
                    color: $primaryColor;
                    padding: 0.4rem 0;

                }
            }

            .preorder-communications-files {
                display: flex;
                flex-wrap: wrap;
                row-gap: 8px;
                column-gap: 1rem;

                .communications-files-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-evenly;

                    .fal.fa-file {
                        font-size: 32px;
                        position: relative;
                        color: #4A4A4A;
                    }

                    .communications-files-type {
                        font-size: 12px;
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        top: 10%;
                        width: 100%;
                        height: 100%;
                    }

                    .file-name-date {
                        margin: 0 10px;

                        span {
                            font-size: 14px;
                            font-weight: 500;
                            color: $primaryColor;
                        }

                        div {
                            font-size: 12px;
                            font-weight: 400;
                            color: #9B9B9B;
                        }
                    }

                    .download-btn-files {
                        width: 32px;
                        color: #9B9B9B;
                        height: 32px;
                        border: 1px solid;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        .far.fa-arrow-to-bottom {
                            font-size: 1rem;
                        }
                    }
                }
            }

            .messages-suggestions-buttons {
                padding: 0.8rem 0;
                display: flex;
                column-gap: 1rem;
                clear: both;
                font-size: 14px;
                font-weight: 500;
                justify-content: end;
            }

            @media (max-width: 767px) {
                .cosentino-message {
                    flex-direction: column;
                }

                .message {
                    padding: 4px 8px;
                    margin: 4px 4px 6px;
                    max-width: 100%;
                }
            }
        }

        .preorder-communications-send {

            .preorder-send-input {
                display: flex;
                align-items: center;
                margin-top: 2rem;
                column-gap: 1rem;

                .send-input-container {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 2em;
                    border-radius: 0;
                }
            }
        }

        @media (max-width: 767px) {
            padding: 1rem;
        }
    }

    @media (max-width: 767px) {
        .preorder-sidebar-right, .preorder-chat-left {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .preorder-summary-card {
        background-color: $white;
        padding: 2rem 1rem 1rem 1rem;
        margin-bottom: 24px;

        .preorder-summary-title {
            border-bottom: 1px solid #EBEBEB;
            padding-bottom: 0.4rem;
            h5 {
                font-size: 22px;
                font-weight: 600;
                color: $primaryColor;
            }
        }

        .preorder-summary-container {
            padding-top: 1rem;

            .preorder-summary-details {
                color: $primaryColor;
                font-size: 14px;

                span {
                    font-weight: 400;
                }

                strong {
                    font-weight: 700;
                }

                li {
                    strong {
                        span {
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        @media (max-width: 767px) {
            padding: 1rem;
        }

        @media (max-width: 991px) {
            margin-top: 24px;
        }
    }

    .preorder-docs-attachments {
        background-color: $white;
        padding: 2rem 1rem 1rem 1rem;

        .preorder-attach-title {
            border-bottom: 1px solid #EBEBEB;
            padding-bottom: 0.4rem;

            h5 {
                font-size: 22px;
                font-weight: 600;
                color: $primaryColor;
            }
        }

        .preorder-attach-files {
            max-height: 320px;
            overflow: auto;

            .preorder-attach-container {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                overflow: hidden;
                margin-top: 0.6rem;

                .preorder-attach-content {
                    display: flex;
                    column-gap: 10px;
                    margin-top: 0.5rem;

                    .fal.fa-file {
                        font-size: 46px;
                        position: relative;
                        height: 100%;
                        color: #4A4A4A;

                        .attach-icon {
                            font-size: 16px;
                            position: absolute;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            top: 10%;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .attach-file-name-container {
                        display: flex;
                        justify-content: space-between;
                        align-items: baseline;
                        overflow: auto;
                        width: 100%;

                        .file-name {
                            overflow: hidden;
                            white-space: nowrap;

                            .file-name-content {
                                color: #9B9B9B;
                                font-size: 12px;
                                font-weight: 400;
                            }

                            strong {
                                color: $primaryColor;
                                font-weight: 500;
                                font-size: 14px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }

                    .fal.fa-download {
                        font-size: 20px;
                        margin: 2px 8px;
                        cursor: pointer;
                        color: $primaryColor;
                    }
                }
            }
        }

        @media (max-width: 767px) {
            padding: 1rem;
        }
    }

    @media (max-width: 991px) {
        .header-goback {
            .go-back {
                display: none;
            }
        }
    }
}

// V1 style

@media (max-width: 991px) {
    .preorder-mobile-space {
        margin-top: 24px;
    }
}