//START LANDING_ONE

//FINISH LANDING_ONE

//START PRODUCT_LANDING_ONE
.product_landing_one {
    padding: 1em;
    box-shadow: -5px 5px 5px $smoke;

    .wrapper-product-image {
        height: 100%;

        .product-image {
            background-position: top;

            &[type='TABLA'] {
                background-size: cover;
            }
        }
    }

   
    .product-info {
        flex-grow: 1;
        text-align: center;
        display: flex;
        flex-direction: column;

        & > div {
            margin: auto;
        }

        .product-brand {
            color: $gray;
            font-size: 11px;
            font-weight: 200;
            margin-top: 10px;
            text-transform: capitalize;
        }

        .product-color-name {
            color: $thunder;
            font-family: $titleFont;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;

            hyphens: auto;
            line-height: 20px;
            text-transform: capitalize;
            -ms-word-break: break-all;

            word-break: break-all;

            word-break: break-word;
            flex-grow: 1;
        }

        .product-info-table {
            border-bottom: 1px solid $mercury;
            display: inline-block;
            padding: 10px 0 5px;
            width: 80%;

            table {
                width: 100%;

                thead {
                    tr {
                        th {
                            color: $gray;
                            font-size: 12px;
                            font-weight: 200;

                            &:not(:last-child) {
                                border-right: 1px solid $smoke;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            color: $thunder;
                            font-size: 12px;
                            font-weight: 300;
                        }
                    }
                }
            }
        }

        .product-info-details {
            .thickness {
                display: inline-block;

                .icon {
                    border-left: 0.5px solid $onyx;
                    border-right: 0.5px solid $onyx;
                    display: inline-block;
                    height: 10px;
                    line-height: 10px;
                    width: 15px;

                    div {
                        background: $onyx;
                        display: inline-block;
                        vertical-align: middle;
                        width: 100%;
                    }
                }

                .text {
                    display: inline-block;
                    font-size: 12px;
                    margin-left: 5px;

                    [dir='rtl'] & {
                        margin-left: unset;
                        margin-right: 5px;
                    }
                }
            }

            .finish {
                display: inline-block;
                margin-left: 4px;
                margin-right: 4px;

                .icon {
                    display: inline-block;
                    vertical-align: middle;

                    img {
                        height: 15px;
                    }
                }

                .text {
                    display: inline-block;
                    font-size: 12px;
                    margin-left: 2.5px;
                    margin-right: 2.5px;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                    white-space: nowrap;
                    text-transform: capitalize;
                }
            }

            .subtypeName {
                display: inline-block;
                margin-left: 4px;
                margin-right: 4px;
                .text {
                    display: inline-block;
                    font-size: 12px;
                    margin-left: 2.5px;
                    margin-right: 2.5px;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                    white-space: nowrap;
                    text-transform: initial !important;
                }
            }

            .undefined {
                display: inline-block;
                margin-left: 4px;
                margin-right: 4px;
                .text {
                    display: inline-block;
                    font-size: 12px;
                    margin-left: 2.5px;
                    margin-right: 2.5px;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                    white-space: nowrap;
                    text-transform: initial !important;
                }
            }

            .format {
                display: inline-block;
                margin-left: 4px;
                margin-right: 4px;
                .text {
                    display: inline-block;
                    font-size: 12px;
                    margin-left: 2.5px;
                    margin-right: 2.5px;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                    white-space: nowrap;
                    text-transform: initial !important;
                }
            }

            .product_detail {
                display: inline-block;
                margin-left: 4px;
                margin-right: 4px;
                white-space: normal;

                .icon {
                    display: inline-block;
                    vertical-align: middle;

                    img {
                        height: 15px;
                    }
                }
            }

            .price {
                width: 100%;

                span {
                    font-weight: 500;
                    font-size: 1.2em;
                }
            }
        }
    }

    
    .product-actions {
        display: flex;
        margin-top: 10px;
        align-items: center;
        gap: 16px;

        @media (max-width: $mdMaxWidth) {
            flex-direction: column;
        }

        .input-row {
            flex-grow: 1;
            max-width: 50%;


            @media (max-width: $mdMaxWidth) {
                max-width: 100%;
            }

            .icon {
                display: inline-block;

                img {
                    height: 15px;
                }
            }

            .input {
                .input-number {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .bt-row {
            flex-grow: 1;
            max-width: 50%;

            @media (max-width: $mdMaxWidth) {
                max-width: 100%;
            }
        }
    }

    .indicators {
        max-width: 100%;
    }

    .disclaimer-price-discount {
        text-align: center;
    }

}
.catalog-background-color {
    background-color: $backgroundV2;
}

.products-promotion-landing-two {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px 24px;

    @media (max-width: 1398px) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 5px 12px;
    }
    
    @media (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5px 10px;
    }
    @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 5px 0;
    }
}
