/*
 * Specific styles of signin component
 */

/*
 * General styles
 */

#modal-impersonate-container:not(.v2) {
    position: absolute;
    top: 56px;
    right: 0px;
    width: 600px;
    text-align: left;
    max-width: 600px;
    z-index: $layer30;
    border-radius: 5px;
    max-height: 80vh;
    overflow-y: auto;
    @media (max-width: $mdMaxWidth) {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100% !important;
        max-width: none;
        max-height: none;
        overflow-x: none;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        border-radius: 0;
    }
    
}
.container-impersonate{
    
    position: absolute;
}
.search-bar-download{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    .download{
        flex: 0 0 auto;
        width: 10vw;
        margin-right: 25px;
        div{
            width: 100%;
        }
        
    }
}
.search-bar-v2{       
    position: relative;
    padding-bottom: 20px;       
    flex: 2 1 auto;    
    width: 40vh;
    @media (max-width: 991px) {
        width: auto;
        
    }
    &::before {
        content: '\F002';
        font-family: 'Font Awesome 5 Pro';
        right: 12px;
        position: absolute;
        top: 6px;
        
    }           
    .search-box-v2{
        width: 100%;
        border: 1px solid $primaryColor;       
        outline: none;
        text-indent: 8px;
        &::placeholder {
            text-transform: capitalize;
            font-size: 12px;
        }
    }
     
}
.modal-impersonate.v2{
    position: fixed ;
    top: 200px;
    left: 50%;
    width: 720px;
    
    margin-right: -50%;
    transform: translate(-50%, 3%);
    overflow-y: auto;
    max-height: 80vh;
    z-index: $layer30;
    @media (max-width: $mdMaxWidth) {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100% !important;
        max-width: none;
        max-height: none;
        overflow-x: none;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        border-radius: 0;
        transform: none;
    }
    .row{
        justify-content: center;
    }
    .col-12{
        max-width: 95%;
        @media (max-width: 580px) {
            max-width: 100%;
        }
    }
    .bt-gentle{
        background-color: white;
        color: black;
        padding: .5em;
    }
    .rt-tr{
        text-align: left;
    }
    .-pagination{
        justify-content: space-between;
        margin-top: 2em;
        margin-bottom: 3em;
        
        
        .-center {
            order: 1;
            display: flex;
            flex-direction: row-reverse;
    
        }
    
        .-pageInfo {
            position: absolute;
            right: 150px;
        }
    
        .-pageSizeOptions {
            position: absolute;
            left: 10px;
        }
    
        .-previous {
            order: 2;
            position: absolute;
            right: 80px;
            bottom: 40px;
        }
    
        .-next {
            order: 3;
            position: absolute;
            bottom: 40px;
            right: 20px;
        }
    
        
    
        .-pageJump {
            input {
                border: 1px solid black;
                width: 70px;
                
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button{
                -webkit-appearance: none !important;
                margin: 0;                
            }
            input[type=number] {
                -moz-appearance: textfield;                
            }
        }
    
        select {
            border: none;
            font-size: 14px;
        }
        .-btn {
            background: none !important;
        }
        @media (max-width: 991px) {
            .-next, .-previous {
                bottom: -20px;                
            }
            .-next{
                right: 40%;
            }
            .-previous {
                right: 60%;
            }
            .-pageInfo{
                right: 0%;
            }
            .-pageSizeOption{
                right: 15%;
            }
        }
    }
}
.modal-impersonate {
    background: $white;
    header {
        padding: 20px 25px;
        .bt-close {
            display: inline-block;
            cursor: pointer;

            &:hover {
                color: $onyx;
            }

            i {
                font-size: 40px;
            }
        }
    }
    table {
        text-align: center;

        input[type='radio'] {
            margin-right: 10px;
        }
    }
    footer {
        padding: 20px;
    }
}

.v2 {
    
    #modal-impersonate-container {        
        @media(min-width:991px) {
                top: 56px;
        }
    }
    
    .impersonate-table{
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
}
