.walkthrough__tooltip__content__main {
    &--heading {
        padding-bottom: 16px;
        text-align: center;
        h2 {
            font-family: 'Diagramm', 'Roboto';
            font-size: 28px;
            font-weight: 600;
            margin-bottom: 0;
            line-height: 32px;
        }

        @media (max-width: $smMaxWidth) {
            max-width: 95%;
            h2 {
                font-size: 22px;
            }
        }
    }

    &--info {
        text-align: center;
        span {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }
}

.walkthrough__tooltip__content {
    &--heading {
        padding-bottom: 16px;
        text-align: left;
        h2 {
            font-family: 'Diagramm', 'Roboto';
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0;
            line-height: 24px;
        }

        @media (max-width: $smMaxWidth) {
            max-width: 95%;
        }
    }

    &--info {
        text-align: left;
        span {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }

        ul {
            padding-left: 1rem;
        }

        .walkthrough__tooltip__content__pn-outlet {
            .beggining {
                padding-bottom: 8px;

                span {
                    font-weight: 500;
                }
            }

            &--body {
                display: flex;
                align-items: center;
                flex-direction: column;

                .first-block {
                    display: flex;
                    align-items: baseline;
                    justify-content: center;
                    gap: 10px;
                    padding-bottom: 8px;

                    svg {
                        width: 16px;
                        path {
                            fill: $white;
                        }
                    }
                }

                .second-block {
                    display: flex;
                    align-items: baseline;
                    justify-content: center;
                    gap: 10px;

                    svg {
                        width: 16px;
                        rect {
                            fill: $white;
                        }
                    }
                }
            }
            
        }
    }
}

.__floater__arrow {
    svg {
        polygon {
            fill: #20367A;
        }
    }
    @media (max-width: $smMaxWidth) {
        display: none !important;
    }
}
// Responsive

@media (max-width: $smMaxWidth) {
    .__floater.__floater__open {
        .__floater__body {
            .react-joyride__tooltip {
                padding: 16px !important;
            }
        }
    }
}