//Global
.copy-to-clipboard__btn {
    background-color: transparent;
    border: 0;
    padding: 0;
    outline: 0;

    &:focus {
        outline: 0;
    }
}

//Big Banner
.big-banner-rocket {
    background: #fdf8f1;
    flex-direction: column;
    .check-colors {
        text-align: left;
        @media (max-width: 768px) {
            text-align: center;
        }
    }

    .big-banner-rocket__container {
        padding-top: 1rem;
        // padding-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .big-banner-rocket__description {
            font-family: $titleFont;
            color: $primaryColor;

            h2 {
                font-size: 28px;
                font-weight: 500;
                margin: 0;
            }

            .rocket_promotion__description--long {
                font-size: 16px;
                font-weight: 400;
            }
        }

        .big-banner-rocket__code--section {
            font-family: $titleFont;
            color: $primaryColor;

            .big-banner-rocket__code {
                display: flex;
                align-items: center;
                gap: 1rem;

                span {
                    font-size: 22px;
                    font-weight: 700;
                }

                img {
                    max-width: 23px;
                    cursor: pointer;
                }
            }

            .big-banner-rocket__small--text {
                font-size: 16px;
                font-weight: 500;
            }
        }

        @media (max-width: 768px) {
            flex-direction: column;
            text-align: center;

            .big-banner-rocket__description {
                h2 {
                    font-size: 24px;
                }

                .rocket_promotion__description--long {
                    font-size: 14px;
                }
            }

            .big-banner-rocket__code--section {
                display: flex;
                align-items: center;
                gap: 1rem;
            }
        }

        @media (max-width: 576px) {
            .big-banner-rocket__description {
                .big-banner-rocket-title {
                    display: none;
                }

                h2 {
                    font-size: 19px;
                }

                .rocket_promotion__description--long {
                    display: none;
                }
            }

            .big-banner-rocket__code--section {
                flex-direction: column;
                gap: 0;
            }
        }

        @media (min-width: 576px) {
            .small-banner-rocket-title {
                display: none;
            }
        }
    }
}

//Small Banner
.small-banner-rocket {
    background: #fdf8f1;
    border: 1px solid $primaryColor;
    padding: 0.8rem;

    .small-banner-rocket__container {
        font-family: $titleFont;
        color: $primaryColor;

        .small-banner-rocket__description {
            font-family: $titleFont;
            color: $primaryColor;

            h2 {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 0.3rem;
            }
        }

        .small-banner-rocket__code--section {
            display: flex;
            align-items: center;
            gap: 16px;
            padding-bottom: 0.3rem;

            span {
                font-weight: 400;
                font-size: 22px;
            }

            .code__section--code {
                font-weight: 700;
            }

            @media (max-width: 768px) {
                span {
                    font-size: 20px;
                }
            }
        }

        .rocket_promotion__description--long {
            font-size: 12px;
            font-weight: 400;
        }
    }

    @media (max-width: 1024px) {
        margin-bottom: 1rem;
    }
}

// BigBannerRocketV2
.big-banner-rocket-promo-v2 {
    background-color: #000;

    .big-banner-rocket-promo-v2-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 14px;
        padding-bottom: 14px;
        gap: 16px;

        @media (max-width: $mdMaxWidth) {
            flex-direction: column;
            align-items: baseline;
            margin: 0;
            max-width: 100%;
        }

        .banner-title-content {
            padding-bottom: 8px;

            h3 {
                font-size: 28px;
                font-weight: 500;
                font-family: $titleFontNormal;
                color: $white;
                margin: 0;
                padding: 0;

                @media (max-width: $smMaxWidth) {
                    font-size: 18px;
                }
            }

            p {
                font-family: $titleFontRegular;
                color: $white;
                font-size: 18px;
                margin-bottom: 0;
                font-weight: 400;

                @media (max-width: $smMaxWidth) {
                    font-size: 14px;
                }
            }
        }

        .banner-card-content {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            gap: 8px;

            @media (max-width: $mdMaxWidth) {
                width: 100%;
            }

            &>div {
                flex: 1;
                width: 100%;
            }

            @media (max-width: $smMaxWidth) {
                flex-direction: column;
            }

            .rocket-promo-card {
                background-color: #fff4e4;
                padding: 12px;
                border-radius: 10px;
                display: flex;
                align-items: baseline;
                flex-direction: column;
                gap: 8px;
                height: 100%;

                .rocket-card-title {
                    h5 {
                        margin: 0;
                        font-size: 32px;
                        color: $primaryColor;
                        font-weight: 700;
                        line-height: 0.9;

                        @media (max-width: $smMaxWidth) {
                            font-size: 18px;
                        }

                        span {
                            font-weight: 400;
                            font-size: 16px;
                        }
                    }
                }

                .rocket-card-content {
                    display: flex;
                    align-items: center;
                    justify-content: baseline;
                    gap: 10px;

                    h6 {
                        margin: 0;
                        color: $primaryColor;
                        font-size: 16px;
                        font-weight: 500;
                    }

                    img {
                        width: 18px;
                    }
                }

                .rocket-card-footer {
                    p {
                        margin-bottom: 0;
                        color: $primaryColor;
                        font-weight: 400;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

// BigBannerRocketV2

.small-banner-rocket-promo-v2 {
    background-color: #FFFCF8;
    border: 1px solid #A9AEBC;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .banner-title-content {
        width: 100%;
        padding-bottom: 16px;

        h3 {
            color: $primaryColor;
            font-size: 500;
            font-size: 18px;
            font-family: $titleFontNormal;
            margin: 0;

            span {
                font-weight: 700;
            }
        }
    }

    .banner-card-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 8px;
        width: 100%;

        .rocket-promo-card {
            padding: 16px;
            background-color: #FFF4E4;
            border-radius: 10px;
            width: 100%;

            display: flex;
            align-items: baseline;
            flex-direction: column;
            gap: 6px;

            &>div {
                display: flex;
                flex-wrap: wrap;
                gap: 6px;
                align-items: center;
            }

            h5 {
                margin: 0;
                font-size: 14px;
                font-weight: 400;
                color: $primaryColor;
            }

            .enter-promotion-code {
                span {
                    font-size: 14px;
                    font-weight: 700;
                    color: $primaryColor;
                }

                img {
                    width: 16px;
                    margin-left: 6px;
                }
            }

            p {
                margin-bottom: 0;
                color: $primaryColor;
                font-size: 12px;
            }
        }
    }
}