:root {
      // Variables para cambiar colores
  --configurable-primary-color: #3F4350;
  --configurable-primary-color-active: #3F4350;
  --configurable-color-border: rgb(226, 228, 233);
  --configurable-color-disabled: rgb(226, 228, 233);
  --configurable-primary-color-info: rgba(215, 234, 248, 0.8);
 
  /*variable para definir la altura que restamos al contenedor principal,
  por si la aplicacion alojadora tiene un menu fijo y hay que restar esta altura*/
  --configurable-height-adjustment: 180px;
//   --configurable-height-adjustment: 120px;
 
  --configurable-secondary-color: #6c757d;
  --configurable-background-color: #f8f9fa;
  --configurable-hover-color: #DBE4E9;
}


@media (max-width: 1199px) {
    :root {
      --configurable-height-adjustment: 174px;
    }
}

@media (max-width: 991px) {
    :root {
      --configurable-height-adjustment: 92px;
    }
}

//Modal configurable baths
.modal--configurable-baths {
    font-family: $infoFont;

    .modal-header {
        border: none;
    }

    .modal-body {
        background-color: #fafbfb;
        padding: 24px;

        @media (max-width: $smMaxWidth) {
            padding: 16px;
        }
    }

    .model--body-container {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        .modal--body-content {
            display: flex;
            flex-direction: column;
    
            .body-content__item {
                padding-bottom: 16px;
                h4 {
                    color: $primaryColor;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    margin-bottom: 0;
                }
    
                &--desc {
                    color: $primaryColor;
                    font-size: 14px;
                    font-weight: 300;
    
                    .material-brand {
                        color: #81889C;
                    }
                }
            }
    
            .body-content__item:last-child {
                padding-bottom: 0;
            }
        }

        .body-action--download {
            i {
                color: $normalblue;
                font-size: 12px;
                margin-right: 8px;
            }

            button {
                color: $normalblue;
                font-weight: 500;
                font-size: 14px;
                border: 0;
                background-color: transparent;
                padding-left: 0;
                padding-right: 0;
    
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        @media (max-width: $mdMaxWidth) {
            flex-direction: column-reverse;

            .body-action--download {
                padding-bottom: 16px;
            }
        }

    }

    .modal--body-footer {
        padding-top: 16px;
        .body-footer--edit {
            a {
                button {
                    background-color: $white;
                    color: $primaryColor;
                    border: 1px solid $primaryColor;
                    padding: 14px;
                    font-family: $infoFont;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 0;
                    
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;

                    &:hover {
                        background-color: $primaryColorHover;
                    }
                }
            }
        }
    }
}

//Catalog configurable baths
.catalog__configurable__baths {
    &--goback {
        color: $normalblue;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 32px;

        i {
            font-size: 12px;
            margin-right: 8px;
        }

        a {
            &:hover {
                text-decoration: underline !important;
            }
        }

        @media (max-width: $smMaxWidth) {
            padding-bottom: 16px;
        }
    }

    &--page-title {
        padding-bottom: 32px;

        h1 {
            font-size: 28px;
            font-weight: 500;
            line-height: 32px;
            color: $primaryColor;
            margin-bottom: 0;
        }
    }

    &--materials {
        display: grid;
        gap: 16px;
        grid-auto-flow: dense;
        grid-auto-rows: auto;
        grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    }

    &--filters {
        padding-bottom: 32px;
        .filter-by {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            padding-bottom: 8px;
        }
    }
}

//Catalog config bath card
.config__bath__card {
    background-color: $white;

    &--img {
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &--info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 16px;

        .brand {
            font-family: $infoFont;
            color: $primaryColor;
            font-size: 12px;
            line-height: 16.5px;
            padding-bottom: 6px;
        }

        .name {
            font-family: $titleFont;
            color: $primaryColor;
            font-weight: 500;
            font-size: 22px;
            line-height: 20px;
        }
    }

    .x-divider {
        height: 1px;
        background-color: #d8d8d8;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    &--price {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 16px;

        span {
            font-size: 16px;
            font-weight: 500;
            color: $primaryColor;
        }
    }

    &--btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;

        button, a {
            background-color: #3f4350;
            color: $white;
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            width: 100%;
            padding: 8px 18px;
            line-height: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            border: 0;

            &:hover {
                background-color: #dbe4e9;
                color: $primaryColor;

                svg {
                    path {
                        fill: $primaryColor;
                    }
                }
            }
        }
    }
}

.config__bath__filter {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &--select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        width: 100%;

        .flex-block {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 24px;
            width: 100%;

            .custom__select {
                width: 100%;
                border-radius: 0 !important;
                border-color: #c7c7c7 !important;

                .Select-placeholder {
                    color: $primaryColor !important;
                    font-weight: 400;
                    font-family: $infoFont;
                }

                .Select-arrow-zone {
                    .Select-arrow {
                        border-color: $primaryColor transparent transparent !important;
                    }
                }

                .Select-menu-outer {
                    .Select-menu {
                        .Select-option {
                            color: $primaryColor !important;
                            font-size: 14px;
                            font-family: $infoFont;
                        }
                    }
                }

                .Select-value {
                    .Select-value-label {
                        font-size: 14px;
                        color: $primaryColor !important;
                    }
                }
            }

            @media (max-width: $smMaxWidth) {
                flex-direction: column;
            }

            @media (max-width: $mdMaxWidth) {
                gap: 16px;
            }
        }

        @media (max-width: $mdMaxWidth) {
            gap: 16px;
        }
    }

    @media (max-width: $mdMaxWidth) {
        gap: 16px;
        flex-direction: column;
    }
}

.configurable__bath__view__config {
    button {
        font-family: $infoFont;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: $normalblue;
        border: 0;
        background-color: transparent;
        display: inline-flex;
        padding: 0;

        &:hover {
            text-decoration: underline;
        }
    }
}

.configurable-bath-btn-orders {
    padding-top: 6px;
    button {
        font-family: $infoFont;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: $normalblue;
        border: 0;
        background-color: transparent;
        display: inline-flex;
        padding: 0;

        &:hover {
            text-decoration: underline;
        }
    }
}

//Configurable plan acceptance
.config__plan__acceptance {
    &--back-btn {
        padding: 24px 0;

        button {
            background-color: transparent;
            color: $normalblue;
            padding: 0;
            border: 0;
            border-width: 0 0 1px 0;
            border-color: transparent;
            border-style: solid;
            font-size: 16px;
            font-weight: 500;

            display: flex;
            align-items: center;
            gap: 8px;

            i {
                font-size: 12px;
            }

            &:hover {
                border-bottom: 1px solid $normalblue;
            }
        }
    }

    &--content-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        background-color: $white;
        padding: 32px;
        margin-bottom: 24px;

        .main-heading {
            border-bottom: 1px solid #C7C7C7;
            width: 100%;
            margin-bottom: 24px;
            padding-bottom: 12px;

            h1 {
                color: $primaryColor;
                font-family: $titleFont;
                font-weight: 500;
                font-size: 28px;
                margin-bottom: 0;
            }
        }

        .pdf-text-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 24px;

            .pdf-viewer {
                height: 600px;
                overflow-x: hidden;
                border: 1px solid #C7C7C7;
                width: 100%;
                position: relative;

                .config-plan-acceptance-document {
                    .react-pdf__Page__canvas {
                        width: 100% !important;
                        height: 100% !important;
                    }
                }

                @media (max-width: $smMaxWidth) {
                    height: 300px;
                    overflow-x: hidden;
                }
            }

            .text-checkbox {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                height: 600px;
                padding-left: 24px;
                border-left: 1px solid #C7C7C7;

                .main-paragraph {
                    font-size: 16px;
                    font-family: $titleFont;
                    font-weight: 500;
                    color: $primaryColor;
                }

                .second-paragraph {
                    font-size: 14px;
                    font-weight: 500;
                    color: $primaryColor;
                }

                .normal-paragraph {
                    font-size: 14px;
                    font-weight: 300;
                    color: $primaryColor;
                }

                .downlow-plan-acceptance {
                    padding-bottom: 16px;
                    button {
                        background-color: transparent;
                        color: $normalblue;
                        padding: 0;
                        border: 0;
                        border-width: 0 0 1px 0;
                        border-color: transparent;
                        border-style: solid;
                        font-size: 16px;
                        font-weight: 400;

                        display: flex;
                        align-items: center;
                        gap: 8px;

                        i {
                           font-size: 12px;     
                        }

                        &:hover {
                            border-bottom: 1px solid $normalblue;
                        }
                    }
                }

                .accept-plan-checkbox {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    

                    .checkbox-block {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 8px;

                        border-top: 1px solid #C7C7C7;
                        border-bottom: 1px solid #C7C7C7;
                        padding: 16px 0;

                        label {
                            font-size: 14px;
                            font-weight: 500;
                            color: $primaryColor;
                            cursor: pointer;
                            margin-bottom: 0;
                        }

                        @media (max-width: $mdMaxWidth) {
                            border-top: 0;
                            padding-top: 0;
                        }
                    }

                    .button-block {
                        width: 100%;

                        .accept-plan-button {
                            background-color: $primaryColor;
                            color: $white;
                            font-weight: 500;
                            font-size: 12px;
                            border: 0;
                            padding: 18px 16px;
                            line-height: 0;
                            width: 100%;
                            margin-top: 16px;
                            cursor: pointer;
        
                            &:hover {
                                background-color: #DBE4E9;
                                color: $primaryColor;
                            }

                            &:disabled {
                                background-color: #D8D8D8;
                                color: $white;
                                cursor: not-allowed;
                            }
                        }
                    }

                    @media (max-width: $mdMaxWidth) {
                        position: fixed;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        z-index: $layer60;
                        background-color: $white;
                        margin-right: 16px;
                        margin-left: 16px;
                        padding: 16px;
                    }
                }

                @media (max-width: $mdMaxWidth) {
                   border: 0;
                   height: auto;
                   padding-left: 0;
                }
            }

            @media (max-width: $mdMaxWidth) {
                flex-direction: column-reverse;
                gap: 16px;
            }

            @media (max-width: $smMaxWidth) {
                gap: 0;
            }
        }

        @media (max-width: $mdMaxWidth) {
            padding: 24px;
        }

        @media (max-width: $mdMinWidth) {
            padding: 16px;
        }
    }
}