.help-row {
    background-color: $mercury;
    color: #333;
    text-align: right;
    margin: 40px 0px 0px 0px;

    .icon-chat {
        height: 40px;
        margin: 10px 0;
    }
}
.suggestions-row {
    background-color: $mercury;
    padding: 5px 0;
    i {
        margin: 0 10px;
    }
    .container {
        display: flex;
        > * {
            display: flex;
            align-items: center;
        }
        span {
            flex-grow: 1;
        }
    }
    &:hover {
        background-color: $iron;
    }
}

.footer {
    padding: 20px 0px 20px 0;
    background: $onyx;
    // color: #ddd !important;
    font-weight: 300;
    font-size: 13px;

    a,
    span {
        // color: $iron;
        text-decoration: none !important;

        &:hover {
            color: $white !important;
        }
    }
    .apps {
        padding: 0px 40px;
        @media (max-width: $smMaxWidth) {
            padding: 0px 20px;
        }
    }
    .footer-col {
        vertical-align: top;
        width: 16%;
        display: inline-block;

        .footer-title {
            font-family: $titleFont;
            font-size: 16px;
            font-weight: 400;
            color: $white;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                @media (max-width: $smMaxWidth) {
                    margin: 10px 0px;
                }
            }
        }

        .dl-play-store,
        .dl-app-store {
            width: 92%;
        }
    }

    .legal {
        color: #fff !important;

        @media (max-width: $smMaxWidth) {
            text-align: center;
        }

      

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: inline-block;

                a,
                span {
                    color: $white !important;
                    margin-right: 10px;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }
            }
        }
    }

    hr {
        border-color: $iron;
        margin: 30px 0 20px;
    }
    .brands {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        @media (max-width: $mdMaxWidth) {
            flex-direction: column;
            align-items: center;
        }
        .img-fluid {
            text-align: center;
            padding: 0 5px;
            height: 20px;
            margin: 0px 10px;
            &.silestone {
                // max-height: 20px;
                height: 23px;
            }

            &.dekton {
                // max-height: 20px;
                // height: 40px;
            }

            &.sensa {
                // max-height: 20px;
                // height: 40px;
            }

            &.natural-stone {
                // max-height: 50px;
                // height: 40px;

                // @media (max-width: $smMaxWidth) {
                //     margin-top: -15px;
                // }
            }

            object {
                max-width: 100%;
                padding: 20px 40px;
            }
        }
    }
      .social-links {
            // text-align: right;

            @media (max-width: $smMaxWidth) {
                text-align: center;
                margin-top: 15px;
            }

            a,
            span {
                font-size: 20px;
                vertical-align: middle;
                margin-right: 15px;
                // opacity: 0.75;

                &:hover {
                    opacity: 1;
                }
            }
        }
}


.recently-visited-products-container {
    background-color: $backgroundColor;
}

.footer-v2 {
    padding-top:40px;
    color:white;
    background:$newOnyx;
    font-family: 'Roboto';
    font-size: 16px;
    .social-links{
        text-align:left;
        a,span{
        //   border-radius:100%;
                // padding:5px;
                // border:1px solid white;
        }
    
    }
    ul{
        list-style: none;

        }
        
        li{
            margin:10px 0px;
            color:white;
            a,span{
                color:white !important;
                
            }
        }
    }
            .footer-links{
                ul{
                    &>li:first-child{
                            margin-bottom:15px;
                            font-weight: 400;
                        }
                }
}

.about-footer{
   
    @media (max-width: 771px) {
        padding: 0;
        text-align: center
    }
    
}
.chevron-reponsive-footer{
    display: flex;
    justify-content: space-between;
    width: 80%;
    i{
        display: none;
    }
    @media (max-width: 445px) {
        i{
            display: block;
        }
    }
}
#links-footer{
    @media (max-width: 445px) {
        display: none;
    }
}
#links-footer2 {
    @media (max-width: 445px) {
        display: none;
    }
}

// TinyFooter
.tinyfooter-main-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #1F2228;
    width: 100%;
    padding: 12px 24px;

    .tinyfooter-main-container__first-level {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.361);
        padding-bottom: 4px;

        &--ecosentino-logo {
            img {
                max-width: 122px;
            }
        }

        &--cosentino-brands {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 40px;

            .silestone-brand {
               width: 74px;
            }

            .dktn-brand {
                width: 67px;
            }

            .dekton-brand {
                width: 74px;
            }

            .sensa-brand {
                width: 70px;
            }

            .natural-stone-brand {
                width: 115px;
            }

            @media (max-width: $smMaxWidth) {
                flex-direction: column;
                gap: 4px;
                padding-top: 6px;
            }
        }

        @media (max-width: $mdMaxWidth) {
            flex-direction: column;
            padding-bottom: 8px;
        }
    }

    .tinyfooter-main-container__second-level {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 4px;

        span {
            color: $white;
            font-size: 14px;
            font-weight: 300;
        }

        a {
            color: $white;
            font-weight: 500;
            font-size: 12px;

            &:hover {
                text-decoration: underline !important;
            }
        }

        &--terms {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;

            @media (max-width: $smMaxWidth) {
                flex-direction: column;
                gap: 0;
            }
        }

        @media (max-width: $mdMaxWidth) {
            flex-direction: column-reverse;
            padding-top: 8px;
        }
    }
}