
#main-header {
    background: $white;
    border-bottom: 1px solid $mercury;
    height: 80px;

    > .container {
        height: 80px;
    }

    @media (max-width: $smMaxWidth) {
        top: 60px;
    }

    #logo {
        margin: 20px 0;

        img {
            width: 165px;
        }
    }

    .main-menu-classic{
        text-align: center !important;
        ul{
            display: inline-block !important;
            
            li{
                margin: 0 10px !important;
            }
        }
    }
    nav#main-menu {
        margin: 20px 0;
        text-align: left;
        .lowes-container {
            padding: 0;
        }
        ul {
            display: flex;
            justify-content: space-evenly;
            list-style: none;
            //display: inline-block;
            margin: 0;
            padding: 0;
        
            
                
            li {
                display: inline-block;
                margin-top: 8px;
                margin-right: 10px;
                margin-bottom: 10px;
                //margin: 0 10px;
                div {
                    ul{
                        li:first-child {
                            margin-top: 1.5rem
                        }
                    }
                }
                a,
                span {
                    color: $thunder;
                    font-family: $titleFont;
                    font-size: 17px;
                    border-bottom: 1px solid transparent;

                    &:hover,
                    &.active {
                        color: $onyx;
                        border-bottom: 1px solid $onyx;
                    }
                }
            }
        }

        .has-submenu {
            position: relative;

            &:hover .submenu {
                opacity: 1;
                visibility: visible;
                pointer-events: unset;
            }
        }

        .submenu {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;

            position: absolute;
            top: calc(100% + 1rem);
            left: 0;
            width: 30vw;
            background: #fff;
            padding: 0.8rem;
            border-radius: 2px;
            box-shadow: 0 3px 6px rgba($onyx, 0.32);
            transition: all 0.3s ease-out 0s;

            &::before {
                content: '';
                position: absolute;
                bottom: 100%;
                left: 0;
                right: 0;
                height: 1rem;
                background: transparent;
            }

            li {
                display: block;
                text-align: left;

                &:not(:last-child) {
                    border-bottom: 1px solid rgba($onyx, 0.32);
                    padding-bottom: 0.2rem;
                    margin-bottom: 0.2rem;
                }

                a {
                    // white-space: nowrap;

                    &.nav-link {
                        padding: 0;
                    }
                }
            }
        }
    }

    /*.main-menu-v2 {
    
        text-align: left !important;
        ul {
            display: flex !important;
            justify-content: space-between !important;

            li:first-child {
                    margin-top: 2rem !important;
                }
            
            li {
                margin: 0px;
                margin-top: 8px !important;
                margin-right: 10px !important;
                margin-bottom: 10px !important;
            }

        }

        
    }*/

    nav#top-side-menu {
        margin: 20px 0;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            text-align: right;

            & > li {
                display: inline-block;
                cursor: pointer;

                & > a,
                & > span {
                    color: $thunder;
                    padding: 10px 15px;
                    @media (max-width: $lgMaxWidth) {
                        padding: 10px;
                    }
                    // border-left: 1px solid #ddd;

                    &.cart {
                        position: relative;
                        display: inline-block;

                        .badge {
                            // background: rgba($onyx, 0.85);
                            position: absolute;
                            top: 0;
                            right: -5px;
                        }
                    }

                    &:hover {
                        color: $onyx;
                    }

                    .icon {
                        font-size: 25px;
                        height: 25px;

                        @media (max-width: 375px) {
                            height: 20px;
                        }
                    }
                }
            }
        }
    }

    #mobile-main-menu-toggler {
        background: none;
        border: none;
        padding: 20px 0;
        font-size: 24px;
        color: $thunder;
        cursor: pointer;

        &:hover {
            color: $onyx;
        }
    }
}
.icon.close-btn-icon{
    font-size: 20px !important;
    height: 20px !important;
}
#mobile-main-menu {
    background: $smoke;
    width: 100%;
    z-index: $layer20;
    overflow-y: auto;
    max-height: 66vh;
    border-bottom: 1px solid #e6e6e6;

    .submenu {
        list-style: none;
        padding-left: 1rem;
        li {
            margin: 0 0 0.2rem;
        }
        a {
            padding: 0;
        }
    }
}

#main-submenu {
    background: $smoke;
    text-align: center;

    nav {
        ul {
            display: inline-block;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;

                a,
                span {
                    display: inline-block;
                    font-size: 14px;
                    color: $thunder;
                    padding: 8px 10px;

                    &:hover {
                        color: $onyx;
                    }
                }
            }
        }
    }
}
.extra-discount-mall {
    border-radius: 4px;
    background: #aed8d3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    margin: 20px;
    img {
        width: 29px;
        margin-right: 20px;
    }
    .alert__title {
        font-size: 20px;
        font-weight: 500;
        color: #232323;
    }
}
.checkout {
    .extra-discount-mall {
        margin: 0px 0px 10px;
        padding: 10px 20px;
        min-height: 49px;
    }
}

#mini-cart-container {
    position: absolute;
    top: 56px;
    right: 0;
    width: 780px;
    text-align: left;
    background: $white;
    border-radius: 5px;
    z-index: $layer30;
    max-height: 80vh;
    overflow-y: auto;
    cursor: auto;

    @media (max-width: $mdMaxWidth) {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        max-height: none;
        overflow-x: none;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        border-radius: 0;
    }

    #mini-cart {
        color: $thunder;

        .image {
            max-height: 70px !important;
        }

        header {
            padding: 20px 25px;

            .bt-close {
                display: inline-block;
                cursor: pointer;

                &:hover {
                    color: $onyx;
                }

                i {
                    font-size: 40px;
                }
            }
        }

        .item-list {
            padding: 0 25px;

            max-height: 200px;

            overflow-y: scroll;

            &.more-height {
                max-height: 300px;
            }

            .item {
                padding: 10px 0;
                border-bottom: 1px solid $mercury;

                &:first-child {
                    border-top: 1px solid $mercury;
                }

                .title {
                    font-weight: 600;
                    text-transform: capitalize;
                    font-weight: 600;
                }

                .subtitle {
                    font-size: 11px;
                    text-transform: capitalize;
                }

                .price {
                    margin: 2px 0;
                }

                .quantity-label {
                    display: block;
                    color: $gray;
                    font-size: 12px;
                }
                .bundle-content {
                    cursor: pointer;
                    color: deepskyblue;
                }

                .bt-edit {
                    cursor: pointer;
                }

                .bt-delete {
                    color: $gray;
                    font-size: 14px;
                    text-transform: uppercase;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .total {
            margin-top: 20px;
            background: $solitude;
            color: $thunder;

            &.cart-empty {
                .label {
                    padding: 10px 0;
                }
            }

            .row {
                padding: 0 40px;

                .label {
                    font-size: 15px;
                    margin: 3px 10px 3px 10px;
                }

                .quantity {
                    font-size: 15px;
                    background: $white;
                    margin: 10px 3px 10px 20px;
                    padding: 3px 10px 3px 10px;
                    width: fit-content;
                }

                .amount {
                    color: $onyx;
                    font-size: 20px;
                    font-weight: bold;
                }
            }
        }

        .cross-selling {
            color: $thunder;
            padding: 0 40px;
            font-size: 14px;

            .item {
                padding: 10px 0;
                border-bottom: 1px solid #ddd;

                .label {
                }

                .image {
                    max-height: 100px;
                }
            }
        }

        footer {
            padding: 20px 20px;
        }
    }
}
[dir='rtl'] {
    #mini-cart-container {
        right: unset;
        left: 0;
        text-align: right;
    }
}

#user-profile-modal-container {
    position: absolute;
    top: 56px;
    right: 0;
    width: 400px;
    text-align: left;
    background: $white;
    border-radius: 5px;

    z-index: $layer30;

    @media (max-width: $mdMaxWidth) {
        background: $aluminum;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        overflow-x: none;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
    ul > li > a,
    ul > li > span {
        padding: 0px !important;
    }
    .bt-close {
        display: inline-block;
        cursor: pointer;

        &:hover {
            color: $onyx;
        }

        i {
            font-size: 40px;
        }
    }
    .user-profile-menu {
        .badge {
            background: rgba($onyx, 0.85);
        }
    }
}

.user-profile {
    header {
        text-align: center;
        padding: 10px;
        .icon {
            font-size: 25px;
            border: 1px solid black;
            border-radius: 100%;
            height: 40px;
            margin-right: 10px;
            padding: 5px;
        }
    }
    ul {
        text-align: center !important;
        li {
            padding: 10px;
            display: block !important;
        }
    }

    a,
    span {
        border: none !important;
    }
    footer {
        padding: 20px;
    }
}
.user-profile-responsive {
    > img {
        margin-right: 20px;
        padding: 10px;
        border: 1px solid black;
        border-radius: 100%;
    }
}

[dir='rtl'] {
    #main-header {
        nav#top-side-menu {
            ul {
                text-align: left;
                & > li > a,
                & > li > span {
                    border-left: none;
                    border-right: 1px solid #ddd;
                }
            }
        }
    }

    #user-profile-modal-container {
        right: unset;
        left: 0;
    }
}

.arrow-right{
    position: absolute;
    right: 30px;
}
.my-account-header{
    position: fixed;
    background-color: $white;
    width: 20rem;
    right: 0px;
    z-index: 250;

    [dir='rtl'] & {
        left: 0;
        right: unset;
    }
}
.my-account-header-responsive{
    position: fixed;
    background-color: $white;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: none;
    max-height: none;
    overflow-x: none;
    overflow-y: scroll;
    border-radius: 0;
    z-index: $layer40;

    .close-button-userprofile {
        span {
            font-size: 1.4rem;
            cursor: pointer;
            padding: 0.4rem;
        }
    }
}

.logo-cosentino>a>img {
    z-index: 1;

    @media (max-width: 1199px) {
        position: relative;
        left: 42px;

        [dir='rtl'] & {
            left: 0;
        }
    }

    @media (max-width: 365px) {
        width: 120% !important;
    }
}
.header-v2{

    #mini-cart-container {
        top: 58px;
    }
    #user-profile-modal-container {
        top: 58px;
        border-radius: 0;
        header{
            text-align:left;
        }
    }

    .user-profile-menu,#mobile-main-menu{
        background:white;
        ul{
            >li:last-child {
                margin-bottom:16px;
            }
            li{
            font-family:'diagramm-medium';
            font-weight:100;
            text-align:left;
        }
        
    }

    }

    .go-to-cosentinowe {
        color: $primaryColor;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
    }
}

.btns-header {
    margin-left: auto;
    width: fit-content;
    position: relative;
    top: 0px;
    @media (max-width: 1199px) {
        width: 98vw;
    }
}
.burger-menu {
    position: absolute;
    left: 0px;
    top: 3px;

    [dir='rtl'] & {
        position: relative;
    }
}



.header-menu-link {
    text-align: left;
}
.header-menu-link:hover{
    cursor: pointer;
}

.nav-burger.responsive {
    width: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 0px;

    li {
        a {
            span {
                position: absolute;
                right: 30px
            }
        }

        div {
            ul {
                list-style-type: none;
            }

        }
    }

    .link-hover {
        position: relative;
    }

}

.header-links-separator {

    overflow: hidden;
    margin: 0;
}

.a-hover-menu {
    text-transform: lowercase;
    display: block;
}
.a-hover-menu::first-letter {
    text-transform: capitalize !important;
}
#main-header {

    height: fit-content;

    >.container {
        height: 56px;
    }

    .logo-container {
        display: flex;

        #logo {
            margin: 5px 0;
            position: absolute;
            //top: 0;
            //left: 0;
            flex: 1 1 0;
        }

        #main-menu {
            flex: 3 1 0;
            margin-left: 2rem;
            z-index: $layer10;

            @media (max-width: 1199px) {
                display: none;
            }
        }

        .btns-header {
            margin-left: 2rem;
            align-self: stretch;

            @media (max-width: 1199px) {
                margin-left: auto;
                min-height: 45px;
            }

        }

    }

    nav#top-side-menu {
        margin: 2px 0;

        ul {
            &>li {

                &>a,
                &>span {
                    font-family: $titleFont;
                }
            }
        }

        .cart {
            position: relative;
            display: inline-block;
            width: auto;
            background-color: transparent;
            .badge {
                background: #DBE4E9;
                color: black;
                border-radius: 4px;
                margin-left: 7px;
                position: relative;
                font-weight: 300;

                @media (max-width:400px) {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 0px !important;
                    right: -6px !important;
                    margin: 0;
                    padding: 8px;
                    height: 0;
                }
            }
        }
    }

    nav#main-menu {
        margin: 5px 0;

        ul {
            &>li {

                &>a,
                &>span {
                    font-family: $titleFont;
                }
            }
        }
    }
}

.v2 {
    #main-header {
        nav#top-side-menu {
            .cart {
                .badge {
                    @media (max-width: 400px) {
                        top: -11px !important;
                        right: -15px !important;
                    }
                }
            }
        }
    }
}

.background{
    background-color: white;
    width: 100%;
    height: 35vh;
    position: fixed;
    left: 0px;   
    @media (max-width: 991px) {
        display: none;
    }
    
}
.extra-menu {
    position: fixed;
    background-color: white;
    //width: 100vw;
    height: max-content;
    //z-index: 10;

    /* duration of the fade transition */
    .group-links {
        display: flex !important;
        flex-direction: column !important;
        text-align: left !important;
        position: relative;

        @media (max-width: $lgMaxWidth) {
            padding-left: 24px;
        }
    }
    .group-links>li{
        @media (max-width: 1300px) {
            max-width: 150px;
        }
       
    }
    .group-links>li>a {
        //color: red !important;
        font-weight: 100 !important;
        color: #706e6e !important;
    }
}

.a-hover-menu:not(.v2){
    font-family: 'questa_bold' !important;
}
.sub-menu-link:not(.v2) {
    font-family: 'questa_medium' !important;
}
.a-hover-menu.v2{
    color: #232323 !important;
    font-family: 'diagramm-semibold' !important;
}
.sub-menu-link.v2{
    color: #4a4a4a !important;
    font-family: 'diagramm-regular' !important;
}
.arrow-down{
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;

    i {
        padding: 0.3rem;
    }
}

.extra-menu{
    @media (max-width: $lgMaxWidth) {
        position: inherit;
        background-color: transparent;
    }
}
