.go-back, .go-back-profile {
    cursor: pointer;
    display: inline-block;

    &:hover {
        color: $onyx;
    }

    i {
        display: inline-block;
        vertical-align: middle;
        font-weight:400;
    }

    .label {
        color: $gray;
        display: inline-block;
        font-size: 15px;
        margin-left: 10px;
        vertical-align: middle;

        &:hover {
            color: $thunder;
        }
    }

    .label-v2 {
        color: $primaryColor;
        font-weight: 500;
        font-size: 0.875rem;
    }
}
