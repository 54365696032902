$contentHPadding: 16px; 

.tip-modal {
    margin-top:15px;
    min-width:300px; 
    display:block;
}

.tip-modal-arrow {
    position:absolute;
    top:0;
    //right:15%;
    filter: drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.05));
    inset-inline-end: 152px;

    @media (max-width: $mdMaxWidth) {
        inset-inline-end: 50px;
    }
}

.tip-modal-dialog {
    position: relative;
    box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius:4px;
}

.tip-modal-content {
    padding:8px $contentHPadding 0;
}

.tip-modal-close {
    color: $onyx;
    position: absolute;
    top: 4px;
    right: 12px;
    font-size: 1.4rem;
    font-weight: lighter!important;
    cursor: pointer;
}

.tip-modal-header {
    padding-right: 2.4rem;
    font-size: 12px;
    font-weight: 400;
    color: $onyx;
}

.tip-modal-header-separator {
    margin-top: 4px;
    margin-bottom: 0;
    margin-left: -$contentHPadding;
    margin-right: -$contentHPadding;
    height: 1px;
    background-color: $loblolly;   
}