.logo-cosentino-lan {
    position: absolute;
    left: calc(-50vw + 50%);
    right: calc(-50vw + 50%);
    margin-left: auto;
    margin-right: auto;
    top: 60px;
}

.country-list {
    position: relative;
    top: 60px;
}
#top-ribbon {
    background: $thunder;
    color: $smoke;
    font-size: 12px;
    font-weight: 200;

    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $white;
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        color: $white;
        opacity: 1;
    }
    :-ms-input-placeholder {
        /* IE 10+ */
        color: $white;
    }
    :-moz-placeholder {
        /* Firefox 18- */
        color: $white;
    }
    input[type='text'] {
        color: $white;
        height: 100%;
    }
    
    .logo-cosentino {
        position: absolute;
        left: 45%;
       
        @media (max-width: 1199px) {
            display: none;
        }
    }
    .location{
        position: fixed;
        left: -25%;
        @media (max-width: 991px) {
            left: -50%;
        }
    }
    .select-center {
        input {
            cursor: default;
        }
        .Select {
            background: $thunder;
            border: none;
            color: $white;
            height: 32px;
            margin-left: 15px;
            outline: none;
            padding-left: 5px;
            width: 100%;
            .Select-value {
                span {
                    color: $white;
                }
            }
            .Select-input {
                input {
                    padding: 0 10px;
                }
            }

            option {
                background: $smoke;
                color: $thunder;
                padding: 5px;
            }
        }

        i {
            color: $white;
            cursor: default;
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }

    .icon {
        background: $onyx;
        display: block;
        height: 32px;
        left: -10px;
        padding-top: 5px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 32px;

        img {
            height: 20px;
            vertical-align: middle;
        }
    }

    input {
        background: transparent;
        border: none;
        bottom: 0;
        color: $iron;
        display: block;
        left: 0;
        outline: none;
        padding: 0 5px 0 37px;
        position: absolute;
        top: 0;
        vertical-align: middle;
        width: 100%;

        &:hover {
            color: #fff;
        }
    }

    .search,
    .location {
        background-color: $thunder;
        height: 32px;
        position: relative;
    }
    .location {
        bottom: 0;
        transition: 0.7s;
        z-index: $layer20;
        &.highlight {
            bottom: -10px;
            box-shadow: 0px 0px 10px 1px rgba(255, 255, 255, 1);
            transform: scale(1.2);
        }
        .text {
            left: -25%;
            position: absolute;
            text-align: center;
            top: 50px;
            width: 150%;
            h3 {
                font-weight: 300;
            }
        }
    }
    .remember-select-center {
        color: $onyx;
        .icon {
            background: unset;
        }
    }
}
@keyframes animacionCenterRepeat {
    from {
        box-shadow: 0 0 0 0 white;
    }
    to {
        box-shadow: 0 0 10px 1px white;
    }
}
@keyframes animacionCenter {
    from {
        box-shadow: 0 0 0 0 white;
    }
    to {
        box-shadow: 0 0 15px 1px white;
    }
}
.highlight-animate1 {
    animation: animacionCenterRepeat 0.6s 2;
    animation-direction: alternate;
}
.highlight-animate2 {
    animation: animacionCenter 0.6s 2;
    animation-direction: alternate;
}

[dir='rtl'] #top-ribbon {
    .icon {
        right: 0;
    }
    input {
        padding: 0 37px 0 5px;
        right: 0;
    }
    .select-center .Select .Select-input input {
        padding: 0 20px;
    }
    .Select-value {
        padding: 0 20px;
    }
}
.v2{
    #top-ribbon{
        background: white;
        color: $thunder;
    }
}


#top-ribbon-v2{
    border-bottom: 1px solid $mercury;
    height:40px;
    background:white;
    & > .container-fluid{
        height:100%;
        & >div{
            height:100%;
        }
    }
    .align-items-center{
        text-align: center;
        
    }

    .center-name-header{
        
        span{      
            position: relative;
            top: 4px;
            strong{     
                overflow: hidden;
                white-space: nowrap;
                max-width: 50vw;
                display: inline-block;
                text-overflow: ellipsis
            }
            span{
                position: relative;
                top: -7px;
            }

        }            
    }

    .logo-cosentino{
        z-index: 1;
        position: absolute;
        left: 45%;
        @media (max-width: 1199px) {
            display: none;
        }
    }

    .center-selector:not(.ikea){
        display: flex;
        align-items:center;
        justify-content: center;
        //position: absolute;
        //right: 100px;
        
        @media (max-width: 991px) {
            position: absolute;
            left: 25px;
            right: auto;
        }
    }
    .center-selector.ikea{      
        
        position: absolute;
        right: 100px;
        width: 200px;
        @media (max-width: 991px) {
            position: absolute;
            left: 25px;
            right: auto;
        }
        .Select-control{
            border: none !important;
            height: 10px;
        }
    }
    
}

.language-selector-topRibbon {
    @media (max-width: 991px) {
        position: absolute;
        right: 25px;
        left: auto;
    }
}