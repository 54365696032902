$datepicker__selected-color: #9dcdc3;

#section-kpis {
    margin-bottom: -40px;
    &:not(.detail) {
        background: $smoke;
        padding-bottom: 220px;
    }
    .rt-table {
        //shadow on horizontal scroll
        background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white), linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
            linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));
        background-position: left center, right center, left center, right center;
        background-repeat: no-repeat;
        background-color: white;
        background-size: 60px 100%, 60px 100%, 30px 100%, 30px 100%;
        background-attachment: local, local, scroll, scroll;
    }
    .top-background {
        position: relative;
        height: 325px;

        background-image: url('../img/backgrounds/dekton-kitchen-nilium.jpg');
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
        background-position: center bottom;

        .waves {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            background-image: url('../img/backgrounds/waves.png');
            background-repeat: no-repeat;
            background-attachment: scroll;
            background-size: 100% 100%;
            background-position: center bottom;

            @media (max-width: $mdMaxWidth) {
                background-size: auto 100%;
            }
        }
    }

    .kpis-container {
        margin-top: -220px;

        .Select-menu-outer {
            font-size: 13px;
        }
        h1 {
            color: $white;
            font-size: 25px;
            font-weight: 200;
        }

        .filter-bar {
            margin-bottom: 20px;
            padding: 10px 15px;
            background: rgba($white, 0.2);
            font-weight: 200;

            .title {
                font-size: 18px;
            }

            .date-filter {
                text-align: right;
                font-size: 12px;
                color: white;
                .start-date,
                .end-date {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 15px;

                    .name {
                        display: inline-block;
                        margin-right: 5px;
                    }

                    .date {
                        display: inline-block;
                    }
                }

                .icon {
                    display: inline-block;

                    img {
                        height: 20px;
                    }
                }
            }
        }

        .more-filters {
            height: 30px;

            .bt-open-more-filters {
                color: white;
                cursor: pointer;

                img {
                    margin-top: 55px;
                    height: 20px;
                }
            }

            .more-filters-container {
                position: relative;

                width: 200px;
                margin-top: 30px;
                padding: 15px;

                overflow-y: scroll;

                background: #fff;
                border-radius: 5px;
                box-shadow: rgba(0, 0, 0, 0.2) 0 0 20px;

                z-index: $layer15;

                @media (max-width: $mdMaxWidth) {
                    right: 150px;
                }

                &::after {
                    content: ' ';
                    position: absolute;
                    bottom: 100%;
                    left: 30px;
                    margin-left: -15px;
                    border-width: 15px;
                    border-style: solid;
                    border-color: transparent transparent white transparent;

                    @media (max-width: $mdMaxWidth) {
                        right: 10px;
                        left: unset;
                    }
                }

                .filters {
                    .filter {
                        &:not(:last-child) {
                            margin-bottom: 20px;
                        }

                        .label {
                            font-size: 13px;
                            font-weight: 600;
                            color: $gray;
                        }

                        .options {
                            margin-top: 5px;
                            padding: 5px;
                            font-size: 13px;
                            background: $smoke;

                            border-radius: 3px;

                            .option {
                                position: relative;
                                font-size: 13px;
                                font-weight: 300;

                                &:not(:last-child) {
                                    margin-bottom: 3px;
                                }

                                input[type='checkbox'] {
                                    position: absolute;
                                    display: block;
                                    top: 3px;
                                    left: 3px;

                                    margin-right: 5px;

                                    vertical-align: middle;

                                    cursor: pointer;
                                }

                                .text {
                                    display: block;
                                    margin: 0;
                                    padding: 0 0 0 20px;

                                    vertical-align: middle;

                                    cursor: pointer;
                                }
                            }

                            .Select-control {
                                height: auto;

                                .Select-multi-value-wrapper {
                                    width: 100%;

                                    .Select-value {
                                        padding: 3px;

                                        background: $lightBlue;
                                        border: none;

                                        font-size: 11px;
                                        color: #666;

                                        .Select-value-icon {
                                            border: none;
                                            margin-right: 2px;
                                            padding: 0 5px;
                                            border-radius: 50%;

                                            &:hover {
                                                background: #333;
                                                color: #fff;
                                            }
                                        }

                                        .Select-value-label {
                                            display: inline;
                                            padding: 0;
                                        }
                                    }

                                    .Select-input {
                                        display: block !important;
                                        margin-bottom: 5px;
                                        border-bottom: 1px solid #999;
                                        cursor: text;
                                    }
                                }

                                .Select-clear-zone {
                                    display: none;
                                }

                                .Select-arrow-zone {
                                    width: 10px;
                                    padding: 0 0 0 5px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .kpis-resume {
            margin-top: 45px;

            .kpi {
                background: $white;
                border-radius: 3px;
                padding: 25px 10px;
                color: $onyx;

                &.active {
                    .icon,
                    .number {
                        opacity: 1;
                    }
                }

                .icon {
                    text-align: right;
                    opacity: 0.4;

                    img {
                        height: 32px;
                        max-width: 100%;
                    }
                }

                .number {
                    font-size: 20px;
                    text-align: left;
                    opacity: 0.4;
                }

                @media (max-width: $mdMaxWidth) {
                    .icon {
                        text-align: center;
                        margin-bottom: 10px;
                    }

                    .number {
                        text-align: center;
                    }
                }
            }
        }

        .kpi-box {
            background: $white;
            border-radius: 5px;
            margin-bottom: 30px;
            padding: 20px;
            cursor: pointer;

            box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px;

            &:hover {
                box-shadow: rgba(0, 0, 0, 0.2) 0 0 20px;
            }

            .icon {
                text-align: center;
                margin: 20px 0 30px;

                img {
                    height: 32px;
                }
            }

            .chart {
                width: 100%;
                height: 90px;
                display: inline-flex;
                align-items: flex-end;
                padding: 3px;
                margin-bottom: 10px;

                .bar {
                    position: relative;
                    flex: 1;
                    background: $loblolly;
                    margin: 3px;
                    border-radius: 3px;
                    text-align: center;

                    &:hover {
                        background: $blue;

                        .number {
                            opacity: 1;
                        }
                    }

                    .number {
                        left: 0;
                        right: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 100%;
                        top: -50px;
                        position: absolute;
                        display: inline-block;
                        background: darken($blue, 20%);
                        border-radius: 3px;
                        min-width: 18px;
                        color: $white;
                        font-size: 11px;
                        font-weight: bold;
                        padding: 4px 4px 2px;

                        opacity: 0;
                        transition: opacity 0.2s;

                        &::after {
                            content: ' ';
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            margin-left: -5px;
                            border-width: 5px;
                            border-style: solid;
                            border-color: darken($blue, 20%) transparent transparent transparent;
                        }
                    }

                    .label {
                        position: absolute;
                        left: 0;
                        bottom: -15px;
                        right: 0;
                        color: $thunder;
                        text-transform: uppercase;
                        font-size: 7px;
                    }
                }
            }

            .title {
                color: $thunder;
                font-size: 20px;
                text-transform: capitalize;
            }

            .subtitle {
                position: relative;
                font-size: 15px;
                color: $gray;
                font-weight: 200;
                margin-top: -5px;

                .number {
                    color: $thunder;
                    font-size: 20px;
                    margin-right: 5px;
                    font-weight: 300;
                }

                .goto-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-size: 25px;
                }
            }
        }

        .results {
            margin-bottom: 40px;

            h2 {
                color: $onyx;
                font-size: 20px;
                padding: 10px 0;
            }
        }
    }

    .modal-kpi-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba($thunder, 0.65);
        z-index: $layer20;
    }

    .modal-kpi-container {
        position: fixed;
        width: 100%;
        max-width: 900px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background: $white;
        border-radius: 3px;

        z-index: $layer30;

        .header {
            h3 {
                font-size: 23px;
                font-weight: 200;
                padding: 20px 0 15px 30px;
            }

            .bt-close {
                display: inline-block;
                margin-right: 10px;
                cursor: pointer;

                &:hover {
                    color: $onyx;
                }

                i {
                    font-size: 40px;
                }
            }
        }
    }
    .react-datepicker__day--in-range {
        background-color: $datepicker__selected-color;
    }
    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
        background-color: rgba(157, 205, 195, 0.5);
        color: black;
    }
    .react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
        background-color: lightgray;
        color: white;
    }
}
