//Flash promotions
#section-promotions {
    header {
        background: $smoke;
        padding: 30px 15px;

        h2 {
            font-weight: 200;
            font-family: $titleFont;
            font-size: 30px;
            text-align: center;
            color: $thunder;
        }
    }

    .promotions {
        .slider {
            .col {
                padding: 0;

                .slick-slider {
                    .ec-slide {
                        cursor: pointer;
                    }

                    .slick-arrow {
                        z-index: 100;
                        width: auto;
                        opacity: 0.25;

                        &:hover {
                            opacity: 1;
                        }

                        &.slick-prev {
                            left: 10px;
                        }

                        &.slick-next {
                            right: 10px;
                        }
                    }
                }
            }
        }

        .promotion {
            .promotion-info {
                padding: 25px 0 35px;

                .promotion-title {
                    font-family: $titleFont;
                    font-size: 22px;
                    color: $thunder;
                    text-align: center;
                }

                .promotion-description {
                    margin-top: 5px;
                    padding: 0 30px;
                    font-size: 14px;
                    text-align: center;
                }

                .promotion-product-image {
                    margin-top: 20px;
                    text-align: center;

                    img {
                        width: 50%;
                    }
                }

                .promotion-actions {
                    margin-top: 20px;
                    text-align: center;

                    .bt {
                        margin-left: 15px;
                    }
                }
            }

            .promotion-main-image {
                min-height: 300px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }
        }
    }
}

.rt-tr {
    align-items: self-start !important;
}

//Promotions commerce
.promotion-card {
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 10px;

    div[class*='select_'] {
        text-transform: capitalize;
    }

    &.background-transparent {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        box-shadow: none;

        .card-header {
            background-color: transparent;
        }
    }

    .card-header {
        background-color: white;
        border-bottom: 0;
    }

    .card-body {
        font-size: 13px;

        .value {
            font-size: 14px;
        }
    }

    .card-footer {
        background: none;
        border: none;

        .bt {
            @media (max-width: $smMaxWidth) {
                width: 100%;
            }
        }
    }

    .promo-alert-message-container {
        i {
            font-size: 22px;
            margin-right: 1rem;
        }
    }

    @media (min-width: 1399px) {
        .form-group {
            .react-datepicker-wrapper {
                margin-right: 2rem;
            }
        }
    }

    @media (max-width: 1199px) {
        .form-group {
            .react-datepicker-wrapper {
                width: 100%;

                .react-datepicker__input-container {
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .reverse-flex {
            flex-direction: column-reverse;

            .promo-alert-message-container {
                padding-bottom: 10px;
            }
        }
        .header-step-promotion {
            font-size: 1.5rem !important;
        }
    }
}

.react-datepicker__close-icon {
    margin: 0 !important;
}

.promotion-button {
    background: #ffffff;
    border: 1px solid #1a1a1a;
    border-radius: 20px !important;
    color: #4d4d4d;
    cursor: pointer;
    font-size: 16px !important;
    font-weight: 300 !important;
    padding: 5px 20px;
    text-align: center;
    text-decoration: none !important;
    margin: 5px 15px;

    &.inverted {
        background: $onyx;
        color: $white;

        &:not(.bt-disabled) {
            &:hover {
                background: $thunder;
                border-color: $thunder;
            }

            &:active,
            &.active {
                background: $iron;
                border-color: $iron;
                color: $thunder;
            }
        }
    }
}

.promotion-i18n-row {
    border: 1px solid #777777;
    border-radius: 5px;
    padding: 5px 20px;
    margin: 5px;
}

//Promotions List
section.promotions-list {
    .ReactTable.light .rt-table .rt-tbody .rt-tr .rt-td:nth-child(n + 3) {
        padding: 0;
    }

    .ReactTable.light .rt-table .rt-thead.-header {
        border: 0;
        margin: 0;
        padding: 0;
        text-align: left;

        .rt-tr {
            text-align: left;
        }
    }

    .container-group-promo-btns {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        @media (max-width: $smMaxWidth) {
            flex-wrap: wrap;
        }
    }
}

//Start Promotion materials

//End Promotion materials

.banner-picker {
    .img-thumbnail {
        cursor: pointer;

        &--selected {
            background: #666;
        }
    }

    .wrapper_spinner {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(2px);
    }
}

.show-modal-stock {
    min-height: initial;

    #section-catalog {
        padding: 0;

        .filter-and-results-container {
            padding: 0;
        }
    }
}

.promotion__material-list {
    .sk-search-box {
        background: $white;
        border-radius: 15px;

        .sk-search-box__icon {
            position: relative;
            width: 100%;

            &:before {
                content: '\f002';
                font-family: 'Font Awesome 5 Pro';
                left: 12px;
                position: absolute;
                top: 6px;

                [dir='rtl'] & {
                    left: unset;
                    right: 12px;
                }
            }
        }

        .sk-search-box__text {
            width: 100%;
            border-radius: 6px;
            padding: 7px 15px 5px 40px;
            outline: none;
            border: 1px solid $primaryColor;

            [dir='rtl'] & {
                padding: 7px 40px 5px 15px;
            }
        }

        .sk-search-box__action {
            display: none;
        }
    }

    .bt-reset-searchbox {
        position: absolute;
        top: 8px;
        right: 10px;
        width: 21px;
        height: 21px;

        background: $gray;
        border-radius: 10px;

        cursor: pointer;

        &:before {
            position: absolute;
            top: 1px;
            width: 100%;

            font-family: 'Font Awesome 5 Pro';
            font-size: 14px;
            content: '\f00d';
            text-align: center;
            color: $white;
        }

        [dir='rtl'] & {
            right: unset;
            left: 10px;
        }
    }
}

//promotion text banners

.text_banner {
    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1.8em;
    }

    h3 {
        font-size: 1.6em;
    }

    h4 {
        font-size: 1.4em;
    }

    h5 {
        font-size: 1.2em;
    }

    h6 {
        font-size: 1em;
    }

    p {
        margin: 0;
    }

    table {
        width: 100%;
    }

    &--home {
        font-size: 1rem;
    }

    &--landing {
        font-size: 1rem;
    }

    &--filters {
        font-size: 0.8rem;
    }

    &--preview {
        font-size: 0.85rem;
    }
}
#section-promotion-admin {
    .Select {
        font-size: 14px !important;
    }

    td,
    th {
        text-align: start;
        padding: 8px;
    }

    td input {
        width: 20px;
        height: 20px;
        filter: grayscale(1);
    }

    tr {
        padding: 8px;
    }

    tbody {
        font-size: 15px !important;
    }

    tbody tr:nth-child(odd) {
        background-color: $lightWhite;
    }

    .search-bar {
        @media (max-width: $mdMaxWidth) {
            padding-bottom: 8px !important;
        }
    }

    .landings__modal--add-product {
        .results .results-list .list-result .product-image-container {
            height: 30px;
        }
        z-index: 10003 !important;
        .btn-row {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            & > * {
                margin: 8px;
            }
        }
    }
    .landings &__modal {
        .modal-body {
            display: block;
            padding: 30px 15px;
        }

        &--text {
            p {
                display: block;
                margin: 0;
            }
        }

        &--image {
            img {
                display: block;
                margin: 0 auto;
                width: auto;
                height: auto;
                max-width: 100%;
            }
        }

        &--add-product {
            text-align: center;
        }
    }

    .product-image {
        width: 64px;
        height: 64px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

section.resolve-discount {
    .approve_deny_button {
        width: 250px !important;
    }

    .discount-input-row {
        display: flex;
        padding-bottom: 1em;
        column-gap: 24px;
        align-items: end;
        border-bottom: 1px solid $iron;
        height: fit-content;
    }

    .discount-input {
        text-align: end;
        font-size: 1.2em;
        width: 9em;
        padding: 2px 6px 1px;
        border-radius: 6px;
        border: 3px solid $aluminum;
        height: 2em;
    }

    .bt {
        border-radius: 6px !important;
        background-color: $gray;
        color: white;
        border-color: $gray;
        min-width: 9em;
    }

    .selector {
        font-weight: 500;
        text-align: center;
    }

    .selected {
        color: #414bb2;
        border-bottom: 2px solid #414bb2;
        cursor: default;
    }

    .not-selected {
        color: #4d4d4d;
        border-bottom: none;
        cursor: pointer;
    }

    .ReactTable.light {
        height: 100% !important;

        .rt-th {
            font-weight: bold;
            //text-align: start;
        }

        .rt-tr {
            align-items: center !important;
            .rt-td {
                text-align: center;
                padding: 5px !important;
            }
        }

        .rt-thead {
            flex-grow: 0 !important;
        }
    }

    //.response-modal {
    //    top:25%!important;
    //    .modal-header {
    //        border: none!important;
    //    }
    //    .modal-content {
    //        width: 90%;
    //        margin: auto;
    //    }
    //}

    .discount-materials {
        .rt-td {
            position: relative;
        }

        .outlet-indicator {
            top: 0px;
            left: 25%;
            font-size: 10px;
            font-weight: 500;
            padding: 2px 4px 1px;
        }
    }
}

.timer-promo {
    display: flex;
    column-gap: 12px;
    align-items: center;
    color: $primaryColor;

    @media (max-width: 991px) {
        flex-direction: column;
    }

    @media (max-width: 767px) {
        align-items: baseline;
    }

    .time-remaining {
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 400;
    }
}
.promotion-progress-bar {
    background-color: #979797;
    height: 6px;
    width: 100%;
    border-radius: 13px;

    span {
        display: block;
        height: 100%;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 20px;
        position: relative;
        overflow: hidden;
    }
}
/* .promotion-progress-bar::before {
    content: '';
    background-color: red;
    width: 50%;
} */

.request-discount-question {
    .rdq-main-message {
        padding: 0.5rem 0;
        font-size: 1.15rem;
        font-weight: 400;

        @media (max-width: 767px) {
            font-size: 0.95rem;
            padding: 0 14px;
        }
    }

    .rdq-question {
        font-size: 1rem;
        font-weight: 400;

        @media (max-width: 767px) {
            font-size: 0.9rem;
            padding: 0 14px;
        }
    }

    .buttons-row-rdq {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    }
}
