.rectangle-result {
    display: flex;
    flex-direction: column;
    margin: 20px 27px 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    width: 220px;
    .estimated_date {
        font-size: 12px;
    }
    &:not([type='TABLA']) {
        .product-image-container {
            .product-image {
                background-size: contain;
            }
        }
    }
    /* .outlet-indicator {
        background: #d66f00;
        border-radius: 2px;
        box-shadow: rgba($onyx, 0.3) 1px 1px 3px;
        color: $white;
        font-size: 11px;
        font-weight: bold;
        left: -16px;
        padding: 3px 5px 0;
        position: absolute;
        text-transform: uppercase;
        top: -2px;
        z-index: 1;
    } */
    /* .sales-indicator {
        background: #ffd972;
        border-radius: 4px;
        box-shadow: none; //rgba($onyx, 0.3) 1px 1px 3px;
        color: #232323;
        font-size: 14px;
        font-weight: bold;
        left: -20px;
        padding: 4px 12px 2px;
        position: absolute;
        text-transform: uppercase;
        top: -10px;
        z-index: 1;
    } */
    /* .discontinued-indicator {
        background: $discontinuedIndicatorBg;
        border-radius: 2px;
        box-shadow: rgba($onyx, 0.3) 1px 1px 3px;
        color: $white;
        font-size: 11px;
        font-weight: bold;
        left: -16px;
        padding: 3px 5px 0;
        position: absolute;
        text-transform: uppercase;
        z-index: 1;
    } */
    /* .technology-indicator {
        background: whitesmoke;
        border-radius: 2px;
        box-shadow: rgba($onyx, 0.3) 1px 1px 3px 1px;
        color: $white;
        display: flex;
        font-size: 11px;
        font-weight: bold;
        height: 25px;
        justify-content: center;
        left: -5px;
        padding: 2px;
        position: absolute;
        text-transform: uppercase;
        top: 140px;
        width: 50px;
        z-index: 1;
        &.plus {
            &:after {
                color: black;
                content: '+';
                font-size: 14px;
                font-weight: 200;
                line-height: 14px;
                margin-left: 2px;
            }
        }
        > img {
            width: 80%;
        }
        &.hybriq {
            background-color: white;
            width: 80px;
            align-items: center;
        }
    } */

    /* .promo-indicator {
        background: $promoIndicatorBg;
        border-radius: 2px;
        box-shadow: rgba($onyx, 0.3) 1px 1px 3px;
        color: $white;
        font-size: 11px;
        font-weight: bold;
        padding: 3px 5px 0;
        position: absolute;
        right: -20px;
        text-transform: uppercase;
        top: -10px;
        z-index: 1;
    } */

    .wrapper-product-image {
        position: relative;
    }
    .product-image-container {
        height: 160px;
        position: relative;

        width: 220px;

        .product-image {
            background-position: center;

            background-repeat: no-repeat;
            background-size: cover;
            height: 160px;
            // left: -1px;
            position: absolute;
            position: relative;
            // top: -1px;
            width: 220px;
            [dir='rtl'] & {
                left: 1px;
            }
        }
    }
    .product-info {
        flex-grow: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        & > div {
            margin: auto;
        }
        .product-brand {
            color: $gray;
            font-size: 11px;
            font-weight: 200;
            margin-top: 10px;
            text-transform: capitalize;
        }

        .product-color-name {
            color: $thunder;
            font-family: $titleFont;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;

            hyphens: auto;
            line-height: 20px;
            text-transform: capitalize;
            -ms-word-break: break-all;

            word-break: break-all;

            word-break: break-word;
            flex-grow: 1;
        }

        .product-info-table {
            border-bottom: 1px solid $mercury;
            display: inline-block;
            padding: 10px 0 5px;
            width: 80%;

            table {
                width: 100%;

                thead {
                    tr {
                        th {
                            color: $gray;
                            font-size: 12px;
                            font-weight: 200;

                            &:not(:last-child) {
                                border-right: 1px solid $smoke;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            color: $thunder;
                            font-size: 12px;
                            font-weight: 300;
                        }
                    }
                }
            }
        }

        .product-info-details {
            .thickness {
                display: inline-block;

                .icon {
                    border-left: 0.5px solid $onyx;
                    border-right: 0.5px solid $onyx;
                    display: inline-block;
                    height: 10px;
                    line-height: 10px;
                    width: 15px;

                    div {
                        background: $onyx;
                        display: inline-block;
                        vertical-align: middle;
                        width: 100%;
                    }
                }

                .text {
                    display: inline-block;
                    font-size: 12px;
                    margin-left: 5px;
                    [dir='rtl'] & {
                        margin-left: unset;
                        margin-right: 5px;
                    }
                }
            }

            .finish {
                display: inline-block;
                margin-left: 4px;
                margin-right: 4px;

                .icon {
                    display: inline-block;
                    vertical-align: middle;

                    img {
                        height: 15px;
                    }
                }

                .text {
                    display: inline-block;
                    font-size: 12px;
                    margin-left: 2.5px;
                    margin-right: 2.5px;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                    white-space: nowrap;
                    text-transform: initial !important;
                }
            }
            .product_detail {
                display: inline-block;
                margin-left: 4px;
                margin-right: 4px;

                .icon {
                    display: inline-block;
                    vertical-align: middle;

                    img {
                        height: 15px;
                    }
                }

                .text {
                    display: inline-block;
                    font-size: 12px;
                    margin-left: 2.5px;
                    margin-right: 2.5px;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                    white-space: nowrap;
                    text-transform: capitalize;
                }
            }
            .price {
                width: 100%;
                span {
                    font-weight: 500;
                    margin: 5px 10px;
                    font-size: 1.1em;
                }

                .product_detail {
                    padding: 5px 0;
                }
            }
        }
    }

    .product-actions {
        display: flex;
        margin-top: 10px;
        align-items: center;

        .input-row {
            flex-grow: 1;

            .icon {
                display: inline-block;

                img {
                    height: 15px;
                }
            }
            max-width: 50%;
        }

        .bt-row {
            flex-grow: 1;
            padding-top: 2px;
            max-width: 50%;
        }
    }

    .measure-unit {
        .text {
            text-transform: none!important;
        }
    }
}

.single-rectangle-result {
    font-size: 12px;
    margin-bottom: 1rem;
    // .wrapper-product-image {
    //     width: 200px;
    //     height: 150px;
    // }

    .product-brand {
        font-size: 0.9em !important;
    }

    .product-image-container {
        // height: 75%;
    }
    .product-image {
        //background-size: cover;
    }
    .technology-indicator {
        background: whitesmoke;
        border-radius: 2px;
        box-shadow: rgba($onyx, 0.3) 1px 1px 3px 1px;
        color: $white;
        display: flex;
        font-size: 11px;
        font-weight: bold;
        height: 25px;
        justify-content: center;
        bottom: 0;
        top: auto;
        padding: 2px;
        position: absolute;
        text-transform: uppercase;
        width: 50px;
        z-index: 1;
        &.plus {
            &:after {
                color: black;
                content: '+';
                font-size: 14px;
                font-weight: 200;
                line-height: 14px;
                margin-left: 2px;
            }
        }
        > img {
            width: 80%;
        }
        &.hybriq {
            background-color: white;
            width: 80px;
            align-items: center;
        }
    }
}

.rectangle-result.v2 {
    .product-info {
        .product-info-details__content {
            flex-grow: 1;
            margin: 10px 0;
        }
    }
}

.v2 {
    .recently-view {
        .rectangle-result:not(.mall-promo) {
            min-height: 450px;
            width: 250px;
            @media (min-width: 768px) {
                min-height: 470px;
            }
                .product-image-container, .product-image {
                width: 250px;
            }
        }
    }
    
    .rectangle-result {
        background: white;
        border-radius: 0;
        width: 280px;
        margin: 8px;

        .technology-indicator {
            bottom: 0;
            left: 0;
            top: auto;
        }

        .product-info {
            padding: 0px 20px 0px;
            padding-bottom: 15px;

            .product-brand {
                color: $primaryColor;
                font-size: 0.750rem;
                font-weight: 300;
            }

            .product-color-name {
                color: $primaryColor;
                font-size: 1.375rem;
                padding: 2px 0 5px 0;
            }
        }

        .product-actions {
            padding: 0px 20px 0px;
            padding-bottom: 15px;
            margin-top: 0px;

            .input-row {
                margin-right: 0.5rem;

                .input-number {
                    max-width: 100%;
                    color: #232323;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 3px;

                    .input-container {
                        height: 30px;
                        width: 34px;

                        .inner-input-number {
                            border: 1px solid $primaryColor;
                            background-color: $white;
                        }
                    }
                }
            }
        }

        .product-image-container,
        .product-image {
            width: 280px;
        }

        .product-info-details {
            line-height: 18px;

            .productId {
                .text {
                    color: $primaryColor;
                    .product-detail__value {
                        font-weight: 300;
                        font-size: 0.750rem;
                    }
                }
            }

            .format, .subtype, .thickness, .finish  {

                .text {
                    color: $primaryColor;
                    font-weight: 300;
                    font-size: 0.875rem;
                }
                
            }

            .product-detail__value {
                color: $thunder;
                font-size: 0.875rem;
                font-weight: 400;
            }
        }

        .stock {
            font-weight: 400;
            .length {
                color: #A1A1A1;
                font-size: 10px;
            }
        }

        .product_detail.subtype {
            margin-left: 0px;
        }

        .product_detail.format {
            margin-right: 0px;
        }

        .product-color-name{
            flex-grow:initial;
        }
    }

    .mall-promotion-btn {
        min-width: 260px;
        border: none;
        padding: 16px;            
        border: solid 1px $primaryColor;
        background: none;
        font-weight: 600;
        font-size: 12px;

        &:hover {
            background-color: $primaryColor;
            color: #dbe4e9;
        }
    }
}

.view-more-promo-btn{
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    border: 1px solid #232323;
    //padding: .5em 2em !important;
    font-size: 12px;    
    font-weight: 600 !important;
    //min-width: 7rem;
    //width: 6.3rem;
}

/* .view-more-promo-btn.cart{
    padding: .3em !important;
}
 */
.product-actions.mall {
    justify-content: center;
    gap: 1em;
}
.promo-action-btns{
    button{
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;

        border: 1px solid #232323;
        padding: .5em 2em;
        font-size: 12px;
        margin: .7em;
        font-weight: bold;
        min-width: 6.3rem;
        width: 6.3rem;
    }
    button:last-child{
        background-color: #232323;
        color: white;
    }
}