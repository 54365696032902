.product-info-tabs__container {
    @media (max-width: 1023px) {
        margin: 2rem 0 2rem 0;
    }
    margin: 1rem 0 1rem 0;
    background-color: $white;
    border-radius: 0;
    
    .nav::-webkit-scrollbar {
        padding-top: 0.5rem;
        height: 0.2rem;
    }

    .nav::-webkit-scrollbar-track {
        box-shadow: none;
      }

    .nav::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    .product-info-tabs__heading {
        padding: 1rem 1.5rem 0 1.5rem;
        border-bottom: 1px solid $primaryColorDisable;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .nav {
            overflow: auto;
            white-space: nowrap;
            display: inline-block;

            .nav-item {
                font-size: 1rem;
                font-weight: 500;
                color: #A9A9A9;
                display: inline-block;
    
                &:hover {
                    color: #797979;
                }
                
                .nav-link.active  {
                    color: $primaryColor;
                    border-bottom: 4px solid $primaryColor;
                }
            }     
        }
    }

    .open-close-info {
        background-color: transparent;
        border: 0;
        border-radius: 100%;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: 0;

        &:hover {
            background-color: $primaryColorHover;
        }    
    }

    .rotate-icon {
        font-size: 1.385rem;
    }

    .open-close-info:not(.collapsed) .rotate-icon {
        transform: rotate(180deg);
    }

    .product-info-tabs__content {

        .tab-content {
            padding: 1.2rem 3rem 2rem 3rem;
            color: $thunder;

            .tab-pane {
                text-align: left;
            }

            .product-info-tabs__title {
                font-size: 0.875rem;
                font-weight: 400;
            }

            .product-info-tabs__text {
                font-size: 0.875rem;
                font-weight: 400;
            }

            .product-info-tabs__box {
                padding-top: 1rem;

                .product-info-tabs__cards {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-direction: column;
                    min-height: 140px;

                    .product-info-tabs__img {
                        padding-bottom: 1rem;
                        i {
                            font-size: 40px;
                        }
                    }
    
                    .product-info-tabs__img-text {
                        color: $thunder;
                        font-size: 0.775rem;
                        font-weight: 500;
                        text-align: center;
                    }
                }
            }
        }
    }
    
    @media (max-width: 768px) {
        .product-info-tabs__heading {
            padding: 1rem 0.7rem 0 0.5rem;

            .nav-item {
                font-weight: 400;

                .nav-link {
                    font-size: 0.750rem;
                }
            }
        }

        .product-info-tabs__title {
            font-size: 0.750rem;
        }

        .product-info-tabs__text {
            font-size: 0.750rem;
        }

        .open-close-info {
            display: none;
        }

        .product-info-tabs__content .tab-content {
            padding: 1.2rem 1rem 1.2rem 1rem;
        }
    }
}