/*
 * Specific styles of signin component
 */

/*
 * General styles
 */
/*Spinner*/
.sk-cube-grid {
    width: 40px;
    height: 40px;
    position: relative;
    top: calc(50% - 80px);
    margin: 0 auto;
}

.spinner-inner-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.spinner-relative {
    top: unset;
}

.spinner-small {
    width: 20px;
    height: 20px;
    top: 5px;
}

.spinner-inner {
    top: calc(50% - 3px);
    position: absolute;
    width: 100%;
}

.sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #333;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    background-color: #fff !important;
}

.sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    background-color: $smoke !important;
}

.sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    background-color: $iron !important;
}

.sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
    background-color: $smoke !important;
}

.sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    background-color: $iron !important;
}

.sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    background-color: #666 !important;
}

.sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    background-color: $iron !important;
}

.sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
    background-color: #666 !important;
}

.sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
    0%,
    70%,
    100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }
    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}

@keyframes sk-cubeGridScaleDelay {
    0%,
    70%,
    100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }
    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}

.modal-ask-for-email {
    top: 10% !important;
    max-width: 568px !important;
    background: #d9dedf !important;

    .card-container {
        background: #d9dedf !important;
    }

    h3 {
        color: #333 !important;
    }

    label {
        color: #333 !important;
    }
}

/*Spinner*/
.modal-ask-for-email {
    top: 10%;
    max-width: 568px;
    background: #d9dedf;

    .card-container {
        background: #d9dedf;
    }

    h3,
    label {
        color: #333;
    }

    #profile-img {
        top: 0;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    input[type='email'],
    input[type='password'],
    input[type='text'],
    button {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 20px;
    }
}



#modal-login-container.modal-ask-for-email,
#modal-login-container.modal-newsletter {
    @media (max-width: $mdMaxWidth) {
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        overflow-x: none;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
    @media (max-height: 700px) and (min-width: $mdMaxWidth) {
        top: unset;
        bottom: 10%;
    }

    .logo-container {
        height: calc(100vh - 300px);
        max-height: 300px;
        background-size: cover;
        background-position: center;
    }

    top: 10%;
    max-width: 568px;
    background: #d9dedf;

    .card-container {
        background: #d9dedf;
    }

    h3 {
        color: #333;
    }

    label {
        color: #333;
    }

    #profile-img {
        top: 0;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    input[type='email'],
    input[type='password'],
    input[type='text'],
    button {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 20px;
    }

    input[type='email'],
    input[type='password'],
    input[type='text'] {
        margin-right: 8px;
    }
}

#modal-login-container {
    position: fixed;
    top: 15%;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;
    max-width: 950px;

    z-index: $layer50;
    @media(min-width: $mdMaxWidth)and(max-width:$lgMaxWidth){
        top: 5%;
    }
    @media (max-width: $mdMaxWidth) {
        background: $lightWhite;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        overflow-x: none;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
}

.modal-login {
    label,
    .forgot-password a,
    .forgot-password span {
        // color: $white;
    }

    header {
        padding: 16px;
        .bt-close {
            display: inline-block;
            cursor: pointer;

            &:hover {
                color: $onyx;
            }

            i {
                font-size: 40px;
            }
        }
    }

    footer {
        padding: 20px;
    }

    #profile-img-fixed {
        width: 50%;
        margin-top: 2%;
    }

    .logo-container {
        background: $white;

        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        [dir='rtl'] & {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    .card-container {
        background: $lightWhite;

        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        @media (max-width: $mdMaxWidth) {
            border-radius: 0;
        }

        [dir='rtl'] & {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }

    .form-signin {
        width: 80%;
        margin: 0 auto;
    }

    .links {
        a,
        span {
            cursor: pointer;
            // color: white;
        }
    }
}

.container-login {
    background: url('../img/backgrounds/dekton-kitchen-nilium.jpg');
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.container-layer {
    background: rgba(0, 0, 0, 0.5);
    padding-top: 60px;
    padding-bottom: 60px;
    width: 100%;
    height: 100%;
}

.btn {
    font-weight: 200;
    height: 36px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
}

/*
 * Card component
 */
// .card {
//     max-width: 530px;
//     width: 80%;
//     background-color: white;
//     border: none;
//     box-shadow: none;
//     /* just in case there no content*/
//     padding: 20px 80px 30px;
//     margin: 0 auto 25px;
//     margin-top: 50px;
//     /* shadows and rounded borders */
//     -moz-border-radius: 2px;
//     -webkit-border-radius: 2px;
//     -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
//     -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
//     border-radius: 0.35rem;

//     @media (max-width: $xsMaxWidth) {
//         padding: 20px 20px 30px;
//     }
// }

.profile-img-card {
    width: 240px;
    margin: 0 auto 20px;
    padding-top: 30px;
    display: block;
}

/*
 * Form styles
 */
.profile-name-card {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0 0;
    min-height: 1em;
}

.remember-me {
    text-align: left;
    // color: $thunder;
}

.forgot-password {
    // color: $thunder;
    text-align: right;

    a,
    span {
        // color: $thunder;
    }

    .forgot-password:hover,
    .forgot-password:active,
    .forgot-password:focus {
        color: rgb(12, 97, 33);
    }
}

.form-signin {
    #inputEmail,
    #inputPassword {
        direction: ltr;
        height: 44px;
        font-size: 16px;
    }

    input[type='email'],
    input[type='password'],
    input[type='text'],
    button {
        width: 100%;
        display: block;
        margin-bottom: 10px;
        position: relative;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: $headingBorderRadius;
    }

    .form-control {
        font-weight: 500;
    }

    input.form-control {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
    }

    .form-control:focus {
        border-color: rgb(60, 60, 60);
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(60, 60, 60);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(60, 60, 60);
    }

    .loginbutton {
        width: 100%;
        margin-bottom: 10px;
    }

    .btn {
        background-color: black;
        padding: 0px;
        font-weight: 200;
        font-size: 14px;
        height: 36px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border: none;
        -o-transition: all 0.218s;
        -moz-transition: all 0.218s;
        -webkit-transition: all 0.218s;
        transition: all 0.218s;
        border-radius: $headingBorderRadius;

        .btn.btn-signin:hover,
        .btn.btn-signin:active,
        .btn.btn-signin:focus {
            background-color: rgb(12, 97, 33);
        }
    }
}

.sso .bt {
    padding: 10px;
    margin-top: 20px;
}

.azure-login{
    // background:;
}