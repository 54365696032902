.input-number {
    color: $primaryColor;
    max-width: 100px;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    
    &.small {
        .input-container {
            height: 24px;
            padding: 0;
            input {
                padding: 0;
                height: 24px;
            }
        }
        .spin {
            padding: 0;
            height: 24px;
        }
    }
    .input-container {
        display: inline-block;

        input {
            -moz-appearance: textfield;
            background: $smoke;
            border: none;
            border-radius: 0;
            font-size: 14px;
            font-weight: 300;
            height: 30px;
            padding: 6px 2px;
            text-align: center;
            width: 100%;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &:focus {
                outline: none;
            }
        }
    }

    .spin {
        background: $smoke;
        cursor: pointer;
        font-size: 10px;
        height: 30px;
        line-height: 25px;
        padding: 3px 0 3px;
        position: absolute;
        width: 34px;

        &:hover {
            background: $mercury;
        }

        &:active,
        &.active {
            background: $iron;
        }

        &.minus {
            border-right: 2px solid #fff;
            bottom: 0;
            left: 0;
            top: 0;
        }

        &.plus {
            border-left: 2px solid #fff;
            bottom: 0;
            right: 0;
            top: 0;
        }
    }
}

.input-number.v2 {
    .spin.minus, .spin.plus {
        position: relative;
        background-color: white;
        border: 1px solid $primaryColor;
    }
    .spin.minus:hover {
        background-color: #DBE4E9;

    }
    .spin.plus:hover {
        background-color: #DBE4E9;

    }
    input:hover {
        background-color: #DBE4E9;

    }

    > * {
        font-size: 12px;
    }
    .input-container{
        .inner-input-number{
            font-weight: 400;
        }
    }
}
