#section-profile {
    background: $smoke;
    min-height: calc(100vh - 300px);

    .btn-group-vertical {
        width: 100%;
    }

    .profile-menu {
        background: $white;
        margin: 20px 0;
        border-radius: 3px;

        ul {
            list-style: none;
            text-align: left;

            li {
                padding: 2px 5px;

                i {
                    margin-right: 5px;
                }
            }
        }

        .user-box {
            padding: 20px 0;
            word-break: break-all;

            .user-image {
                width: 50%;
                margin: 0 auto;
                position: relative;

                img {
                    display: inline-block;
                    width: 100%;
                    opacity: 0;
                }

                .frame {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;

                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }

            .user-info {
                padding: 10px 20px 0;
                text-align: center;
                font-size: 14px;

                div.label {
                    display: block;
                    margin-bottom: -3px;

                    font-size: 12px;
                    color: $gray;
                }

                div.value {
                    font-weight: 400;
                }

                .user-fullname {
                    font-size: 16px;
                    font-weight: 400;
                }

                .user-username {
                    font-size: 12px;
                }

                .user-email {
                    font-size: 12px;
                }

                .user-menu {
                    text-align: left;

                    span {
                        padding-left: 15px;
                    }
                }

                .user-default-center {
                    margin-top: 10px;
                }

                .user-units {
                    margin-top: 10px;

                    .unit {
                        margin-right: 5px;
                        padding: 2px 5px;
                        border-radius: 4px;

                        font-size: 12px;
                        color: $white;

                        &:nth-child(1) {
                            background: $blue;
                        }

                        &:nth-child(2) {
                            background: $green;
                        }
                    }
                }
            }
        }
    }

    .btn-group-vertical {
        width: 100%;
    }

    .profile-form {
        margin: 20px 0;
        border-radius: 3px;

        h2 {
            padding: 15px 20px;
            font-size: 19px;
            border-bottom: 2px solid $onyx;
        }

        .form {
            padding: 10px 20px 20px;

            h3 {
                font-size: 16px;
                font-weight: 400;
            }

            label {
                font-size: 13px;
                margin-bottom: 4px;
            }
        }
    }

    a.btn {
        cursor: pointer;
    }

    .toolbar {
        padding: 15px;

        .container {
            .row {
                border-bottom: 2px solid black;
            }

            margin-bottom: 10px;
        }

        .select-center {
            margin-bottom: 10px;
        }
    }

    .modal-body {
        max-height: calc(90vh - 100px);
        overflow-y: auto;
    }

    #section-products-favourite {
        text-align: center;
        padding: 50px 0px;
    }

    .change-password-form {
        form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            input {
                margin: 5px 0;
            }
        }
    }

    .result_products_favourite {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 1.2rem 0.6rem;
        gap: 1.2rem;

        .rectangle-result-v2 {
            border: 1px solid #eaeaea;
        }   
    }
}

.table-recurring-orders {
    .rt-tbody,
    .rt-thead {
        min-width: 290px !important;
    }
}

.profile-nav-bar {
    .without-buttons {
        a {
            margin: 0 5px;

            &.active {
                font-weight: bold;
            }
        }
    }
}
