.edit_order {
    .toolbar {
        .order_header {
            display: flex;
            .group_title {
                flex-grow: 1;
            }
            .group_buttons {
                flex-grow: unset;
                & > * {
                    margin: 5px;
                }
            }
        }
    }
    .ReactTable {
        img {
            height: 60px;
            max-height: 100%;
            max-width: 100%;
            width: 60px;
        }
        .rt-tr {
            height: 90px;
            transition: 0.5s;
            &.edited {
                border: 1px solid lightgray;
            }
            &.reject {
                height: 35px;
                overflow: hidden;
                position: relative;
                &:after {
                    background-color: rgba(150, 150, 150, 0.2);
                    content: '';
                    height: 100%;
                    position: absolute;
                    width: 100%;
                }
            }
        }
        .rt-td {
            align-self: center;
            &:last-child {
                cursor: pointer;
                text-align: center;
                z-index: 10;
            }
        }
    }
}
