.bt,
.sk-no-hits__step-action {
    display:flex;
    justify-content: center;
    align-items: center;
    background: $white;
    border: 1px solid $onyx;
    color: $primaryColor;

    cursor: pointer;
    font-size: 14px !important;
    font-weight: 400;
    padding: 6px 20px;
    text-align: center;
    text-decoration: none !important;

    .icon {
        display: none;
        height: auto;
        margin-right: 6px;
        &.visible {
            display: inline-block;
        }
        [dir='rtl'] & {
            margin-right: unset;
            margin-left: 6px;
        }
    }

    .custom-icon {
        height: 20px;
        margin-left: 6px;
        [dir='rtl'] & {
            margin-right: unset;
            margin-left: 6px;
        }
    }

    &.bt-disabled {
        cursor: not-allowed;
        opacity: 0.35;
    }

    &:not(.bt-disabled) {
        &:hover {
            background: $thunder;
            border-color: $thunder;
            color: $white;
        }

        &:active,
        &.active {
            background: $onyx;
            border-color: $onyx;
            color: $white;
        }
    }

    &.bt-inline {
        display: inline-block;
    }

    &.bt-noborder {
        border: 0;
    }

    &.bt-inverted,
    &.sk-no-hits__step-action {
        background: $onyx;
        color: $white;

        &:not(.bt-disabled) {
            &:hover {
                background: $thunder;
                border-color: $thunder;
            }

            &:active,
            &.active {
                background: $iron;
                border-color: $iron;
                color: $thunder;
            }
        }

        &.bt-disabled {
            background: $primaryColorDisable;
            border-color: $primaryColorDisable;
            color: $white;
            opacity: 1;
        }
    }

    &.bt-gentle {
        background: $thunder;
        border-color: $thunder;
        color: $white;

        &:not(.bt-disabled) {
            &:hover {
                background: $iron;
                border-color: $iron;
                color: $onyx;
            }

            &:active,
            &.active {
                background: $smoke;
                color: $thunder;
            }
        }
    }

    &.bt-dark {
        background: $dark;
        color: $white;

        &:hover {
            background: $kunta;
        }

        &.gt {
            padding: 0;
            border-radius: 0.25rem !important;
            height: 48px;
            line-height: 48px;
        }

        &.xt-pd {
            padding: 0 20px;
        }

        &.no-bo {
            background: $newOnyx;
            border-radius: 0.12rem !important;

            &:hover {
                background: $kinte;
            }
        }
    }

    &.bt-preorder {
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 0;
        font-weight: 500!important;
        font-size: 12px;
        .icon {
            font-size: 18px;
            line-height: 1;
        }
    }

    &.bt-cts {
        background: white;
        color: $onyx;
        font-weight: 400 !important;
        padding: 0.5em 2.6em 0.5em 2.6em !important;

        &:hover {
            color: white;
        }
    }

    &.bt-cts-inverted {
        background: $onyx;
        color: white;
        font-weight: 400 !important;
        padding: 0.5em 2.6em 0.5em 2.6em !important;
        width: fit-content !important;

        &:hover {
            background-color: $iron;
            color: $onyx;
        }
    }

    &.bt-we {
        background: $dark !important;
        border-color: $gray;
        color: $white;
        box-shadow: 0 0.05rem 0.15rem 0.1rem $shadow !important;

        &:hover {
            background: $kinte;
        }
    }

    &.bt-danger {
        border-color: #df0000;
        color: #df0000;

        &:not(.bt-disabled) {
            &:hover {
                background: #df0000;
                border-color: #df0000;
                color: white;
            }

            &:active,
            &.active {
                border-color: #b10000;
                background: darken(#b10000, 0.7);
                color: white;
            }
        }
    }

    &.bt-buy-lot {
        height: 56px;
        display: flex;
        align-items: center;
        font-size: 18px !important;
        padding: 6px 20px !important;
    }

    &.bt-size-large {
        font-size: 18px !important;
        padding: 4px 15px;

        .icon,
        .custom-icon {
            height: 15px;
        }
    }

    &.bt-size-medium {
        font-size: 14px !important;
        padding: 4px 15px;

        .icon,
        .custom-icon {
            height: 15px;
        }
    }

    &.bt-size-small {
        font-size: 12px !important;
        padding: 2px 10px;

        .icon,
        .custom-icon {
            height: 12px;
        }
    }

    &.bt-size-tiny {
        font-size: 10px !important;
        padding: 1px 8px;

        .icon,
        .custom-icon {
            height: 10px;
        }
    }

    &.bt-offers {
        background: transparent;
        border: 1px solid $iron;
        color: $thunder;
        font-size: 12px !important;
        font-weight: 400 !important;
        padding: 6px 15px !important;

        &:not(.bt-disabled) {
            &:hover {
                background: $thunder;
                border-color: $gray;
                color: $white !important;
            }

            &:active,
            &.active {
                background: $smoke;
                color: $thunder !important;
            }
        }
    }

    &.bt-hidden {
        display: none !important;
    }

    &.bt-login {
        font-size: 15px !important;
        font-weight: 400 !important;
        letter-spacing: 0.05rem;
        border: none;
        height: 44px;
        border-radius: 0.25rem;
        background: $aluminum;
        color: white;
    }

    &.bt-preorder {
        color: #232323;
        font-size: 0.86em !important;
        font-weight: 500 !important;
        border-color: #232323;
        border-radius: 2px;
        line-height: 1.92em;
    }

    &.only-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            margin: 0;
        }
    }
    &--thin {
        height: 26px;
    }
}
.sk-no-hits__step-action {
    width: fit-content;
    margin: 0 auto;
    margin-top: 10px;
}
.input-group-append {
    .btn {
        height: 100%;
    }
}
.v2 {
    .rectangle-result .product-actions .bt-row {
        padding-top: 0px;
    }
    .bt {
        border-radius: 0;
        //TODO This might change more styles
        &:not(.bt-disabled) {
            &:hover {
                background-color: $primaryColorHover;
                color: #232323;
                border-color: $primaryColorHover;
            }
        }
    }
    .btn {
        border-radius: 0;
        font-family: Roboto;
        font-weight: 500;
        font-size: 15px;
        color: black;
    }
    .btn-outline-secondary:hover {
        border-color: #dbe4e9;
        background-color: transparent;
    }
    .bt-inverted {
        border: none;
        &:not(.bt-disabled) {
            &:hover {
                background-color: $primaryColorHover;
                color: #232323;
            }
            &:active {
                color: white;
            }
        }
    }
    .bt-transparent {
        background-color: transparent;
        border: none;
    }
    .bt.bt-size-large {
        font-family: 'Roboto';
        font-size: 14px !important;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bt-new-style {
        font-weight: 500 !important;
        padding: 16px 24px;
        font-family: 'Roboto';
        font-size: 14px !important;
        color: black;
        background-color: transparent;
    }
    .bt-new-style {
        font-weight: 500 !important;
        padding: 16px 24px;
        font-family: 'Roboto';
        font-size: 14px !important;
        color: black;
        background-color: transparent;
    }
}
.bt-add-to-cart {
    .bt.noselect.bt-inverted.bt-add-to-cart-product-v2 {
        padding: 0;
        font-size: 12px !important;
        font-weight: 500 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
