#home,
#product-detail {
    .filter-and-results-container {
        padding: 0px;
    }

    .small-devices-text {
        text-align: center;
        padding: 10px 20px;
        margin: 20px;
    }

    .image-slide {
        position: relative;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center;
        background-size: cover;
        height: 250px;
        color: white;

        &.left {
            height: 500px;
        }

        &.custom-move {
            @media (max-width:768px) {
                background-position: 20% 50%;
            }
        } 
        
        &.banner-home {
            background-size: contain;
            background-position: bottom;
            @media (max-width: $xsMaxWidth) {
                height: 340px;
                background-size: cover;
            }
            @media (min-width: $mdMaxWidth) {
                background-size: cover;
            }
            @media (max-width: $mdMaxWidth) and (min-width: $xsMaxWidth) {
                max-width: 100%;
                background-size: cover;
            }
            .slide-title {
                color: black;
                .title {
                    line-height: 40px;
                    max-width: 100%;
                    font-size: 30px !important;
                    @media (min-width: $mdMaxWidth) {
                        max-width: 80%;
                    }
                    @media (max-width: $mdMaxWidth) and (min-width: $xsMaxWidth) {
                        max-width: 100%;
                        font-size: 22px !important;
                    }
                }
            }
        }

        &:not(.left) {
            .slide-title {
                .title {
                    line-height: 22px;
                }
                .virtual_visit_subtitle {
                    word-spacing: 100vw;
                    line-height: 22px;
                }
            }
        }

        .slide-title-box-one {
            position: absolute;
            @media(min-width: 992px) {
                top: 52%;
            }
            @media(max-width: 992px) {
                top: 50%;
            }

            @media(max-width: 768px) {
                top: 32%;
            }
            left: 30px;
            right: 30px;

            .title {
                font-family: $titleFont;
                font-size: 2.35rem !important;
                font-weight: 700;
                color: $white;
                margin-bottom: 1rem;

                @media(max-width: 768px) {
                    font-size: 2rem !important;
                }
            }

            .subtitle {
                font-size: 1.08rem;
                font-weight: 400;
                color: $white;
                margin-bottom: 1.4rem;
            }

            .bt {
                background-color:  $dark !important;
                color: $white !important;
                font-size: 1.45rem !important;
                border-color: $dark !important;
                padding: 4px 30px !important;

                &:hover {
                    background-color: $white !important;
                    color: $dark !important;
                }

                @media(max-width:768px) {
                    font-size: 14px !important;
                    padding: 4px 15px !important;
                }
            }
        }

        .slide-title {
            display: flex;            
            flex-direction: column;
            .title {
                font-family: $titleFont;
                font-size: 1.625rem !important;
                font-weight: 700;
                color: $dark;                
                align-self: flex-start;
                margin: 1em;
            }
            .bt{
                align-self: flex-start;
                justify-self: end;
                margin: 1em;
                position: absolute;
                bottom: 20px;
            }

            .subtitle {
                font-size: 0.980rem;
                font-weight: 400;
                color: $dark;                
                align-self: flex-start;
                margin: 1em;
            }

            .subtitle-tables {
                font-size: 0.980rem;
                font-weight: 400;
                color: $dark;
                align-self: flex-start;
                margin: 1em;
                @media (max-width: 768px) {
                    max-width: 64%;
                }
                @media (min-width: 768px) {
                    max-width: 44%;
                }
            }

            .bt {
                background-color:  $dark !important;
                color: $white !important;
                border-color: $dark;

                &:hover {
                    background-color: $white !important;
                    color: $dark !important;
                }
            }

            .outlet-image__icon {
                max-width: 90px;
            }
        }

        .contain {
            height: 100%;
            .black-label {
                width: 100%;
                padding: 20px;
                background: transparent;

                @media (max-width: $mdMaxWidth) {
                    background: rgba($thunder, 0.25);
                }
            }
            h2 {
                font-family: $titleFont;
            }
            span {
                font-family: $infoFont;
                font-weight: 200;
                text-transform: uppercase;
            }
        }
    }

    .dekton {
        background-image: url('../img/backgrounds/dekton.png');
        height: 200px;
    }
    .loyalty {
        background-image: url('../img/backgrounds/loyalty.png');
        height: 200px;
    }

    .brand-image {
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center;
        background-size: cover;
    }
    .brand-p {
        text-align: center;
        padding: 20px 20px;
        .bt {
            margin: 5px;
        }
    }
}
.v2 #home #section-catalog {
    background: white;
}
.v2 .home-cards-container {
    margin: 24px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-gap: 24px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    .lavabos {
        background: url('../img/home/lavabos-home.png') #efefef;
    }
    .standard{
        background: url('../img/home/standard-home.png') #efefef;
    }
    .ppcc {
        background: url('../img/home/ppcc-home.png') #efefef;
    }
    .campaigns {
        background: url('../img/home/campaigns-home.png') #efefef;
    }
    .slabs {
        background: url('../img/home/slabs-home.png') #efefef;
    }
    .sinks {
        background: url('../img/home/sinks-home.png') #efefef;
    }
    .elaborados {
        background: url('../img/home/lavabos-home.png') #efefef;
    }
    .outlet {
        background: url('../img/home/outlet-home.png') #efefef;
    }
    .cts {
        background: url('../img/home/cts-home.png') #efefef;
    }
    .warehouse {
        background: url('../img/home/warehouse-home.png') #efefef;
    }
    .showroom {
        background: url('../img/home/showroom-home.png') #efefef;
    }
    .mktools {
        background: url('../img/home/mktools-home.png') #efefef;
    }
    .profile {
        background: url('../img/icons/user.svg') #efefef;
        background-size: 120px !important;
    }
    .home-card {
        display: none;
    }
    .home-card.slabs,
    .home-card.ppcc,
    .home-card.elaborados,
    .home-card.sinks,
    .home-card.outlet,
    .home-card.standard,
    .home-card.cts{
        display: block;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
        height: 210px;
        max-width:400px;
        flex: 1;
        list-style: none;
        @media screen and (max-width: $smMaxWidth) {
            min-width:100%;
            max-width:100%;
        }
        span,
        a {
            top: 160px;
            position: relative;
            font-family: 'diagramm-medium';
        }
    }
}
.v2 {
    /* .results--productsForYou, .results--lastProductsBought {
        .sk-hits {
            grid-gap:8px 16px;
            display: flex !important;
            flex-direction: column !important;
            flex-wrap:nowrap !important;
            @media screen and (min-width: $mdMaxWidth) {
                flex-direction: row !important;
            }
        }
    } */

    .home-slide-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
        grid-auto-rows: 256px;
           @media screen and (max-width: $mdMaxWidth) {
                grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
                grid-auto-rows: 280px;
        }
        width: 100%;
        font-family: 'diagramm-light';
        .image-slide-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            background-size: cover;
            position: relative;
            span,
            a {
                position: relative;
                font-family: 'diagramm-light';
            }
            .slide-title {
                font-family: 'diagramm-medium';
                position: absolute;
                bottom: 60px;
                left: 30px;
                right: 30px;

                .title {
                    font-size: 32px !important;
                }

                .subtitle {
                    margin-top: -5px;
                    font-size: 17px;
                    font-weight: 200;
                    font-family: 'diagramm-light';
                }
            }

            .slide-bt {
                position: absolute;
                bottom: 27px;
                left: 30px;
                right: 30px;

                .bt {
                    font-weight: 600 !important;
                    text-transform: uppercase;
                    background: transparent;
                    border: 1px solid white;
                }
            }
        }
        @media screen and (min-width: $mdMinWidth) {
            .card-tall {
                grid-row: span 2 / auto;
            }

            .card-wide {
                grid-column: span 2 / auto;
            }
        }
    }
    
    .background-position-center {
        background-position: center center;
    }
}

.slide-bt.slide-bt-home {
    .bt.noselect.bt-inline.bt-noborder.bt-size-medium {
        background-color: black;
        color: white;
    }
}

.image-slide.ppcc {
    background-size: 428px !important;
    background-position-x: 160px !important;
    background-position-y: 30px !important;
}

.image-slide.cts {
    background-size: 539px !important;
    background-position-x: 251px !important;
    background-position-y: 28px !important;
}

.image-slide.tabla {
    background-size: 742px !important;
    background-position-x: 150px !important;
    background-position-y: -36px !important;
}

.image-slide.banos {
    background-size: 300px !important;
    background-position-x: 292px !important;
}

.image-slide.outlet {
    background-size: 840px !important;
    background-position-x: 101px !important;
}

.div-banner-recurring {
    .left.image-slide{
        .slide-title{
            color: black !important;
            top: 30px !important;
            .home-title {
                font-weight: 500 !important;
            }
            span {
                font-weight: 700;
                display: block;
            }
        }
        .slide-subtitle {
            color: black !important;
            top: 145px !important;
        }
        .slide-bt.slide-bt-home {
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0 !important;
            right: 0;
            text-align: center;
            top: 210px;
            @media (max-width: 368px) {
                top: 248px;
            }
        }
        @media (max-width: 991px) {
            background-size: 70% !important;
        }
        @media (max-width: 575px) {
            background-size: 80% !important;
        }
    }
}

.product-links-container{
    margin: 24px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-gap: 24px;
    width: 100%;
    text-align: center;
    cursor: pointer;

    @media (max-width: 767px){
        display: none;
    }
}

.product-links-slider-container{
    .slick-list{
        padding-right: 20px;
    }
    @media (min-width: 768px){
        display: none;
    }

    margin-bottom: 20px;
    &.container{
        padding: 0;
    }

    .slick-slider{
        margin: 0 -10px;
    }
}
