#wrapper-full.v2 {
    #wrapper-contain {
        .stepper-checkout {
            font-family: diagramm-light;
            font-size: 16px;
            max-width: 960px;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
            div {
                div {
                    div {
                        div {
                            padding: 0 !important;
                            font-weight: 500 !important;
                            border-width: 2px !important;
                            span {
                                line-height: 31px !important;
                                font-weight: 500 !important;
                            }
                            a {
                                line-height: 35px !important;
                                font-weight: 500 !important;
                            }
                        }
                        a {
                            cursor: pointer !important;
                            font-weight: 500 !important;
                        }
                    }
                }
            }
        }
    }
}