.v2 {
  .paginator {
    .page-item:first-child {
    border-right: 0px !important;
    margin-right: 0px;
      a {
        padding-right: 0;
        span {
          i:before {
            font-family: "Font Awesome 5 Pro";
            content: '\f177';
            display: inline-block;
            padding-right: 3px;
            vertical-align: middle;
            font-weight: 900;
          }
        }
      }
  }

    .page-item:last-child {
      border-left: 0px !important;
      margin-left: 0px;
      a {
        padding-left: 0;
        span {
          i:before {
            font-family: "Font Awesome 5 Pro";
            content: '\F178';
            display: inline-block;
            padding-right: 3px;
            vertical-align: middle;
            font-weight: 900;
          }
        }
      }
    }
  }
}

.paginator {

  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border: none!important;
  }

  :focus {
    border: none!important;
    box-shadow:none!important;
  }

  .page-nav {
    font-weight: 300!important;
    color: #5f5f5f!important;;
  }

  .page-nav:hover {
    color: #3a3a3a!important;;
  }

  .page-item:first-child {
    border-right: 1px solid #cacaca!important;
    margin-right: 8px;
  }

  .page-item:last-child {
    border-left: 1px solid #cacaca!important;
    margin-left: 8px;
  }

  .page-link {
    cursor: pointer;
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #9B9B9B;
    background-color: transparent!important;
    font-weight: 500;
    border: none!important;
  }

  .page-item:focus {
    border: none!important;
  }

  .page-link:hover {
    z-index: 2;
    font-weight: 900;
    text-decoration: none;
  }

  .page-link:focus {
    border: none!important;
  }

  .page-item:first-child .page-link {
  }

  .page-item:last-child .page-link {
  }

  .page-item {
    border: none!important;
    font-weight: 500;
    color: #9B9B9B;
    background-color: transparent!important;
  }

  .page-item.active .page-link {
    cursor: default!important;
    z-index: 1;
    color: #4A4A4A;
    font-weight: 700;
  }

  .page-item.disabled .page-link {
    cursor: default!important;
    color: #cacaca!important;
    pointer-events: none;
  }

  .pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5
  }

  .pagination-lg .page-item:first-child .page-link {
  }

  .pagination-lg .page-item:last-child .page-link {
  }

  .pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5
  }

  .pagination-sm .page-item:first-child .page-link {
  }

  .pagination-sm .page-item:last-child .page-link {
  }
}

.sk-toggle-option.sk-toggle__item {
  background-color: transparent !important;
  border: none !important;
  color: #69696B !important;
}

.results-pagination {
  margin-top: 1rem;
}

.sk-toggle-option.sk-toggle__item.is-active {
  font-weight: bold !important;
}

.sk-toggle-option.sk-toggle__item[data-key=previous]>div.sk-toggle-option__text>div {
  display: none !important;
}

.sk-toggle-option.sk-toggle__item[data-key=previous]>div.sk-toggle-option__text:before {
  font-family: "Font Awesome 5 Pro";
  content: '\f177';
  //content: '\f053';
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}

.sk-toggle-option.sk-toggle__item[data-key=next]>div.sk-toggle-option__text>div {
  display: none !important;
}

.sk-toggle-option.sk-toggle__item[data-key=next]>div.sk-toggle-option__text:before {
  font-family: "Font Awesome 5 Pro";
  content: '\f178';
  //content: '\f054';
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}