/*
 * Specific styles of checkout component
 */
.confirm-checkout {
    text-align: center;
    margin-bottom: -25px;
    &_title {
        font-weight: 100;
        margin-bottom: 20px;
        font-size: 2rem;
    }
    &_sub-title,
    &_text {
        font-weight: 400;
        font-size: 1rem;
        margin-bottom: 5px;
    }
}
.form-group {
    &.select_date {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__voucher {
        .wrapper-input {
            position: relative;
            flex: 1 1 auto;
            .icons-status {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                padding: 8px;
                height: 100%;
                line-height: 0;
            }
            input {
                padding-right: 2.25em;
            }
        }
        &--success {
            .wrapper-input {
                color: $green;
                input {
                    color: $green;
                    font-weight: bold;
                    border-color: $green;
                    background-color: rgba($green, 0.1);
                }
            }
        }
        &--failure {
            .wrapper-input {
                color: #dc3545;
                input{
                    color: #dc3545;
                    border-color: #dc3545;
                    background-color: rgba(#dc3545, 0.1);
                }
                .clean-voucher-btn  {
                    background: transparent;
                    border: 0;
                    color: #dc3545;;
                    outline: 0;
                
                    &:focus {
                        outline: 0;
                    }
                
                    &:hover {
                        background-color: rgba(#dc3545, 0.1);
                    }
                }
            }
        }
    }
}
/*
 * General styles
 */
.checkout{
    .form-control:disabled,
    .form-control[readonly] {
        background: white;
    }
}
.container-heading {
    background: $smoke;
    max-width: 100%;
    margin: 0px 0px 20px 0px;
}

.heading {
    text-transform: uppercase;
    font-weight: 200;
    padding: 10px 20px;
    font-family: $infoFont;
    border-radius: $headingBorderRadius;
}

.checkout-heading-row {
    margin: 0px !important;

    .col-12 {
        margin: 0px;
        padding: 0px;

        .checkout-heading {
            margin: 5px 0px !important;
        }
    }
}

/*
 * Form styles
 */

.cart-heading {
    background: $solitude;
}

.form-heading {
    background: $gray;
    color: $white;
}

.checkout {
    .table {
        margin-bottom: 0;
    }
    .cross-selling {
        color: $thunder;
        font-size: 14px;

        .item {
            padding: 20px 0;

            .image {
                max-height: 100px;
            }
        }
    }
    .total {
        margin-top: 20px;
        background: $solitude;
        color: $primaryColor;

        &.cart-empty {
            .label {
                padding: 10px 20px;
            }
        }
    }
    form {
        padding: 10px 20px;
        .is-disabled {
            background-color: rgba(0, 0, 0, 0.05);
            cursor: not-allowed !important;
            .Select-value {
                cursor: not-allowed !important;
            }
        }
    }

    .recurring_orders_image.product-image-container {
        .product-image {
            margin-top: 0 !important;
            
        }
    }

    .checkout-table {
        .headers {
            font-family: $infoFont;
            font-weight: 400;
            border-top: 0;
        }
        .products {
            .row {
                font-size: 13px;
                padding: 10px 0;
                border-bottom: 1px solid #ddd;

                &:last-child {
                    border-bottom: none;
                }

                .bt-edit {
                    cursor: pointer;
                }
            }
        }
        .product-serie {
            font-weight: 500;
            color: $gray;
            text-transform: capitalize;
        }

        .product-title {
            font-weight: 600;
            font-size: 14px;
            text-transform: capitalize;
        }

        .total {
            margin-top: 0.5rem;
            background: $solitude;
            color: $thunder;

            &.cart-empty {
                .label {
                    padding: 10px 0;
                }
            }

            .row {
                padding: 0 40px;

                .label {
                    font-size: 15px;
                    margin: 3px 10px 3px 10px;
                }

                .quantity {
                    font-size: 15px;
                    background: $white;
                    margin: 10px 3px 10px 20px;
                    padding: 3px 10px 3px 10px;
                    width: fit-content;
                }

                .amount {
                    color: $onyx;
                    font-size: 20px;
                    font-weight: bold;
                }
            }
        }
    }
}
.incentive-wrapper {
    min-height: 20px;
}
.incentive-container {
    background: $mercury;
    text-align: center;
    margin: 20px 0px;

    .incentive {
        padding: 30px 0;
        font-weight: 300;
        .icon {
            font-size: 35px;
            vertical-align: middle;
            margin-right: 15px;
        }
        &.mini {
            padding: 10px 0;
            font-size: 13px;

            b {
                font-size: 16px;
            }

            .target {
                width: 90%;

                .icon {
                    font-size: 30px;
                }
            }
        }

        b {
            font-size: 18px;
            font-weight: 600;
        }

        .target {
            display: inline-block;
        }
    }

    .incentive-container__checkout {
        flex: 1 1 5%;
    }
}

#order-finished-container {
    position: fixed;
    top: 20%;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;
    max-width: 950px;
    background: white;
    z-index: $layer50;

    @media (max-width: $mdMaxWidth) {
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        overflow-x: none;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
}
.logo-container {
    background: $white;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
#logo-img {
    width: 80%;
    top: 20%;
    position: relative;
}
.order-finished {
    label,
    .forgot-password a,
    .forgot-password span {
        color: $white;
    }
    .check-icon {
        position: absolute;
        margin-top: 20px;
        left: -30px;
        color: limegreen;
        @media (max-width: $mdMaxWidth) {
            margin-top: -30px;
            left: 10px;
        }
    }
    header {
        padding: 20px 25px;
        .bt-close {
            display: inline-block;
            cursor: pointer;

            &:hover {
                color: $onyx;
            }

            i {
                font-size: 40px;
            }
        }
    }
    h2 {
        padding-bottom: 40px;
    }
    footer {
        padding: 20px;
    }
}

.checkout-form {
    form .form-heading.heading {
        margin: 20px -20px;
    }
    &--ikea {
        form .form-heading.heading {
            margin: 20px -20px;
        }
    }
}
label.CheckboxLinkSaleConditions {
    a {
        text-decoration: underline !important;
        font-weight: 400;
    }
}
.matNoStock-modal {
    .qty-cell {
        text-align: center;
    }
    .col-3 {
        align-self: center;
    }
}

#wrapper-full.v2 {
    .cross-selling-checkout{
        padding: 24px;
    }

    div.Select-menu-outer{
        border: 1px solid black !important;
        border-top: unset !important;
        border-radius: 0 !important;
        margin-left: -1px !important;
        min-width: 130px;
    }

    .incentive-select-shipping{
        min-width: 130px;

        .select__shipping_method--option{
            background: $TertiaryColor !important;
            &:hover{
                background: $disableLightColor !important;
            }
        }

        >div.Select-menu-outer{
            border: 1px solid black !important;
        }

        > .select__shipping_method{
            background: transparent !important;
            border: 1px solid black;
        }


    }

    .incentive-info-text{
        text-align: start;
        max-width: 300px;
        @media (max-width: 768px){
            max-width: unset;
            margin-bottom: 10px;
        }
    }

    .incentive-buttons{
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        margin-top: 16px;
    }

    .incentives-box{
        @media (max-width: 768px){
            flex: 1;
        }
    }

    .incentives-weights{
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        &-mobile{
            @media (min-width: 769px){
                display: none;
            }

            @media (min-width: 992px) and (max-width: 1199px){
                display: flex;
            }
        }

        &-desktop{
            @media (max-width: 768px){
                display: none;
            }

            @media (min-width: 992px) and (max-width: 1199px){
                display: none;
            }
        }
    }

    .mktools-bar {
        flex: 1 0 100%;
    }

    .mktools-info {
        text-align: left;
        padding: 0 25px;
        margin-top: 15px;
    }

    .d-flex-1 {
        flex: 1;
    }
    .d-flex-2 {
        flex: 2;
    }

    .modal-dialog.modal-no-stock {
        @media (min-width: 320px) and (max-width: 780px) {
            .modal-no-stock-title {
                display: none !important;
            }
        }
    }

    .rectangle-result.small {
        width: 200px;
        .product-image-container {
            width: 200px;
            .product-image {
                width: 200px;
            }
        }
        .product-info {
            .product-info-details {
                .price {
                    span {
                        margin: 0;
                        font-size: 12px;
                    }
                }
            }
        }
        .product-actions {
            display: flex;
            flex-direction: column;
            .input-row {
                max-width: none;
                .input {
                    .input-number.v2.noselect {
                        max-width: none;
                        .spin {
                            width: 50px;
                        }
                    }
                }
            }
            .bt-row {
                width: 100%;
                max-width: none;
                margin-top: 8px;
            }
        }
    }

    div.alert_empty {
        border-color: red !important;
    }
    input.alert_empty {
        border-color: red !important;
    }
    textarea.alert_empty {
        border-color: red !important;
    }
    span.alert_empty {
        color: #FF5F6D;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 13px;
    }
    .checkout-cart-details {
        .cross-selling-phone {
            display: none;
        }
        @media (min-width: 320px) and (max-width: 1023px) {
            .cross-selling-desktop {
                display: none;
            }
            .cross-selling-phone {
                display: block;
            }
        }
        //font-family: 'diagramm-light';
        @media screen and (min-width: 1024px) {
            display: grid;
            grid-template-columns: 65% 33%;
            justify-content: space-between;
        }
        padding: 24px;

        .checkout-cart {
            background-color: white;
            padding: 24px 16px;

            .line-checkout-not-show {
                display: none;
            }

            .btn-keep-buying {
                height: 48px;
                font-family: 'Roboto';
            }

            .btn-empty-cart {
                width: 136px;
                height: 48px;
                background-color: white;
                border: 1px solid #232323;
                font-family: 'Roboto';
            }

            &__weights, &__hq{
                margin-bottom: 8px
            }

            .checkout-cart-details__cart-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 24px;

                @media (max-width: $xsMaxWidth) {
                    flex-direction: column;
                    align-items: flex-start;

                    .your_cart {
                        font-size: 24px;
                    }

                    .total_qty {
                        font-size: 20px;
                    }

                }

                .checkout-cart-details__total-qty {
                    display: flex;
                    align-items: center;
                    
                    .your_cart {
                        padding-right: 0.5rem;
                    }
                }

                .checkout-cart-details__empty-cart {
                    span {
                        cursor: pointer;
                        font-weight: 500;
                        font-size: 14px;
                        color: $primaryColor;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            &__hq, &__center{
                .checkout-cart-details__cart-content {
                    .checkout-cart-details__total-qty{
                        @media screen and (max-width: $xsMaxWidth){
                            display: block;
                        }
                    }
                }
            }

              &__weights{
                    .checkout-cart-details__cart-content{
                          .checkout-cart-details__total-qty{
                                align-items: baseline;

                                .your_cart {
                                    font-size: 28px;
                                }
                                .total_qty {
                                    font-size: 22px;
                                }
                          }
                    }
              }


            @media (max-width: $xsMaxWidth) {
              &__weights{
                .checkout-cart-details__cart-content{
                  flex-direction: row;
                  align-items: normal;

                  .checkout-cart-details__total-qty{
                    display: block;

                    .your_cart {
                      font-size: 22px;
                      line-height: 26px;
                      vertical-align: bottom;
                    }

                    .total_qty {
                      font-size: 14px;
                      line-height: 16px;
                      vertical-align: top;
                    }
                  }
                }
              }
            }

            .checkout-table {
                .col-12.products.item-list {
                    .line-item.checkout{
                        padding-bottom: 16px;
                        margin-bottom: 16px;
                        border-bottom: 1px solid #EBEBEB;
   
                        @media (min-width: 320px) and (max-width: 455px) {
                            .img {
                                max-height: 80px;
                                min-width: 80px;
                            }
                            .technology-indicator {
                                top: 32px;
                            }
                        }
                        @media (min-width: 320px) and (max-width: 420px) {
                            .delete-add-fav {
                                flex-direction: column;
                                .separator {
                                    display: none;
                                }
                            }
                        }
                        .content {
                            .description-and-input {
                                .segmentation {
                                    display: none;
                                }
                                .comment-title, .segmentation-title, .sla-date {
                                    font-family: $infoFont;
                                    font-weight: 400;
                                    font-size: 14px;
                                    color: $primaryColor;
                                }
                                .mini_cart_line_title  {
                                    .mini_cart_line_info__line {
                                        .total {
                                            font-family: $infoFont;
                                            font-weight: 500;
                                            font-size: 21px;
                                            float: right;
                                            background-color: transparent;
                                        }
                                        .price {
                                            font-family: $infoFont;
                                            font-weight: 400;
                                            font-size: 12px;
                                            float: right;
                                            color: $primaryColor;
                                        }
                                    }
                                }
                                .cartline-actions, .cartline-actions-phone {
                                    .mini_cart_line_info.mini_cart_line_info--vertical_center {
                                        .input-number.noselect.small {
                                            max-width: 100%;
                                            color: $primaryColor;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            gap: 3px;

                                            .far {
                                                font-weight: 400;
                                            }
                                            max-width: 103px;
                                            .spin {
                                             height: 30px !important;
                                             line-height: 29px;
                                            }
                                            .spin.minus {
                                                position: relative;
                                                background-color: $white;
                                                border: 1px solid $primaryColor;
                                            }
                                            .input-container {
                                                height: 30px !important;
                                                width: 34px;

                                                .inner-input-number {
                                                    border: 1px solid $primaryColor;
                                                    background-color: $white;
                                                    height: inherit;
                                                }
                                            }
                                            .spin.plus {
                                                position: relative;
                                                background-color: white;
                                                border: 1px solid $primaryColor;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .banner {
                @media (min-width: 320px) and (max-width: 1140px) {
                    display: block;
                }
                display: grid;
                grid-template-columns: 100%;
                justify-content: space-between;
                margin-bottom: 24px;
                .incentive-container {
                    .container {
                        padding: 0;

                        .row {
                            margin: 0;
                        }
                    }

                    display: flex;
                    flex-direction: column;
                    margin: 0;
                    padding: 16px;
                    background-color: #DBE4E9;
                    .incentive.mini.row {
                        display: block;
                        padding: 0;
                        .icentives-title {
                            display: flex;
                            text-align: left;
                            font-size: 16px;
                            font-weight: 400;
                            font-family: Roboto;
                            b {
                                font-size: 16px;
                            }
                        }
                        div:first-child{
                            flex-wrap: wrap;
                        }
                        .progress-bar-container.small {
                            .bar {
                                height: 15px;
                            }
                            .custom-progress-circle {
                                border-radius: 50%;
                                height: 12px;
                                text-align: center;
                                width: 12px;
                                padding: 6px;
                                position: relative;
                                top: -3px;
                            }
                        }
                    }
                    .custom-progress-box {
                        margin-top: 16px;
                        display: grid;
                        width: 100%;
                        place-content: center;
                        .custom-progress-legend {
                            display: flex;
                            flex-direction: row;
                            justify-content: end;
                            margin-top: 26px;
                            font-family: Roboto;
                            font-weight: 300;
                            font-size: 10px;
                        }
                        .custom-progress-title {
                            @media (min-width: 320px) and (max-width: 522px) {
                                font-size: 9px;
                            }
                            display: flex;
                            flex-direction: row;
                            white-space: nowrap;
                            align-items: end;
                            justify-content: space-between;
                            font-family: Roboto;
                            font-weight: 300;
                            font-size: 12px;
                            .custom-progress-title-1 {
                                flex-basis: 0;
                                flex-grow: 1;
                            }
                            .custom-progress-title-2 {
                                flex-basis: 0;
                                flex-grow: 1;
                            }
                            .custom-progress-title-3 {
                                flex-basis: 0;
                                flex-grow: 1;
                            }
                        }
                        .custom-progress {
                            display: flex;
                            justify-content: flex-start;
                            background-color: #B2B2B2;
                            border-radius: 4px;
                            @media (min-width: 320px) and (max-width: 365px) {
                                width: 122px;
                            }
                            @media (min-width: 366px) and (max-width: 423px) {
                                width: 141px;
                            }
                            @media (min-width: 424px) and (max-width: 503px) {
                                width: 200px;
                            }
                            @media (min-width: 504px) and (max-width: 585px) {
                                width: 300px;
                            }
                            @media (min-width: 586px) and (max-width: 685px) {
                                width: 400px;
                            }
                            @media (min-width: 686px) and (max-width: 1140px) {
                                width: 500px;
                            }
                            @media (min-width: 1141px) and (max-width: 1286px) {
                                width: 600px;
                            }
                            width: 700px;
                            .custom-progress-bar {
                                background-color: #9BC2C7;
                                height: 4px;
                                border-radius: 4px;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                                min-width: 7%;
                                .custom-progress-bar-box {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    //align-self: center;
                                    .custom-progress-circle {
                                        border-radius: 50%;
                                        height: 12px;
                                        text-align: center;
                                        width: 12px;
                                        background-color: #4AA297;
                                        padding: 6px;
                                        position: relative;
                                        top: -3px;
                                    }
                                    .custom-progress-text {
                                        text-align: end;
                                        font-family: Roboto;
                                        font-weight: 500;
                                        font-size: 16px;
                                        white-space: nowrap;
                                    }
                                }
                            }
                        }
                    }
                    .add_more_items {
                        font-family: Roboto;
                        font-weight: 500;
                        font-size: 14px;
                        cursor: pointer;
                        display: block;
                        margin-top: auto;
                        text-align: right;
                    }
                    .add_more_items:hover {
                        text-decoration: underline;
                    }
                }
                .spend {
                    @media (min-width: 320px) and (max-width: 1140px) {
                        display: none;
                    }
                    font-family: Roboto;
                    font-weight: 500;
                    font-size: 16px;
                    display: flex;
                    flex-direction: row;
                    background-color: #F9F9F9;
                    padding: 16px;
                    div:first-child{
                        margin-right: 16px;
                    }
                    div:last-child {
                        display: flex;
                        flex-direction: column;
                        div:first-child{
                            padding-right: 20px;
                            margin-bottom: 16px;
                            display: flex;
                            flex-basis: 50%;
                            justify-content: center;
                            span {
                                font-size: 16px;
                                font-weight: 400;
                                font-family: Roboto;
                            }
                            b {
                                font-size: 16px;
                                font-weight: 600;
                                font-family: Roboto;
                            }
                        }
                        div:last-child{
                            font-family: Roboto;
                            font-weight: 500;
                            font-size: 14px;
                            cursor: pointer;
                            display: block;
                            margin-top: auto;
                            text-align: right;
                        }
                        div:last-child:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }

            &__weights{
                > .banner{
                    margin-bottom: 0;
                }
            }

            .your_cart {
                display: inline;
                font-family: $titleFont;
                font-weight: 700;
                font-size: 28px;
                color: $primaryColor;
            }
          .center_items_title{
            display: inline;
            font-family: 'diagramm-semibold';
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            vertical-align: bottom;
          }

          .center_items_total_qty{
            display: flex;
            align-items: center;
            gap: 6px;
            font-family: 'diagramm-light';
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
          }
            .total_qty {
                font-weight: 400;
                font-size: 22px;
                color: $primaryColor;
            }
            .cart-heading.heading {
                display: none;
            }
            .total.container-fluid{
                display: none;
            }
            .description-and-input {
                .cartline-actions-phone {
                    display: none;
                }
                .mini_cart_line_price {
                    .mini_cart_line_info__line {
                        display: flex !important;
                        align-items: end;
                        flex-direction: column;
                        .total {
                            margin-top: 0;
                        }
                        br {
                            display: none;
                        }
                    }
                    .price {
                        font-family: 'Roboto';
                        font-weight: 500;
                        font-size: 21px;
                    }
                }
                .mini_cart_line_price_phone {
                    display: none;
                    align-items: baseline;
                    .total {
                        margin-top: 0;
                        font-family: 'Roboto';
                        font-weight: 500;
                        font-size: 21px;
                        background-color: transparent;
                        margin-right: 8px;

                        @media (max-width: $smMaxWidth) {
                            font-size: 18px;
                        }
                    }
                    .price {
                        font-family: 'Roboto';
                        font-weight: 400;
                        font-size: 11px;
                    }
                }
                @media (min-width: 320px) and (max-width: 767px) {
                    .mini_cart_line_price {
                        display: none;
                    }
                    .cartline-actions {
                        display: none;
                    }

                    .delete-add-fav-phone {
                        display: flex !important;
                        margin-top: 16px;
                        i{
                            margin-right: 0px !important;
                        }
                        .separator{
                            margin-left: 4px !important;
                            margin-right: 4px !important;
                        }
                    }
                    .delete-add-fav {
                        display: none !important;
                    }
                    .cartline-actions-phone {
                        display: block;
                    }
                    .mini_cart_line_price_phone {
                        display: flex;
                        padding-bottom: 6px;
                    }
                }
                .date-sla {
                    margin-top: 8px;
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    justify-content: flex-start;

                    @media (max-width: $smMaxWidth) {
                        gap: 6px;
                        align-items: flex-start;
                        flex-direction: column;
                    }
                }

                .mini_cart_line_title {
                    .title {
                        display: flex;
                        flex-direction: column-reverse;
                        .product-title {
                            font-family: $titleFont;
                            font-weight: 500;
                            font-size: 16px;
                        }
                        .subtitle {
                            font-family: $infoFont;
                            font-weight: 300;
                            font-size: 11px;
                        }
                    }
                }
                .mini_cart_line_info {
                    font-family: $infoFont;
                    font-weight: 300;
                    font-size: 11px;
                }
            }
        }

        .checkout-cart-box-desktop {
            display: none;
            @media (min-width: 1024px) {
                display: block;
            }
            .checkout-cart-box {
                position: sticky;
                top: 10.5rem;
                z-index: $layer10;
            }
        }
        
        .checkout-cart-box-mobile {
            display: block;
            @media(min-width: 1024px) {
                display: none;
            }
        }

        .checkout-cart-box {
            height: fit-content;
            .checkout-cart-total {
                hr {
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                }
                @media (min-width: 320px) and (max-width: 1023px) {
                    margin-top: 24px;
                }
                background-color: white;
                padding: 16px;
                height: fit-content;

                h1 {
                    font-family: $infoFont;
                    font-weight: 500;
                    font-size: 28px;
                    margin-bottom: 0;
                    color: $primaryGray;
                }

                .total_qty {
                    font-family: $infoFont;
                    font-size: 16px;
                    color: #646464;
                    font-weight: 500;
                }

                .total {
                    font-family: $titleFont;
                    font-weight: 600;
                    font-size: 20px;
                    color: $primaryGray;
                }

                .coupon-price {
                    font-weight: 400;
                    font-size: 12px;
                    font-family: $infoFont;
                    i {
                        cursor: pointer;
                        display: inline-block;
                        border-radius: 60px;
                        padding: 0.5em 0.6em;
                        color: white;
                        background-color: #a3a3a3;
                    }
                }
                .form-group__voucher {
                    .coupon {
                        font-weight: 500;
                        font-size: 14px;
                        font-family: $infoFont;
                        color: $secondNeutralText;
                    }
                    input.input-voucher {
                        border: 1px solid black;
                        border-radius: 0;
                        padding-right: 0;
                    }
                    div.input-group-append {
                        margin-left: 0;
                    }
                    @media (min-width: 320px) and (max-width: 378px){
                        div.input-group-append {
                            width: 100%;
                            button.btn.btn-outline-secondary {
                                width: 100%;
                            }
                        }
                    }
                    @media (min-width: 1024px) and (max-width: 1050px){
                        div.input-group-append {
                            width: 100%;
                            button.btn.btn-outline-secondary {
                                width: 100%;
                            }
                        }
                    }
                }

                .coupon-applied-successfully {
                    color: rgb(54, 165, 150);
                    margin-top: 16px;
                    font-family: 'Roboto';
                    font-weight: 500;
                }

                .coupon-applied-error {
                    color: rgb(165, 54, 54);
                    margin-top: 16px;
                    font-family: 'Roboto';
                    font-weight: 500;
                    text-align: left;
                }
            }
        }
        .checkout-cart-date{
            background-color: white;
            padding: 16px;
            margin-top: 16px;
            .title {
                font-size: 16px;
                font-family: $infoFont;
                font-weight: 400;
                color: $primaryGray;
                padding-bottom: 6px;
            }
            .subtitle {
                font-size: 12px;
                font-family: $infoFont;
                font-weight: 400;
                color: $primaryGray;
            }
            .select {
                font-size: 12px;
                margin-top: 16px;
                .Select.has-value.is-searchable.Select--single {
                    border: 1px solid black;
                    padding: 4px;
                    border-radius: 0;
                }
            }
            .date {
                margin-top: 16px;
                input {
                    padding: 9px;
                    border-radius: 0;
                    border-color: black;
                }
                .date-text {
                    margin-top: 16px;
                    color: #FF6805;
                    font-size: 12px;
                    font-family: 'Roboto';
                    font-weight: 400;
                }
            }
        }
        @media (max-width: 767px) {
            padding: unset;
        }
    }
    .articles {
        p {
            font-family: diagramm-medium;
            font-weight: 600;
            font-size: 16px;
        }
    }
    .articles-price {
        font-family: Roboto;
        font-weight: 400;
        font-size: 16px;
        font-family: Roboto;
    }
    .shipping {
        p {
            font-family: diagramm-medium;
            font-weight: 600;
            font-size: 16px;
        }
    }
    .shipping-price {
        font-family: diagramm-medium;
        font-weight: 600;
        font-size: 16px;
        font-family: Roboto;
    }
    .total {
        p {
            font-family: $titleFont;
            color: $primaryGray;
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 0;
        }
    }
    .total-price {
        font-weight: 600;
        font-size: 24px;
        font-family: $infoFont;
        color: $primaryGray;
    }
    .next {
        font-family: $infoFont;
        font-weight: 500;
        padding: 12px 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        position: static;
            margin-top: 24px;

        /* @media screen and (min-width: 1024px) {
            
        } */
    }

    .next-floating {
        display: none;
        @media screen and (max-width: 1023px) {
            display: block;
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1030;
            display: block;
            width: 100%;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            font-family: Roboto;
            text-transform: uppercase;
        }
    }

    .checkout-address{
        font-family: $infoFont;
        margin-top: 24px;
        margin-bottom: 24px;

        @media (max-width: $smMaxWidth) {
            margin-top: 16px;
        }

        .mini_cart_line_info__line {
            .total {
                font-family: $infoFont;
                font-weight: 400;
                font-size: 12px;
                background-color: transparent;
                color: $primaryColor
            }
        }
        @media screen and (min-width: 1024px) {
            display: grid;
            grid-template-columns: 65% 33%;
            justify-content: space-between;
        }

        .checkout-address-box {
            background-color: white;
            padding: 24px 16px;

            label {
                font-family: $infoFont;
                font-weight: 500;
                font-size: 12px;

                div.tip {
                    font-weight: 400;
                }
            }

            input#input-po,
            textarea#input-comments,
            input#input-installation-data-name-id,
            input#input-installation-data-address-id,
            input#input-installation-data-town-id,
            input#input-installation-data-province-id,
            input#input-installation-data-zip-code-id,
            input#input-installation-data-phone-id,
            input#input-lowes-data-first-name,
            input#input-lowes-data-last-name,
            input#input-lowes-data-email,
            input#input-lowes-data-title,
            div.react-datepicker__input-container>input,
            label[for="input-employee-id"]+input{
                border: 1px solid black;
                border-radius: 0;
                font-family: Roboto;
                font-size: 16px;
            }

            .shipping_information {
                color: $primaryColor;
                font-family: $titleFont;
                font-weight: 700;
                font-size: 28px;
            }

            .select {
                font-size: 12px;
                margin-top: 16px;

                .Select.is-searchable.Select--single {
                    border: 1px solid black;
                    padding: 4px;
                    border-radius: 0;
                }
            }
        }
        .checkout-address-cart {

            @media (min-width: 320px) and (max-width: 1023px) {
                margin-top: 24px;
            }

            grid-row-start: 1;
            grid-column-start: 2;
            padding: 16px;
            background-color: white;
            height: fit-content;
            position: sticky;
            top: 10.5rem;
            z-index: $layer10;

            .resume {
                font-family: $titleFont;
                font-weight: 500;
                font-size: 28px;
                color: $primaryGray;
                margin: 0;
            }

            .total_qty {
                color: $secondNeutralText;
                font-weight: 500;
                font-size: 16px;
                font-family: $infoFont;
            }

            .mini_cart_line_quantity {
                font-size: 12px;
                font-weight: 400;
                color: $primaryColor;
            }

            .confirm__order__btn {
                padding-bottom: 24px;
            }
        }
        .CheckboxLinkSaleConditions {
            display: block;
            position: relative;
            padding-left: 32px;
            -webkit-user-select: none;
            user-select: none;
            margin-bottom: 0;
        }
        .CheckboxLinkSaleConditions input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        .checkmark {
            position: absolute;
            top: 2px;
            left: 0;
            height: 15px;
            width: 15px;
            background-color: transparent;
            border: 1px solid black;
            border-radius: 3px;
            cursor: pointer;
        }
        .CheckboxLinkSaleConditions input:checked ~ .checkmark {
            background-color: white;
            border: 1px solid black;
            border-radius: 3px;
        }
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        .CheckboxLinkSaleConditions input:checked ~ .checkmark:after {
            display: block;
        }
        .CheckboxLinkSaleConditions .checkmark:after {
            left: 4px;
            top: 1px;
            width: 5px;
            height: 10px;
            border: solid black;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        label.label_1 {
            font-weight: 600;
            font-size: 13px;
        }
        label.label_2 {
            font-weight: 500;
            font-size: 16px;
        }
        input.input_1 {
            border: 1px solid #232323;
            border-radius: 0;
        }
        input[type='radio'] {

        }
        input[type='radio']:checked {
            border-color: black;
            background-color: white;
            color: black;
        }
        textarea.textarea_1 {
            border: 1px solid #232323;
            border-radius: 0;
        }
    }
    .checkout-confirmation {
        padding: 24px 16px;
        .checkout-confirmation-box {
            @media screen and (min-width: 320px) and (max-width: 999px) {
                display: flex;
                flex-direction: column;
            }

            @media (max-width: $smMaxWidth) {
                padding: 16px;
            }

            display: grid;
            grid-template-columns: 55% 43%;
            justify-content: space-between;
            background-color: white;
            padding: 24px;
            .title {
                font-family: diagramm-medium;
                font-weight: 700;
                font-size: 28px;
                div {
                    display: inline;
                    cursor: pointer;
                    border-radius: 60px;
                    padding: 5px 8px;
                    color: white;
                    background-color: #36A596;
                    margin-right: 16px;
                }
            }
            .subtitle {
                font-family: Roboto;
                font-weight: 400;
                font-size: 16px;
                color: #4D4D4D;
            }
            .bold {
                font-family: Roboto;
                font-weight: 700;
                font-size: 16px;
            }
            .box-btn {
                @media screen and (min-width: 320px) and (max-width: 455px) {
                    flex-direction: column;
                    align-items: center;
                    div:first-child {
                        margin-bottom: 16px;
                    }
                }
                display: flex;
            }
            .image-outlet {
                @media screen and (min-width: 320px) and (max-width: 999px) {
                    background-position: right;
                    height: 200px;
                    margin-top: 16px;
                    .slide-title {
                        .title {
                            font-size: 24px !important;
                        }
                    }
                }
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                background-size: cover;
                position: relative;
            }
        }

        .reservation-msg-box {
            display: flex;
            gap: 8px;
            align-items: center;
            padding-bottom: 24px;

            @media (max-width: $smMaxWidth) {
                flex-direction: column;
                align-items: baseline;
                gap: 0;

                .subtitle {
                    padding-bottom: 0.5rem;
                }
            }

            .subtitle-bold {
                color: $thunder;
                font-weight: 700;
                font-size: 16px;
                line-height: normal;
            }

            .divider-vertical {
                height: 100%;
                width: 1px;
                background-color: #C7C7C7;
            }

            .review-my-order-btn {
                background-color: transparent;
                border: 0;
                color: $normalblue;
                font-weight: 500;
                font-size: 14px;
                padding: 0;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .cts-reservation-msg-box {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            padding-bottom: 24px;

            .cts-reservation-msg-content {
                display: flex;
                gap: 8px;
                align-items: center;

                @media (max-width: $smMaxWidth) {
                    flex-direction: column;
                    align-items: baseline;
                }

                .subtitle-bold {
                    color: $thunder;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: normal;
                }

                .divider-vertical {
                    height: 100%;
                    width: 1px;
                    background-color: #C7C7C7;

                    @media (max-width: $smMaxWidth) {
                        display: none;
                    }
                }

                .cts-review-my-order-btn {
                    background-color: transparent;
                    border: 0;
                    color: $normalblue;
                    font-weight: 500;
                    font-size: 14px;
                    padding: 0;
    
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .keep-buying-btn {
            button {
                padding: 16px 32px;
                color: $primaryColor;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                border: 1px solid $primaryColor;
                background-color: $white;

                &:hover {
                    background-color: $primaryColorHover;
                }
            }
        }
    }
}

.checkout-hr{
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-color: $primaryColor;
}

.checkout-empty-cart-container{
    margin-top: 24px;
    padding: 110px 40px;
    background: $white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .cart-is-empty-text{
        text-align: center;
        margin-bottom: 20px;
    }
}

.separator-line-bottom{
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #EBEBEB;
}

.info-text{
  &__container{
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  &__orange{
    margin: 0;
    margin-inline-start: 8px;
    color: $alertColor;
    font-family: $infoFont;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}

.checkout-cart-details__cart-content{
  .alert-danger {
    color: #000000;
    background-color: #FBDFDC; //Is a variable in a different branch
    border-color: #FBDFDC;
    padding: 16px;
    margin-top: 8px;
    border-radius: 0;

    &__main-text{
      font-family: $infoFont;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 4px;
    }

    &__sub-text{
      font-family: $infoFont;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      font-style: italic;
    }
  }
}

.health-conditions-modal {

    .modal-dialog {
        max-width: 1100px;
    }

    .health-conditions-modal-alert.alert-primary {         
        color: #1F2228;
        background-color: #D7EAF8;
        font-size: 14px;
        font-weight: 400;
        border: 0;
        border-radius: 0;
    }

    .health-conditions-modal__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 32px;
        margin: 0;
    
        .modal__actions-btn {
            display: flex;
            align-items: center;
            gap: 24px;

            @media (max-width: $mdMaxWidth) {
                width: 100%;

                .bt {
                    width: 100%;
                }
            }
        }

        @media (max-width: $mdMaxWidth) {
            flex-direction: column;
            gap: 24px;
        }
    }

    .modal-header {
        padding: 32px 32px 0 32px;
        border: 0;

        @media (max-width: $smMaxWidth) {
            padding: 16px 16px 0 16px;
        }

        .modal-title {
            text-transform: uppercase;
            color: #20367A !important;
            //font-size: 24px !important;
            font-weight: 700 !important;

            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                font-weight: 400 !important;
                //font-size: 20px;
                line-height: 36px;
                text-transform: none !important;
            }

            @media (max-width: $smMaxWidth) {
                flex-direction: column;
                align-items: baseline;
            }
        }
    }

    .modal-body {
        padding: 32px 32px 0 32px;
        max-height: 466px;

        @media (max-width: $smMaxWidth) {
            padding: 16px 16px 0 16px;
        }

        p {
            color: #6B6B6B;
            font-size: 14px;
            font-weight: 400;

            a {
                color: $brandNewBlue;
            }
        }

        li {
            color: #6B6B6B;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .modal-footer {
        padding: 32px;
        border: 0;

        @media (max-width: $smMaxWidth) {
            padding: 16px;
        }
    }

    .health-conditions-modal-last-para {
        margin-bottom: 60px;

        @media (max-width: $mdMaxWidth) {
            margin-bottom: 100px;
        }
    }
}

.checkout-show-more-less-btn {
    font-weight: 500 !important;
    color: $primaryColor !important;
}