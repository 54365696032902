.ReactTable {
    border: 0;

    &.light {
        .rt-table {
            .rt-thead {
                background: transparent;
                font-size: 13px;
                font-weight: 400;

                border-bottom: 1px solid $iron;
                padding-bottom: 10px;
                margin-bottom: 10px;

                .rt-tr {
                    .rt-th {
                        padding: 5px;
                    }
                }
            }
            .rt-tbody {
                font-size: 13px;
                //overflow-y: scroll;
                max-height: 300px;
                .rt-tr {
                    .rt-td {
                        padding: 5px;

                        &:nth-child(n + 3) {
                            padding-top: 20px;
                        }
                    }
                }
            }
        }
    }

    .rt-table {
        .rt-thead {
            background: $smoke;
            box-shadow: none;
            font-weight: 300;

            .rt-tr {
                .rt-th {
                    border: none;
                    padding: 20px 10px;
                    // text-align: left;
                }
            }
        }

        .rt-tbody {
            font-size: 14px;
            font-weight: 200;
            overflow-x: hidden;
            .rt-tr {
                .rt-td {
                    padding: 15px 10px;

                    &.rt-expandable {
                        cursor: auto;
                    }
                    &.imagen {
                        padding: 5px 10px;
                    }
                }
            }
        }
    }

    .-rowInfo {
        font-size: 14px;
        font-family: $infoFont;
        font-weight: 500;
    }

    .pagination-bottom {
        .-center {
            flex: unset;
        }
        @media (max-width: $mdMaxWidth) {
            .-pagination {
                flex-direction: column;

                .-previous {
                    order: -2;
                    margin-bottom: 5px;

                    .-btn {
                        padding: 5px 0;
                    }
                }

                .-center {
                    padding: 10px 0 0;
                    flex-direction: column;
                }

                .-next {
                    order: -1;
                    margin-bottom: 5px;

                    .-btn {
                        padding: 5px 0;
                    }
                }
            }
        }

        .-pagination {
            box-shadow: none;
            border: none;

            .-btn {
                padding: 0 10px;
                background: $mercury;
                color: $gray;

                &:hover {
                    background: $iron;
                    color: $onyx;
                }

                &[disabled] {
                    background: $smoke;
                    color: $aluminum;

                    &:hover {
                        background: $smoke;
                    }
                }
            }

            .-pageInfo {
                font-size: 14px;

                .-pageJump {
                    margin: 0 5px;
                }
            }
        }
    }
    .rt-table .rt-tbody .rt-tr {
        .rt-td.cell {
            &_background-image {
                padding: 0;
            }
        }
    }
}
.lots_table {
    .rt-td {
        text-align: center;
        border-right: 0 !important;
    }
}
