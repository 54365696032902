#modal-tables-quantity-container {
    position: fixed;
    right: 0;
    left: 0;
    top: 10%;
    margin: auto;
    width: 640px;
    text-align: left;
    max-width: 640px;
    z-index: $layer50;
    border-radius: 5px;
    max-height: 75vh;
    @media (max-width: $mdMaxWidth) {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        max-width: none;
        max-height: none;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        border-radius: 0;
    }
}
.tables-quantity {
    background: $white;
    .segmentation-icon {
        background: black;
    }
    header {
        padding: 20px 25px 8px;
        border-bottom: 1px solid $onyx;
        .bt-close {
            display: inline-block;
            cursor: pointer;

            &:hover {
                color: $onyx;
            }

            i {
                font-size: 40px;
            }
        }
    }
    footer {
        @media (min-width: 768px) {
            display: flex;
            justify-content: space-evenly;
            & > div {
                flex: 1;
                margin: 0 2%;
            }
        }
        .bt {
            margin-bottom: 20px;
        }
    }
    .scroll {
        max-height: 60vh;
        overflow-y: auto;
    }
    img {
        width: 48px;
        height: 48px;
    }
    .small {
        font-size: small;
    }
    .quantity {
        margin: 0;
    }

    .decorator {
        font-size: 0.6rem;
        line-height: 1.4rem;
    }

    .tables-list {
        padding: 8px;
        width: 100%;
        max-height: 40vh;
        overflow-y: auto;
    }

    .tables-row {
        height: 2rem;
    }
}
