.box-parent-banner-result-v2 {
    font-family: 'Diagramm';
    height: 433px;
    background-repeat: no-repeat;
    display: flex;

    @media (min-width: 768px) {
      height: 100%;
    }
    .box-container {
        height: 100%;
        width: 100%;
        background-blend-mode: multiply;
        text-align: left;
        padding: 16px;
        color: white;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .box-title {
        font-family: 'diagramm-light';
        font-weight: 700;
        font-size: 33px;
        line-height: 113%;
        margin-bottom: 10px;
    }
    .maxi {
        font-family: 'diagramm';
    }
    .box-subtitle {
        width: 210px;
        font-size: 18px;
        line-height: 20px;
    }
    .box-button-container {
        font-family: 'Roboto Light';
        display: flex;
        justify-content: flex-end;
        flex: 1;
        flex-direction: column;
    }
    .box-button {
        display: flex;
        padding: 16px 32px;
        background: #232323;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0);
        border-radius: 0;
        justify-content: center;
    }
    .box-button:hover {
        background-color: #dbe4e9 !important;
        color: #232323 !important;
    }
}
