.cut_to_size {
    .field-info {
        font-size: 80%;
        margin-top: 4px;
        margin-bottom: 4px;
        line-height: 125%;
    }
    .group__input {
        margin-bottom: 0.8rem;
        input {
            &.disabled {
                background-color: $smoke;
                cursor: not-allowed;
            }
        }
        .Select {
            &.disabled {
                background-color: $smoke;
                * {
                    cursor: not-allowed!important;
                }
                .Select-arrow {
                    display: none;
                }
            }
        }        
        
    }
    .container {
        // display: flex;
        // flex-wrap: wrap;
        .react-resizable-handle {
            position: absolute;
            width: 32px;
            height: 32px;
            bottom: -16px;
            right: -16px;
            display: flex;
            padding: 0 3px 3px 0;
            background: none;
            box-sizing: border-box;
            cursor: se-resize;
            &:before {
                content: '';
                background-color: white;
                width: 16px;
                height: 16px;
                display: flex;
                border-radius: 100%;
                border: 2px solid black;
                margin: auto;
                justify-items: center;
            }
        }

        .form {            
            padding-left: 8%;
            padding-right: 8%;
        }
    }
    .cut_to_size {
        &__image {
            &--disabled {
                .react-resizable-handle {
                    display: none;
                }
            }
        }
    }
    .cut_to_size__go_back {
        width: 100%;
    }
    .cut_to_size__filters {
        // width: calc(200%/7);
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        border-bottom:1px solid lightgray;
        &--active{
            background:#E9F0F3;
        }
        // @media (max-width: $mdMaxWidth) {
        //     & {
        //         width: 100%;
        //     }
        // }
        @media (min-width: $mdMinWidth) {
            & {
                // padding: 32px 24px 16px 24px;
            }
        }

        .filters__item {
            height: auto;
            width: 100%;
            &--color {
                display: flex;
                flex-direction: column;
                & > * {
                    width: 100%;
                }
            }
        }
    }
    .cut_to_size__contain {
        width: calc(500%/7);
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @media (max-width: $mdMaxWidth) {
            & {
                width: 100%;
            }
        }
        .contain__content {
            width: 100%;
        }
        .contain__select--color,
        .contain__select--samples,
        .contain__select--furnitures {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            .label {
                width: 100%;
                padding-left: 20px;
            }
            .input {
                display: flex;
                .Select {
                    flex-grow: 1;
                    padding: 0 10px;
                }
            }
        }
        .form {
            display: flex;
            flex-wrap: wrap;
            padding: 10px;
            .group {
                display: flex;
                padding: 10px;
                flex-wrap: wrap;
                &__input {
                    padding: 0 10px;
                    &--add_to_cart {
                        margin-top: auto;
                        padding-bottom: 10px;
                    }
                }
                &--column {
                    flex-direction: column;
                }
                &--inline {
                    flex-direction: row;
                }

                &--right {
                    padding: 10px;
                    flex-grow: 1;

                    @media (max-width: $smMaxWidth) {
                        & {
                            width: 100%;
                        }
                    }
                }
                &--image {
                    flex-grow: 0;
                    .group__label--image {
                        width: 100%;
                    }
                    @media (max-width: $smMaxWidth) {
                        & {
                            width: 100%;
                        }
                    }
                }
                &--measurement {
                    flex-grow: 1;
                    flex-wrap: nowrap;
                    .group__input {
                        flex-grow: 1;
                        &:first-child {
                            padding-left: 0;
                        }
                        &:last-child {
                            padding-right: 0;
                        }
                    }
                    @media (max-width: $smMaxWidth) {
                        & {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .colors {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .colors__item--image {
            min-width: 100px;
            min-height: 100px;
            margin: 10px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .sk-item-list {
        display: flex;
        justify-content: space-evenly;
        .sk-item-list__item {
            display: inline-block;
            vertical-align: top;
            position: relative;
            cursor: pointer;
            width: 70px;
            height: 70px;
            padding: 2px;
            margin: 10px 7px 35px;

            border-radius: 50%;
            border: 1px solid $mercury;

            background-position: center 5px;
            background-size: 90% auto;
            background-repeat: no-repeat;

            font-family: SilestoneWebSymbols;

            &::before {
                color: $thunder;
                font-size: 65px;
            }

            &[data-key='TABLA'] {
                background-image: url('../img/icons/products/tables.svg');
            }

            &[data-key='LAVABOS']::before {
                content: 'œ';
                line-height: 50px;
            }

            &[data-key='FREGADERO']::before {
                content: 'Œ';
                line-height: 50px;
            }

            &[data-key='PLATOSDUCHA']::before {
                content: '–';
                line-height: 50px;
            }

            &[data-key='PPCC']::before {
                content: 'Ë';
                line-height: 70px;
            }

            &[data-key='MKTOOL'] {
                background-image: url('../img/icons/products/mktools.svg');
                background-position: center;
                background-size: 65%;
            }
            &[data-key='SAMPLES'] {
                background-image: url('../img/icons/circle-view-off.svg');
                background-position: center;
                background-size: 65%;
            }
            &[data-key='SILESTONE'] {
                background-image: url('../img/icons/icon-silestone.png');
                background-position: center;
                background-size: 65%;
            }
            &[data-key='DEKTON'] {
                background-image: url('../img/icons/icon-dekton.png');
                background-position: center;
                background-size: 65%;
            }
            &[data-key='SENSA'] {
                background-image: url('../img/icons/icon-sensa.png');
                background-position: center;
                background-size: 65%;
            }
            &[data-key='SCALEA'] {
                background-image: url('../img/icons/icon-scalea.png');
                background-position: center;
                background-size: 65%;
            }
            &[data-key='TABLE'] {
                background-image: url('../img/icons/icon-table.png');
                background-position: center;
                background-size: 65%;
            }

            &.is-active {
                background-color: $mercury;
                border: 2px solid $thunder;
                z-index: 10;
                .sk-item-list-option__text {
                    font-weight: 300;
                }
            }

            &:hover {
                background-color: $mercury;
            }

            .sk-item-list-option__checkbox {
                display: none;
            }

            .sk-item-list-option__text {
                position: absolute;
                display: block;
                top: 75px;
                width: 100%;
                text-align: center;
                font-family: $infoFont;
                font-weight: 200;
                font-size: 12px;
                line-height: 15px;
            }

            .sk-item-list-option__count {
                display: none;
            }
        }
    }

    .cut_to_size_info {
        display: flex;
        text-align: center;
        flex-wrap: wrap;
        &__item {
            text-align: left;
            padding-right: 5px;
            span {
                text-transform: lowercase;
                &:first-letter {
                    text-transform: uppercase;
                }
            }
            &:first-child {
                width: 100%;
            }
        }
    }
    @keyframes step_add {
        from {
            transform: scale(0);
            opacity: 0;
            width: 0;
            max-width: 0;
            height: 0;
            margin-left: -50px;
        }
        to {
            margin-left: 0px;
            transform: scale(1);
            opacity: 1;
            width: 75px;
            min-width: 75px;
            height: 75px;
        }
    }
    .step {
        transition: 0.5s;
        opacity: 1;
        transform: scale(1);
        font-size: 14px;
        animation-name: 'step_add';
        animation-duration: 0.5s;
        width: 75px;
        min-width: 75px;
        height: 75px;
        &--picked {
            opacity: 0;
            transform: scale(0);
            height: 0;
            width: 0;
            min-width: 0px;
        }
    }
    .Select {
        border: 1px solid $gray;
        border-radius: 0.25rem;
        // margin-top: 10px;
    }

    .form-control {
        border-color: $gray!important;
        padding: 0 calc(10px + 0.4rem);
    }
    .attach-file {
        border: 1px solid $gray;
        width: 100%;
        border-radius: 0.25rem;
        height: 40px;
        padding: 4px;
        font-size: small;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
    }
    .text-input {
        border: 1px solid $gray;
        width: 100%;
        border-radius: 0.25rem;
        height: 36px;
        padding: 4px 8px;
        font-size: 1em;
    }

    .custom-products-block {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 24px;
        padding-bottom: 32px;

        &__key {
            background-color: $white;
            max-width: 662px;
        }
    }

    .custom-products-card {
        padding: 16px;
        background-color: $white;
        color: $primaryColor;
        
        &__img {
            overflow: hidden;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                aspect-ratio: 16/9;
                transition: transform 0.3s ease;
            }
        }

        &__title {
            padding-top: 16px;
            h5 {
                font-family: $titleFontNormal;
                font-weight: 500;
                font-size: 22px;
                margin-bottom: 0;
            }
        }

        &__content {
            padding-top: 8px;
            padding-bottom: 16px;
            p {
                font-family: $infoFont;
                font-size: 14px;
                font-weight: 300;
                line-height: 16px;
                margin: 0;
            }
        }
    }

    .custom-products-card:hover .custom-products-card__img img {
        transform: scale(1.1);
    }

    .custom-products-banner-block {
        background-color: $white;
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        &__content {
            width: 40%;
            padding: 16px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            h4 {
                font-family: $titleFontNormal;
                font-weight: 500;
                font-size: 22px;
                color: $primaryColor;
                margin: 0;
                padding-bottom: 16px;
            }

            button {
                background-color: transparent;
                font-size: 16px;
                font-weight: 500;
                font-family: $infoFont;
                color: $primaryColor;
                border: 0;
                padding-left: 0;
                padding-right: 0;

                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 6px;

                i {
                    font-size: 12px;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            @media (max-width: $mdMaxWidth) {
                width: auto;
            }
        }

        &__img {
            flex: 1;
            display: flex;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        @media (max-width: $mdMaxWidth) {
            flex-direction: column;
        }
    }
    
    .cut_to_size_elaboration_types {
        display: grid;
        gap: 16px;
        grid-auto-flow: dense;
        grid-auto-rows: 17rem;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    }

    .questa-bold {
        font-family: questa_bold;
    }

    
    .form-label {
        font-family: questa_bold;
        font-size: 0.96rem;
        margin-bottom: 0.2rem;
    }

    .measures-label {
        font-family: questa_bold;
        font-size: 0.90rem;
        margin-bottom: 0.2rem;
    }

    input[type=file]::-webkit-file-upload-button {
        border:none;
        height: 100%;
    } 
    input[type=file]::file-selector-button {
        border:none;
        height: 100%;
    }    
    input[type=file]::-ms-browse {
        border: none;
        height: 100%;
    }
    
    .Select-placeholder{
        font-size: small;
        margin-left: 0.4rem;
        line-height: 36px;
    }

    .input-number {
        height: calc(1.5em + .75rem + 2px);
        line-height: calc(1.5em + .75rem + 2px);
        border: 1px solid $gray;
        border-radius: 0.25rem;
        max-width: 100%!important;
        font-weight: bold;
        background-color: white;

        .input-container {
            input {
                background: none!important;
                font-size: inherit;
                font-weight: inherit;
                padding: 0;
                height: 100%;
            }
           
        }
        
        .minus {
            border-right: 1px solid $gray!important;
        }
        .plus {
            border-left: 1px solid $gray!important;
        }

        .spin {
            background: none!important;
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
            padding: 0;
            height: 100%;            
        }        
    }

    .cts-detail-text {
        font-size: small;
        @media (max-width: $mdMaxWidth) {
            display: none;
        }
    }

    .Select-menu-outer {
        width: 100.5%;
        margin-left: -0.25%;
        border: 1px solid $aluminum!important;
    }

    

    .capitalize {
        .Select-menu-outer, .Select-value {
            text-transform: capitalize;
        }
    }

    .pointer {
        cursor: pointer;
    }    
    
    
    .cut_to_size_elaboration_details_model_options {    
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    
    .cut_to_size_elaboration_details_model_option {
        border: 1px solid $aluminum;
        cursor: pointer;
    }

    .cut_to_size_elaboration_details_model_info_row {
        font-size: small;
    }

    [class^="desp_cirle_mark"],
    [class*="desp_cirle_mark"] {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        border: 1px solid #333;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        font-weight: 300;
        font-size: 14px;
        line-height: 13px;
        color: #333;
    }

    [class$="_success"],
    [class*="_success"] {
        color: #00ff00;
        border-color: #00ff00;
    }

    .null_attached_file_button {
        background-color: $lightGray;
        height: 28px;
        width: 28px;
        text-align: center;
        line-height: 30px;
        font-size: 24px;
        cursor: pointer;
    }

    .Select.is-focused {
        color: #495057;
        background-color: #fff!important;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.25);
    }

    //input[type="checkbox"] {
    //    display: none;
    //}
    //
    //input[type="checkbox"] + *::before {
    //    content: "";
    //    display: inline-block;
    //    vertical-align: bottom;
    //    width: 1rem;
    //    height: 1rem;
    //    border-radius: 0.2rem;
    //    border-style: solid;
    //    border-width: 0.2rem;
    //    border-color: #4d4d4d;
    //}
    //
    //input[type="checkbox"]:checked + *::before {
    //    content: "✓";
    //    color: white;
    //    text-align: center;
    //    background: #4d4d4d;
    //    border-color: #4d4d4d;
    //}
    //
    //input[type="checkbox"]:checked {
    //    color: #4d4d4d;
    //}

    .react-datepicker-wrapper, .react-datepicker__input-container {
        width: 100%;
    }

    .form-control-placeholder, .form-control::placeholder {
        color: #4d4d4d;
        font-size: small;
        line-height: 36px;
        font-weight: 300;
    }

    .preorder_created_modal {
        position: fixed; /* Positioning and size */
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
        z-index: 999;

        /* Modal Content/Box */
        .modal-content {
            background-color: white;
            margin: auto;
            padding: 48px 32px 56px;
            width: max-content;
            height: max-content;
            top: 50%;
            transform: translate(0, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
        }

        .success {
            border-radius: 50%;
            border: 2px solid $greenSuccess;
            color: $greenSuccess;
            width: 128px;
            height: 128px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .success-text {
            color: #232323;
            text-align: center;

            &.big {
                font-size: 22px;
                font-weight: 700;
            }

            &.small {
                font-size: 14px;
                font-weight: 300;
            }
        }
    }

    .quick-ship {
        background-color: #DDE4F6;
        border-radius: 2px;
        padding: 16px;
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        margin-block-end: 24px;

        .k-switch {
            font-size: 7px;
        }
        .k-switch-off {
            .k-switch-container {
                background-color: #E2E4E9;
            }
            .k-switch-handle {
                background-color: #5E6578;
            }
        }
        .k-switch-on {
            .k-switch-container {
                background-color: #365BCA;
            }
            .k-switch-handle {
                background-color: white;
            }
        }
        .k-switch-handle {
            border-width: 2px;
        }

        .first-line {
            display: flex;
            gap: 8px;
            align-items: center;
            flex-direction: row;
        }

        .first-txt {
            font-family: Diagramm, sans-serif;
            font-weight: 500;
            color: #20367A;
            font-size: 16px;
            line-height: 20px;
            align-self: flex-end;
        }
        .second-txt {
            font-family: Roboto, sans-serif;
            font-weight: 300;
            color: #232323;
            font-size: 14px;
            align-self: flex-end;

            b {
                font-weight: 500;
            }
        }
        .third-txt {
            font-family: Roboto, sans-serif;
            font-weight: 300;
            color: #5E6578;
            font-size: 12px;
            align-self: flex-end;
            margin-top: -6px;
        }
    }
}

.preorder-description {
    strong {
        color: black;
        font-size: 13px;
        font-weight: 500;
    }
}

.v2{
    position: relative;
    .title-cts{
        font-family: 'Diagramm';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 32px;
        padding-bottom: 32px;
        color: $primaryColor;
        margin-bottom: 0;
    }
}