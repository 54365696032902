.langs-modal {
    .modal-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 1.5rem 1.5rem 0.5rem;

        .bt {
            // transition: all 0.2s ease-out 0s;

            //     &.valid {
            //         background-color: #73de73;
            //         border-color: #73de73;
            //         color: $onyx;

            //         &:hover {
            //             background-color: darken(#73de73, 16%);
            //             border-color: darken(#73de73, 16%);
            //         }
            //   }

            //     &.invalid {
            //         background-color: #de7373;
            //         border-color: #de7373;
            //         color: $white;

            //         &:hover {
            //             background-color: darken(#de7373, 16%);
            //             border-color: darken(#de7373, 16%);
            //         }
            //     }
        }
    }

    .translation-item {
        display: flex;
        flex-basis: 33.33%;
        margin: 0 0 1.25rem;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .translation-label-row {
            padding-bottom: 6px;
        }

        @media (max-width: $xsMaxWidth) {
            flex-basis: 50%;
        }
    }
}

.k-popup.popup-content-landing-item {
    padding: 15px;

    .bt {
        display: block;
        width: auto;
        height: auto;
        min-width: 100px;
        margin: 0;

        & + .bt {
            margin: 10px 0 0;
        }
    }
}

.k-animation-container {
    z-index: 10003 !important;
}

.confirm {
    h2 {
        font-size: 20px;
        color: $primaryColor;
        font-family: $titleFontRegular;
        font-weight: 600;
        padding-bottom: 16px;
        text-align: center;
        margin: 0;
    }
}

#section-landings {
    .tablecustomButtonRound {
        width: 32px;
        height: 32px;
        border: none;
        border-radius: 32px;
        padding: 8px;
        padding-top: 4px;
        margin: auto;

        &.valid {
            background-color: #73de73;
            border-color: #73de73;
            color: $onyx;

            &:hover {
                background-color: darken(#73de73, 16%);
                border-color: darken(#73de73, 16%);
            }
        }

        &.invalid {
            background-color: #de7373;
            border-color: #de7373;
            color: $white;

            &:hover {
                background-color: darken(#de7373, 16%);
                border-color: darken(#de7373, 16%);
            }
        }
    }

    h2 {
        margin: 30px 0;
    }

    .landings {
        display: block;
        width: 100%;
        height: auto;
        padding: 20px 0;
        overflow: hidden;

        .container {
            .row {
                margin-bottom: 12px;

                @media (max-width: $smMaxWidth) {
                    margin-bottom: 0;

                    > div {
                        margin-bottom: 8px;

                        &:empty {
                            display: none;
                        }
                    }
                }

                &.row-reverse {
                    flex-direction: row-reverse;
                }
            }
        }

        &__button {
            padding-top: 20px;

            .bt {
                display: block;
                width: 100%;
                height: 100%;
                padding: 12px 20px;
                border-radius: 4px;
                font-weight: 500 !important;
            }
        }

        &__filter {
            display: block;
            position: relative;

            &--date {
                padding-top: 20px;

                a {
                    display: block;
                    text-align: right;
                    height: 0;
                    font-size: 13px;
                    line-height: 18px;
                    cursor: pointer;
                }

                label {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding-left: 15px;
                    font-size: 14px;
                    line-height: 20px;
                }

                .k-textbox-container {
                    width: auto !important;
                }

                .k-dateinput {
                    position: relative;

                    &::after {
                        content: '\E108';
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        width: 28px;
                        font-family: 'WebComponentsIcons';
                        font-size: 16px;
                        line-height: 48px;
                        text-align: center;
                        color: #656565;
                        background-color: #ededed;
                        pointer-events: none;
                    }
                }
            }

            &--search {
                .k-icon {
                    position: absolute;
                    right: 23px;
                    top: calc(50% + 10px);
                    transform: translateY(-50%);
                }
            }

            .k-select,
            .k-widget {
                background: transparent !important;
            }

            .k-textbox-container {
                display: block;
                width: 100%;

                .k-dropdown,
                .k-multiselect {
                    width: 100%;
                    height: 50px;

                    &-wrap {
                        background: #fff;
                        border: 1px solid #707070;
                        border-radius: 4px;
                        overflow: auto;
                    }

                    .k-searchbar {
                        height: 100%;
                    }

                    .k-input {
                        height: 100%;
                    }
                }

                &.k-state-empty {
                    &.k-state-focused {
                        & > .k-label {
                            transform: translateY(0);
                        }
                    }

                    & > .k-label {
                        transform: translateY(9px);
                    }
                }
            }

            .k-daterangepicker {
                display: block;
                width: 100%;
                height: 50px;
                background: #fff;
                box-shadow: none;

                .k-daterangepicker-wrap {
                    display: flex !important;
                    justify-content: space-between;
                    width: 100%;
                    height: 100%;
                    border: 1px solid #707070;
                    border-radius: 4px;
                }

                .k-label {
                    display: none;
                }

                .k-textbox-container {
                    padding: 0;
                }

                .k-dateinput,
                .k-input,
                .k-dateinput-wrap {
                    height: 100%;
                    border: none;
                    background: transparent;
                    box-shadow: none;
                }
            }

            .k-datepicker {
                display: block;
                width: 100%;
                height: 50px;
                background: #fff;
                box-shadow: none;

                .k-picker-wrap {
                    height: 100%;
                    border: 1px solid #707070;
                    border-radius: 4px;
                }

                .k-input,
                .k-dateinput-wrap {
                    height: 100%;
                    border: none;
                    background: transparent;
                    box-shadow: none;
                }
            }

            .searchInput {
                display: block;
                width: 100%;
                height: 50px;
                background: #fff;
                border: 1px solid #707070;
                border-radius: 4px;
            }
        }

        &__data {
            display: block;
            width: 100%;
            height: auto;
            padding-top: 20px;
            padding-bottom: 20px;

            .k-grid {
                display: block;
                width: 100%;
                height: auto;
                border: 1px solid #707070;
                border-radius: 4px;
                background: #fff;

                &-header,
                &-container,
                &-pager {
                    background: transparent;
                }

                &-header {
                    border-bottom: 1px solid rgba(#707070, 0.1);

                    table {
                        thead {
                            tr {
                                th {
                                    border: none;
                                    .k-link {
                                        color: #424242;
                                        padding: 12px;
                                        .k-icon {
                                            color: #656565;
                                        }
                                    }

                                    &.k-sorted {
                                        .k-link {
                                            font-weight: 500;
                                        }
                                    }

                                    &:nth-last-child(2),
                                    &:last-child {
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }

                &-container {
                    table {
                        tbody {
                            tr {
                                &:hover,
                                &.k-state-hover {
                                    background-color: rgba(0, 0, 0, 0.04) !important;
                                }

                                &.k-alt {
                                    background: transparent;
                                }

                                td {
                                    border: none;
                                    border-bottom: 1px solid rgba(#707070, 0.1);
                                }
                            }
                        }
                    }
                }

                &-pager {
                    justify-content: flex-end;
                    border-top: 1px solid rgba(#707070, 0.1);
                    .k-pager-numbers {
                        .k-link {
                            color: #656565;
                            box-shadow: none !important;

                            &.k-state-selected {
                                background: rgba(#707070, 0.1);
                            }
                        }
                    }
                    .k-pager-nav {
                        &.k-link {
                            color: #656565;
                            box-shadow: none !important;
                        }
                    }
                    .k-pager-info {
                        display: none;
                    }
                }
            }
        }
    }
}
