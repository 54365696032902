.order-history-item {
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 10px;

    &.background-transparent {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        box-shadow: none;

        .card-header {
            background-color: transparent;
        }
    }

    .card-header {
        background-color: white;
        border-bottom: 0;
    }

    .card-body {
        font-size: 13px;

        .value {
            font-size: 14px;
        }
    }

    .card-footer {
        background: none;
        border: none;
        .bt {
            @media (max-width: $smMaxWidth) {
                width: 100%;
            }
        }
    }
}

@media (max-width: 775px) { 
    
    .per-delivery-view{    
        .delivery-head {
            flex-direction: column;
            div{
                text-align: left !important;
            }            
            background-color: white;
            margin-bottom: .1em;
            padding: 1em;
            margin-top: 0px;
            .number{
                font-size: larger;
            }
        }
        .delivery-lines{
            
            padding: 1em;            
           
           .card-lines{
                background-color: white;
                padding: 1em;
                display: flex;
                flex-direction: row;
                gap: 1.5em;
                
                div:last-child{
                    display: flex;
                    flex-direction: column;
                    width: 60%;
                }
                .product-image-container{
                    display: block;
                }
                .product-image{
                    width: 90px;
                    height: 90px;
                }
           }
        }
    }
   
}

.shipping-label {
    text-align: end;
    padding: 8px;
    font-size: 14px;
}

.order-history-detail-items {
    
    .view-selector {
        padding: 10px 10px 20px 0;

        span {
            margin-right: 20px;
            font-size: 18px;
            cursor: pointer;

            &.active {
                font-weight: bold;
                cursor: default;
            }
        }
    }
    div{
        //margin-top: .5em;
        ul{
            li{
                margin-top: .5em;
            }
        }
    }
    div:first-child{
        margin-top: 0em;
    }
    .outlet-indicator {
        display: inline-block;
        background: #d66f00;
        border-radius: 2px;
        box-shadow: rgba($onyx, 0.3) 1px 1px 3px;
        color: $white;
        font-size: 11px;
        font-weight: bold;
        // left: -20px;
        padding: 3px 5px 0;
        // position: absolute;
        text-transform: uppercase;
        // top: -10px;
        z-index: 1;
    }

    .ohcard {
        box-shadow: rgba(0, 0, 0, 0.2) 0 5px 10px;
        border-radius: 6px;
        background-color: white;
        padding: 10px;

        &.mobile {
            font-family: 'Roboto';
            font-size: 14px;
            margin: 0 -15px 0;
            .row {
                min-height: 72px;
            }
            .qtyLines {
                font-size: 12px;
            }
            .col-price-row {
                align-self: center;
                align-items: center;
                display: flex;
                padding: 0;
                .price-box {
                    background-color: $lightBlue;
                    height: 45px;
                    width: 95%;
                    border-radius: 4px;
                    display: block;
                    p {
                        margin-bottom: 0;
                        margin-left: 3px;
                        text-align: center;
                    }
                }
                .go-detail {
                    margin-right: -8px;
                    padding-left: 5px;
                }
            }

            .all-view {
                margin: 0 2% 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                .img {
                    height: 60px;
                    width: 60px;
                }
                .product-image-container {
                    height: 60px;
                    width: 60px;
                }
                .go-detail {
                    justify-content: right;
                    display: grid;
                }
            }
            #order-line-modal {
                font-size: 16px;
                @media (max-width: $mdMaxWidth) {
                    font-size: 14px;
                }
                position: absolute;
                top: 56px;
                right: 0;
                width: 730px;
                text-align: left;
                background: #ffffff;
                border-radius: 5px;
                z-index: 700;
                max-height: 80vh;
                overflow-y: auto;
                cursor: auto;
                @media (max-width: $mdMaxWidth) {
                    position: fixed;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    max-height: none;
                    overflow-x: none;
                    overflow-y: scroll;
                    -webkit-overflow-scrolling: touch;
                    border-radius: 0;
                }
                .title {
                    padding-bottom: 5px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    font-size: 13px;
                    margin-bottom: 15px;
                }

                header,
                footer {
                    padding: 15px 15px;
                }
                .number {
                    background-color: transparent;
                    align-items: baseline;
                    font-size: 13px;
                    b {
                        font-size: 32px;
                    }
                }
                .product_group {
                    padding: 0 20px 10px;
                    .row {
                        margin-bottom: 10px;
                    }
                    .product_detail {
                        .product-title {
                            font-family: 'Roboto';
                        }
                        align-items: center;
                        justify-content: center;
                        align-self: center;
                    }
                }
                div.container-fluid {
                    margin-left: 15px;
                    margin-right: 15px;
                    width: auto;
                    .row {
                        .col-12:nth-child(2) {
                            margin-left: 20px;
                            margin-right: 20px;
                            width: auto;
                        }
                    }
                }
            }
        }
    }

    .per-delivery-view:not(.time-line-2) {
        @media (max-width: $xsMaxWidth) {
            margin: 0 -15px 0;
        }
        .product-title {
            font-family: 'Roboto';
        }
        .delivery {
            margin-bottom: 20px;

            .delivery-head {
                margin: 5px 0px 20px;
                padding-bottom: 10px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                @media (max-width: $xsMaxWidth) {
                    .text-right {
                        text-align: left !important;
                    }
                }
                .delivery-status-dot {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    margin-left: 5px;
                    border-radius: 50%;

                    &.grey {
                        background-color: #dedfde;
                    }
                    &.blue {
                        background-color: #9ac2c6;
                    }
                }
            }

            .delivery-lines {
                .delivery-line {
                    margin: 0 10px 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    @media screen and (max-width: $xsMaxWidth) {
                        .row {
                            p {
                                margin-bottom: 0.5rem;
                            }
                        }
                        .product-image-container {
                            .product-image {
                                &[type='TABLA'] {
                                    background-size: cover;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .all-view {
        table {
            thead {
                tr {
                    th {
                        border: 1px solid black;
                        background-color: #e8eced;
                        padding: 10px;
                        text-align: center;
                        font-size: 15px;
                        font-weight: normal;
                        line-height: 16px;
                    }
                }
            }

            tbody {
                tr {
                    &.rejected {
                        background-color: #fcded7;
                    }

                    td {
                        border: 1px solid black;
                        padding: 10px;
                        text-align: center;
                        word-break: break-word;
                    }
                }
            }
        }
    }
    .product-image {
        &[type='TABLA'] {
            background-size: cover;
        }
    }

    .technology-indicator {
        max-width: 70%;
        background: whitesmoke;
        border-radius: 2px;
        box-shadow: rgba($onyx, 0.3) 1px 1px 3px 1px;
        display: flex;
        justify-content: center;
        padding: 10px 0px;
        position: absolute;
        text-transform: uppercase;
        bottom: -5px;
        margin-left: -5px;
        width: 50px;
        z-index: 1;

        &.plus {
            &:after {
                color: black;
                content: '+';
                font-size: 14px;
                font-weight: 200;
                line-height: 14px;
                margin-left: 2px;
            }
        }

        > img {
            width: 80%;
        }

        &.hybriq {
            background-color: white;
            width: 100px;
            align-items: center;
        }
    }
}
.react-datepicker-popper {
    z-index: $layer60 !important;
}
.order_filters {
    .group_filter_status,
    .search-bar {
        min-width: 200px;
    }
    .group_date_picker .react-datepicker-wrapper {
        min-width: 120px;
        width: 100%;
        .react-datepicker__input-container {
            width: 100%;
        }
    }
    .react-datepicker-popper {
        z-index: 11;
    }
    .wrapper__search-box-with-button {
        position: relative;
        input {
            height: calc(1.5em + 0.75rem + 10px);
        }
        button {
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }
    .btn-primary {
        background-color: #235f7d;
        border-color: #235f7d;
        &:not(:disabled):not(.disabled).active {
            background: transparent;
            color: #235f7d;
            border-color: #235f7d;

        }
    }
}
.order_details {
    .bt-group-detail {
        text-align: right;
        .icon {
            display: none;
        }
        @media (min-width: $xsMaxWidth) {
            .bt {
                margin-right: 0.5rem;
            }
        }
        @media (max-width: $xsMaxWidth) {
            text-align: center;
            display: flex;
            margin-top: 15px;
            padding: 0;
            img.icon {
                display: block;
                min-height: 21px;
                margin: auto;
                margin-bottom: 0.5rem;
            }
            .bt {
                border: 0;
                background: transparent;
                &:hover {
                    background: transparent;
                    color: $thunder;
                }
            }
        }
    }
}



.pre-orders-cts-list-item{
    //min-height:200px;
    padding:0 !important;
    >div{
        border-right: 1px solid lightgray;
        padding:16px 24px 24px;
        &:last-child{
            border-right:none;
        }
    }
}

.send-message-input {
    width: 100%;
    border: none;
    &:focus {
        outline: none;
        border: none;
    }
}

.select-file-type {
    cursor: pointer;
    margin: 8px 4px;
    display: flex;
    align-items: center;
    &:hover {
        background-color: $lightAqua;
    }
}

.order-v2-header{
    border-bottom: 1px solid #EBEBEB;
    padding: 1rem;
    .references{
        flex: 1;
        padding: 1rem;
        text-align: end;
    }
   
    @media (max-width: 991px) {
        flex-direction: column;
        .references{
           text-align: start;
           padding: 1rem 1rem 1rem 0;
        }        
    }
}
.order-v2-block {
    flex: 2;
    @media (min-width: 992px) {
        border-right: 1px solid #EBEBEB;
    }
}
 .edit-btns-history {
    display: flex;

    @media (max-width: 991px) {
        display: none;         
    }
 }
 .edit-btns-history-header{
    display: none;
    @media (max-width: 991px) {
        display: flex;
        justify-content: space-between;
    }
 }

 .details-order-v2{
    display: block;
    @media (max-width: 991px) {
        display: none;
    }
 }
 .details-order-v2-responsive{
    display: none;    
    @media (max-width: 991px) {
        display: block;          
    }
    button{
        width: 100%;
        margin: 0;
    }
 }
 .direction-order-v2{
    display: flex;
    flex-direction: column;
    justify-content: space-around;       
    flex: 1;
    padding-left: 1rem;
    padding-right: 1rem;
    
    @media (max-width: 991px) {
        display: none;
    }
 }

 .order-details-header{
    display: flex;    
    flex-direction: row;
    text-align: end;
    padding-top: 1rem;
    div:last-child{
        margin-left: auto;
    }
    @media (max-width: 991px) {
        flex-direction: column;
        justify-content: end;
        text-align: none;
    }
 }

 
 @media (max-width: 775px) {
    .order-details{
        margin-left: -15px;
        margin-right: -15px;
    }
    .delivery-lines{
        .product-image {
                width: 90px !important;
                height: 90px !important;
            }
    }
    .order-details{
        .product-image {
                width: 90px !important;
                height: 90px !important;
            }
    }
     
}

.search-bar-orders{
    .search-bar{
        margin: 1em;
        &::before {
            left: auto !important;
            right: 15px !important;
        }
    }
    .search-bar--thin {
        margin: 1em;

        &::before {
            left: auto !important;
            right: 15px !important;
        }
    }
   
    
    
}
.absolute-list-pather{
    
    z-index: 1;
}
.absolute-list{
    //position: absolute !important;
    width: inherit;
    z-index: 2;
}
.infinite-scroll-component{
    overflow: hidden !important;
}
