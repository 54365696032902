#modal-pedidos-recurrentes-container {
    position: fixed;
    top: 15%;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;
    max-width: 1000px;
    z-index: $layer50;

    header {
        margin-top: 10px;
        padding-right: 19px;
    }

    .logo-container {
        padding-right: 2rem;
        padding-left: 4rem;
        padding-top: 4rem;
        padding-bottom: 4rem;
        border-radius: 0 !important;
    }

    .title {
        width: 383.3px;
        height: 102.37px;
        left: 274.04px;
        top: 242.34px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 34.9177px;
        line-height: 45px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #000000;
    }

    .subtitle {
        width: 306px;
        height: 102px;
        left: 274px;
        top: 381px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 19.046px;
        line-height: 25px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #000000;
    }

    .image {
        position: relative;
        display: inline-block;
        text-align: right;
        width: 78%;
    }

    hr {
        width: 57.14px;
        height: 5.56px;
        left: 276.42px;
        top: 352.65px;
        background: $primaryColor;
        margin-left: 0;
    }

    .buttons {
        display: flex;
        justify-content: center;
        margin-top: 55px;
    }

    .btn-old {
        margin-right: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 32px;
        gap: 8px;
        width: 187px;
        height: 48px;
        border: 1px solid black;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.24));
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: black;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .btn-new {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 32px;
        gap: 8px;
        width: 188px;
        height: 48px;
        background: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: white;
        flex: none;
        order: 0;
        flex-grow: 0;
        border: none;
    }

    @media screen and (min-width:768px) and (max-width: 925px){
        .image {
            max-width: 77%;
        }
    }

    @media screen and (min-width:32px) and (max-width: 767px){
        .image {
            text-align: center;
            max-width: 77%;
        }
        .title {
            height: 31px;
            font-size: 27.9177px;
        }
        .subtitle {
            height: 45px;
            width: 100%;
        }
        .box-image {
            text-align: center !important;
        }
    }

    @media screen and (min-width:457px) and (max-width: 568px){
        .image {
            text-align: center;
            max-width: 77%;
            .img-fluid {
                max-width: 77%;
            }
        }
        .title {
            height: 31px;
            font-size: 27.9177px;
        }
        .subtitle {
            height: 88px;
            width: 100%;
        }
    }

    @media screen and (min-width:1px) and (max-width: 456px){
        .title {
            width: 100%;
            height: 41px;
            font-size: 19px;
        }
        .subtitle {
            height: 88px;
            width: 90%;
            font-size: 17px;
        }
        .btn-new {
            padding: 0;
            font-size: 10px;
        }
        .btn-old {
            padding: 0;
            font-size: 10px;
        }
    }

    @media screen and (min-width:1px) and (max-width: 456px){
        .title {
            width: 100%;
            height: 41px;
            font-size: 19px;
            margin-bottom: 3rem;

        }
    }
}