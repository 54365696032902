.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: "\00a0";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}
.flag-icon-ca {
  background-image: url(../flags/4x3/ca.svg);
}
.flag-icon-de {
  background-image: url(../flags/4x3/de.svg);
}
.flag-icon-dk {
  background-image: url(../flags/4x3/dk.svg);
}
.flag-icon-es {
  background-image: url(../flags/4x3/es.svg);
}
.flag-icon-fi {
  background-image: url(../flags/4x3/fi.svg);
}
.flag-icon-fr {
  background-image: url(../flags/4x3/fr.svg);
}
.flag-icon-gb {
  background-image: url(../flags/4x3/gb.svg);
}
.flag-icon-il {
  background-image: url(../flags/4x3/il.svg);
}
.flag-icon-it {
  background-image: url(../flags/4x3/it.svg);
}
.flag-icon-jp {
  background-image: url(../flags/4x3/jp.svg);
}
.flag-icon-nl {
  background-image: url(../flags/4x3/nl.svg);
}
.flag-icon-no {
  background-image: url(../flags/4x3/no.svg);
}
.flag-icon-pl {
  background-image: url(../flags/4x3/pl.svg);
}
.flag-icon-pt {
  background-image: url(../flags/4x3/pt.svg);
}
.flag-icon-se {
  background-image: url(../flags/4x3/se.svg);
}
.flag-icon-tr {
  background-image: url(../flags/4x3/tr.svg);
}
.flag-icon-us {
  background-image: url(../flags/4x3/us.svg);
}
